import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { toJS } from 'mobx';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CardActions, IconButton, Link, TextField, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import OutlinedInput  from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import 'dayjs/locale/tr';
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { DataGrid } from '@mui/x-data-grid';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { Clinic } from '../../../model/Doctor/Clinic'

import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';

import getClinicRoom from '../../../utils/api/Doctor/Clinic/GetClinicRoom'
import putClinic from '../../../utils/api/Doctor/Clinic/PutClinic'
import getClinic from '../../../utils/api/Doctor/Clinic/GetClinic';
import postClinic from '../../../utils/api/Doctor/Clinic/PostClinic'
import postClinicRoom from '../../../utils/api/Doctor/Clinic/PostClinicRoom'
import deleteClinicRoom from '../../../utils/api/Doctor/Clinic/DeleteClinicRoom'
import putClinicRoom from '../../../utils/api/Doctor/Clinic/PutClinicRoom'
import getIlListesi from '../../../utils/api/Common/GetIlListesi'
import getIlceListesi from '../../../utils/api/Common/GetIlceListesi'
import getMahalleListesi from '../../../utils/api/Common/GetMahalleListesi'
import postClinicEmployee from '../../../utils/api/Doctor/Clinic/PostClinicEmployee'
import getClinicEmployee from '../../../utils/api/Doctor/Clinic/GetClinicEmployee'
import deleteClinicEmployee from '../../../utils/api/Doctor/Clinic/DeleteClinicEmployee'
import getClinicExpense from '../../../utils/api/Doctor/Clinic/GetClinicExpense';
import postClinicExpense from '../../../utils/api/Doctor/Clinic/PostClinicExpense';
import getClinicProduct from '../../../utils/api/Doctor/Clinic/GetClinicProduct';
import putClinicProduct from '../../../utils/api/Doctor/Clinic/PostClinicProduct';
import putClinicExpenseCategory from '../../../utils/api/Doctor/Clinic/PutClinicExpenseCategory'
import getClinicExpenseCategory from '../../../utils/api/Doctor/Clinic/GetClinicExpenseCategory'
import deleteClinicProduct from '../../../utils/api/Doctor/Clinic/DeleteClinicProduct';
import putClinicEmployee from '../../../utils/api/Doctor/Clinic/PutClinicEmployee'
import deleteClinicExpenseCategory from '../../../utils/api/Doctor/Clinic/DeleteClinicExpenseCategory'
import deleteClinicExpense from '../../../utils/api/Doctor/Clinic/DeleteClinicExpense';
import {getConsumables, putConsumable, deleteConsumable, getProductConsumables, getConsumableLogs} from '../../../utils/api/Doctor/Clinic/Consumable';
import {getEnabizAccount, putEnabizAccount} from '../../../utils/api/Doctor/EnabizAccount';

import { Address } from '../../../model/Address';
import { Employee } from '../../../model/Doctor/Employee';
import { Expense } from '../../../model/Doctor/Expense';

import Upload from '../../upload/Upload';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(#00)000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export const KlinikMenu = inject("store")(observer((props) => {

    const [showClinicAddMenuDisplay, setShowClinicAddMenuDisplay] = useState(false)
    const [showClinicInfoMenuDisplay, setShowClinicInfoMenuDisplay] = useState(false)

    const [klinikBilgisi, setKlinikBilgisi] = useState(Clinic.create({}))
    const [klinikRoomArray, setKlinikRoomArray] = useState([])
    const [klinikEklemeMenuDisplay, setKlinikEklemeMenuDisplay] = useState(false)

    const [klinikIsimMenuDisplay, setKlinikIsimMenuDisplay] = useState(false)
    const [klinikIsmi, setKlinikIsmi] = useState("")

    const [klinikOdaEkleMenuDisplay, setKlinikOdaEkleMenuDisplay] = useState(false)
    const [klinikOdaIsmi, setKlinikOdaIsmi] = useState("")

    const [selectedKlinikRoomId, setSelectedKlinikRoomId] = useState(0)
    const [klinikOdaIsmıGuncelleMenuDisplay, setKlinikOdaIsmıGuncelleMenuDisplay] = useState(false)

    const [ilListesi, setIlListesi] = useState([])
    const [ilceListesi, setIlceListesi] = useState([])
    const [mahalleListesi, setMahalleListesi] = useState([])

    const [klinikAdress, setKlinikAdress] = useState(Address.create({}))

    const [klinikPersonelEkleMenuDisplay, setKlinikPersonelEkleMenuDisplay] = useState(false)
    const [newEmployee, setNewEmployee] = useState(Employee.create({}))
    const [phoneNumber, setPhoneNumber] = useState("")

    const [employeeList, setEmployeeList] = useState([])
    const [value, setValue] = useState(0);

    const [giderListesi, setGiderListesi] = useState([])
    const [backupGiderListesi, setBackupGiderListesi] = useState([])
    const [giderKategoriListesi, setGiderKategoriListesi] = useState([])
    const [selectedKategoriId, setSelectedKategoriId] = useState(0)
    const [selectedKategoriName, setSelectedKategoriName] = useState("")

    const [gider, setGider] = useState(Expense.create({}))
    const [klinikGiderEkleMenuDisplay, setKlinikGiderEkleMenuDisplay] = useState(false)
    const [totalGider, setTotalGider] = useState(0)

    const [klinikGiderKategoriDisplay, setKlinikGiderKategoriDisplay] = useState(false)
    const [klinikGiderKategori, setKlinikGiderKategori] = useState("")
    const [klinikGiderKategoriMenuDisplay, setKlinikGiderKategoriMenuDisplay] = useState(false)
    const [consumableLogDisplay, setConsumableLogDisplay] = useState(false)

    const [productList, setProductList] = useState([])
    const [addProductMenuDisplay, setAddProductMenuDisplay] = useState(false)

    const [clinicProductId, setClinicProductId] = useState(0)
    const [clinicProductName, setClinicProductName] = useState("")
    const [clinicProductPrice, setClinicProductPrice] = useState(0)
    const [clinicProductDuration, setClinicProductDuration] = useState(0)
    const [selectedProductConsumable, setSelectedProductConsumable] = useState({})

    const [consumableList, setConsumableList] = useState([])
    const [consumableLogList, setConsumableLogList] = useState([])
    const [addConsumableMenuDisplay, setAddConsumableMenuDisplay] = useState(false)

    const [consumableId, setConsumableId] = useState(0)
    const [consumableName, setConsumableName] = useState("")
    const [consumableBrand, setConsumableBrand] = useState("")
    const [consumablePcs, setConsumablePcs] = useState(0)
    const [consumableUnit, setConsumableUnit] = useState("adet")
    const [consumableDesc, setConsumableDesc] = useState("")

    const [productConsumableList, setProductConsumableList] = useState([])

    const [giderBilgisiMenuDisplay, setGiderBilgisiMenuDisplay] = useState(false)
    const [selectedGider, setSelectedGider] = useState(Expense.create({}))

    const [updateEmployeeMenuDisplay, setUpdateEmployeeMenuDisplay] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState(Employee.create({}))

    const [updateProductMenuDisplay, setUpdateProductMenuDisplay] = useState(false)

    const [updateConsumableMenuDisplay, setUpdateConsumableMenuDisplay] = useState(false)

    const [filterOptions, setFilterOptions] = useState([])
    const [selectedFilter, setSelectedFilter] = useState(-99)

    const [startFilterDate, setStartFilterDate] = useState(new Date());
    const [endFilterDate, setEndFilterDate] = useState(new Date())

    const [showUsername, setShowUsername] = useState(false);
    const [enabizUser, setEnabizUser] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [enabizPass, setEnabizPass] = useState("");

    const monthArray = [
        "OCAK", "ŞUBAT", "MART", "NİSAN", "MAYIS", "HAZİRAN", "TEMMUZ", "AĞUSTOS", "EYLÜL", "EKİM", "KASIM", "ARALIK"
    ]

    useEffect(() => {
        // Update the document title using the browser API
        //console.log("props.data: ", toJS(props.data))
        if (props.data !== null) {
            setKlinikBilgisi(props.data)
            getClinicRoomList(props.data.clinicId)
            setKlinikIsmi(props.data.name)
            enabizAccount()
            if (props.data.clinicId !== 0) {
                setShowClinicInfoMenuDisplay(true)
                setShowClinicAddMenuDisplay(false)
                getKlinikCalisanListesi(props.data.clinicId)
                getKlinikGiderListesiReq(props.data.clinicId)
                getClinicProductListRequest(props.data.clinicId)
                getClinicConsumableListRequest(props.data.clinicId)
                getClinicExpenseCategoryListRequest(props.data.clinicId)
            }
        } else {
            setShowClinicInfoMenuDisplay(false)
            setShowClinicAddMenuDisplay(true)
        }
        getIlListesiReq()
        createFilterList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    const createFilterList = () => {

        let month = dayjs().month()
        let firstMonth = dayjs().subtract(1, 'month').month()
        let secondMonth = dayjs().subtract(2, 'month').month()
        let tirthMonth = dayjs().subtract(3, 'month').month()

        let filter0 = {
            id: 0,
            monthValue: month,
            monthName: monthArray[month].toLocaleUpperCase(),
            year: dayjs().year()
        }

        let filter1 = {
            id: 1,
            monthValue: firstMonth,
            monthName: monthArray[firstMonth].toLocaleUpperCase(),
            year: dayjs().subtract(1, 'month').year()
        }

        let filter2 = {
            id: 2,
            monthValue: secondMonth,
            monthName: monthArray[secondMonth].toLocaleUpperCase(),
            year: dayjs().subtract(2, 'month').year()
        }

        let filter3 = {
            id: 3,
            monthValue: tirthMonth,
            monthName: monthArray[tirthMonth].toLocaleUpperCase(),
            year: dayjs().subtract(3, 'month').year()
        }

        let filter4 = {
            id: -1,
            monthValue: 0,
            monthName: "GEÇEN YIL",
            year: dayjs().subtract(1, 'year').year()
        }

        let filter5 = {
            id: -2,
            monthValue: 0,
            monthName: "BU YIL",
            year: dayjs().year()
        }
        let filterList = [filter0, filter1, filter2, filter3, filter4, filter5]
        setFilterOptions(filterList)
    }

    const getClinicRoomList = async (clinicId) => {

        let clinicResponse = await getClinicRoom(props.store.baseUrl, props.store.token, props.store.doctor[0].id, clinicId)
        //console.log("clinicRoomResponse: ", clinicResponse)
        if (clinicResponse !== null && clinicResponse !== undefined) {
            if (clinicResponse.status === 200) {
                setKlinikRoomArray(clinicResponse.data)
            }
        }

    }

    const getClinicRequest = async () => {
        let clinicResponse = await getClinic(props.store.baseUrl, props.store.token, props.store.doctor[0].id)
        //console.log("clinicResponse: ", clinicResponse)
        if (clinicResponse !== null && clinicResponse !== undefined) {
            if (clinicResponse.status === 200) {
                setKlinikBilgisi(clinicResponse.data)
            } else if (clinicResponse.status === 401) {
                props.store.setUserLogout()
                window.location.reload()
            }
        }
    }

    const getClinicProductListRequest = async (clinicId) => {
        let clinicProductResponse = await getClinicProduct(props.store.baseUrl, props.store.token, props.store.doctor[0].id, clinicId)
        //console.log("getClinicProductListRequest: ", clinicProductResponse)
        if (clinicProductResponse !== null && clinicProductResponse !== undefined) {
            if (clinicProductResponse.status === 200) {
                if (clinicProductResponse.data !== null) {
                    setProductList(clinicProductResponse.data)
                } else {
                    setProductList([])
                }
            }
        }
    }

    const getClinicConsumableListRequest = async (clinicId) => {
        let clinicConsumableResponse = await getConsumables(props.store.baseUrl, props.store.token, props.store.doctor[0].id, clinicId)
        if (clinicConsumableResponse !== null && clinicConsumableResponse !== undefined) {
            if (clinicConsumableResponse.status === 200) {
                if (clinicConsumableResponse.data !== null) {
                    setConsumableList(clinicConsumableResponse.data)
                } else {
                    setConsumableList([])
                }
            }
        }
    }

    const getConsumableLogListRequest = async (consumableId) => {
        let consumableLogResponse = await getConsumableLogs(props.store.baseUrl, props.store.token, props.store.doctor[0].id, klinikBilgisi.clinicId, consumableId)
        if (consumableLogResponse !== null && consumableLogResponse !== undefined) {
            if (consumableLogResponse.status === 200) {
                if (consumableLogResponse.data !== null) {
                    setConsumableLogList(consumableLogResponse.data)
                } else {
                    setConsumableLogList([])
                }
            }
        }
    }

    const getProductConsumablesRequest = async (clinicId, productId) => {
        let clinicConsumableResponse = await getProductConsumables(props.store.baseUrl, props.store.token, props.store.doctor[0].id, clinicId, productId)
        if (clinicConsumableResponse !== null && clinicConsumableResponse !== undefined) {
            if (clinicConsumableResponse.status === 200) {
                if (clinicConsumableResponse.data !== null) {
                    setProductConsumableList(clinicConsumableResponse.data)
                } else {
                    setProductConsumableList([])
                }
            }
        }
    }

    const getClinicExpenseCategoryListRequest = async (clinicId) => {
        let getClinicExpenseCategoryResponse = await getClinicExpenseCategory(props.store.baseUrl, props.store.token, props.store.doctor[0].id, clinicId)
        //console.log("getClinicExpenseCategoryResponse: ", getClinicExpenseCategoryResponse)
        if (getClinicExpenseCategoryResponse !== null && getClinicExpenseCategoryResponse !== undefined) {
            if (getClinicExpenseCategoryResponse.status === 200) {
                if (getClinicExpenseCategoryResponse.data !== null) {
                    setGiderKategoriListesi(getClinicExpenseCategoryResponse.data)
                } else {
                    setGiderKategoriListesi([])
                }
            }
        }
    }

    const deleteClinicExpenseReq = async (expenseId) => {
        props.store.setLoadingBarStatus(false)

        let deleteClinicExpenseResponse = await deleteClinicExpense(props.store.baseUrl, props.store.token, props.store.doctor[0].id, klinikBilgisi.clinicId, expenseId)
        if (deleteClinicExpenseResponse !== null && deleteClinicExpenseResponse !== undefined) {
            if (deleteClinicExpenseResponse.status === 200) {
                props.store.setMessage("Klinik gider başarıyla silindi.", true, "success")
                await getKlinikGiderListesiReq(props.data.clinicId)
            } else {
                props.store.setMessage("Klinik gideri silinirken hata oluştu", true, "error")
            }
        } else {
            props.store.setMessage("Klinik gideri silinirken hata oluştu", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const deleteClinicExpenseCategoryReq = async (categoryId) => {
        props.store.setLoadingBarStatus(false)

        let deleteClinicExpenseCategoryResponse = await deleteClinicExpenseCategory(props.store.baseUrl, props.store.token, props.store.doctor[0].id, klinikBilgisi.clinicId, categoryId)
        if (deleteClinicExpenseCategoryResponse !== null && deleteClinicExpenseCategoryResponse !== undefined) {
            if (deleteClinicExpenseCategoryResponse.status === 200) {
                props.store.setMessage("Klinik gider kategorisi başarıyla silindi.", true, "success")
                await getClinicExpenseCategoryListRequest(klinikBilgisi.clinicId)
            } else {
                props.store.setMessage("Klinik gider kategori silinirken hata oluştu", true, "error")
            }
        } else {
            props.store.setMessage("Klinik gider kategori silinirken hata oluştu", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }


    const onClickKlinikOdaEkleMenuOpen = () => {
        setKlinikOdaIsmi("")
        setKlinikOdaEkleMenuDisplay(true)
    }

    const handleKlinikOdaEkleMenuClose = () => {
        setKlinikOdaEkleMenuDisplay(false)
    }

    const onChangeKlinikOdaIsmı = (event) => {
        setKlinikOdaIsmi(event.target.value)
    }

    const onClickSaveKlinikOdaMenuButton = async () => {
        props.store.setLoadingBarStatus(false)
        let klinikOda = {
            clinicId: klinikBilgisi.clinicId,
            doctorId: klinikBilgisi.doctorId,
            roomName: klinikOdaIsmi
        }

        let createKlinikOdaResponse = await postClinicRoom(props.store.baseUrl, props.store.token, klinikBilgisi.doctorId, props.data.clinicId, klinikOda)

        if (createKlinikOdaResponse !== undefined && createKlinikOdaResponse !== null) {
            await getClinicRoomList(props.data.clinicId)
            setKlinikOdaEkleMenuDisplay(false)
            props.store.setMessage(createKlinikOdaResponse.data, true, "success")
        } else {
            props.store.setMessage("Klinik odası oluşturulurken hata oluştu", true, "error")
        }
        props.store.setLoadingBarStatus(true)

    }

    const onClickOpenKlinikIsimGuncelle = () => {
        setKlinikIsimMenuDisplay(true)
    }

    const handleKlinikIsimGuncelleMenuClose = () => {
        setKlinikIsimMenuDisplay(false)
    }

    const onChangeKlinikIsim = (event) => {
        setKlinikIsmi(event.target.value)
    }

    const handleKlinikIsimGuncelle = async () => {
        props.store.setLoadingBarStatus(false)
        let klinikBody = toJS(klinikBilgisi)
        klinikBody.name = klinikIsmi
        let updateKlinikIsmiResponse = await putClinic(props.store.baseUrl, props.store.token, klinikBilgisi.doctorId, klinikBody)

        if (updateKlinikIsmiResponse !== undefined && updateKlinikIsmiResponse !== null) {
            if (updateKlinikIsmiResponse.status === 200) {
                await getClinicRequest()
                setKlinikIsimMenuDisplay(false)
                props.store.setMessage("Klinik ismi başarıyla güncellendi.", true, "success")
            } else {
                props.store.setMessage("Klinik ismi oluşturulurken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Klinik ismi oluşturulurken hata oluştu.", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    const onClickDeleteKlinikRoom = async (roomId) => {
        //console.log("roomId: ", roomId)
        props.store.setLoadingBarStatus(false)

        let deleteKlinikRoomResponse = await deleteClinicRoom(props.store.baseUrl, props.store.token, klinikBilgisi.doctorId, klinikBilgisi.clinicId, roomId)
        if (deleteKlinikRoomResponse !== undefined && deleteKlinikRoomResponse !== null) {
            if (deleteKlinikRoomResponse.status === 200) {
                props.store.setMessage("Klinik oda silme işlemi başarıyla tamamlandı.", true, "success")
                await getClinicRoomList(klinikBilgisi.clinicId)
            } else {
                props.store.setMessage("Klinik oda silme işlemi sırasında hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Klinik oda silme işlemi sırasında hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const onClickGuncelleKlinikRoomName = (room) => {
        setKlinikOdaIsmıGuncelleMenuDisplay(true)
        setKlinikOdaIsmi(room.roomName)
        setSelectedKlinikRoomId(room.roomId)
    }

    const handleKlinikOdaGuncelleMenuClose = () => {
        setKlinikOdaIsmıGuncelleMenuDisplay(false)
    }

    const onClickGuncelleKlinikOdaIsmiButton = async () => {
        props.store.setLoadingBarStatus(false)
        let klinikOda = {
            clinicId: klinikBilgisi.clinicId,
            doctorId: klinikBilgisi.doctorId,
            roomName: klinikOdaIsmi,
            roomId: selectedKlinikRoomId
        }

        //console.log("klinikBilgisi: ", klinikBilgisi)

        let updateOdaIsmiResponse = await putClinicRoom(props.store.baseUrl, props.store.token, klinikBilgisi.doctorId, klinikBilgisi.clinicId, klinikOda)
        //console.log("updateOdaIsmiResponse: ", updateOdaIsmiResponse)
        if (updateOdaIsmiResponse !== null) {
            if (updateOdaIsmiResponse.status === 200) {
                await getClinicRoomList(klinikBilgisi.clinicId)
                setKlinikOdaIsmıGuncelleMenuDisplay(false)
                props.store.setMessage("Klinik oda ismi güncelleme işlemi başarıyla tamamlandı.", true, "success")

            } else {
                props.store.setMessage("Klinik oda ismi güncellenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Klinik oda ismi güncellenirken hata oluştu.", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    const onClickOpenKlinikEkleMenu = () => {
        setKlinikEklemeMenuDisplay(true)
    }

    const handleKlinikEklemeMenuClose = () => {
        setKlinikEklemeMenuDisplay(false)
    }

    const saveKlinik = async () => {
        props.store.setLoadingBarStatus(false)

        let klinikData = {
            doctorId: props.store.doctor[0].id,
            name: klinikIsmi,
            address: klinikAdress
        }
        let klinikCreateResponse = await postClinic(props.store.baseUrl, props.store.token, props.store.doctor[0].id, klinikData)
        if (klinikCreateResponse !== undefined && klinikCreateResponse !== null) {
            if (klinikCreateResponse.status === 200) {
                props.store.setMessage("Klinik oluşturma  başarıyla tamamlandı.", true, "success")
                await getClinicRequest()
            } else {
                props.store.setMessage(klinikCreateResponse.data.message, true, "error")
            }
        } else {
            props.store.setMessage("Klinik oluşturulurken hata oluştu.", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    const getIlListesiReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getIlListesiResponse = await getIlListesi(props.store.baseUrl, props.store.token)
        //console.log("getIlListesiResponse: ", getIlListesiResponse)
        if (getIlListesiResponse !== null && getIlListesiResponse !== undefined) {
            if (getIlListesiResponse.status === 200) {
                setIlListesi(getIlListesiResponse.result)
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getIlceListesiReq = async (ilId) => {
        props.store.setLoadingBarStatus(false)

        let getIlceListesiResponse = await getIlceListesi(props.store.baseUrl, props.store.token, ilId)
        //console.log("getIlceListesiResponse: ", getIlceListesiResponse)
        if (getIlceListesiResponse !== null && getIlceListesiResponse !== undefined) {
            if (getIlceListesiResponse.status === 200) {
                setIlceListesi(getIlceListesiResponse.result)
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getMahalleListesiReq = async (districtId) => {
        props.store.setLoadingBarStatus(false)

        let getMahalleListesiResponse = await getMahalleListesi(props.store.baseUrl, props.store.token, districtId)
        //console.log("getMahalleListesiResponse: ", getMahalleListesiResponse)
        if (getMahalleListesiResponse !== null && getMahalleListesiResponse !== undefined) {
            if (getMahalleListesiResponse.status === 200) {
                setMahalleListesi(getMahalleListesiResponse.result)
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const onChangeSelectIlMenu = async (event) => {
        await getIlceListesiReq(event.target.value)
        setKlinikAdress({ ...klinikAdress, cityId: Number(event.target.value) })

    }

    const onChangeSelectIlceMenu = async (event) => {
        await getMahalleListesiReq(event.target.value)
        setKlinikAdress({ ...klinikAdress, districtId: Number(event.target.value) })

    }

    const onChangeSelectMahalleMenu = (event) => {
        setKlinikAdress({ ...klinikAdress, neighbourhoodId: Number(event.target.value) })
    }

    const onChangeAddressTextInput = (event) => {
        setKlinikAdress({ ...klinikAdress, address: event.target.value })
    }

    const handleKlinikPersonelEkleMenuClose = () => {
        setKlinikPersonelEkleMenuDisplay(false)
    }

    const onClickKlinikPersonelEkleMenuOpen = () => {
        setKlinikPersonelEkleMenuDisplay(true)
    }

    const onEmployeeTextInputChange = (event) => {
        setNewEmployee({ ...newEmployee, [event.target.name]: event.target.value })
    }

    const onEmployeePhoneNumberTextInputChange = (event) => {
        setPhoneNumber(event.target.value)
        //setNewEmployee({...newEmployee, mobilePhone: event.target.value})
    }

    const onClickPersonelEkle = async () => {

        //eslint-disable-next-line
        let phoneNumberFixed = phoneNumber.replace(/[\(\)]/g, "").replace("-", "")

        let employeeObject = {
            name: newEmployee.name,
            surname: newEmployee.surname,
            email: newEmployee.email,
            tckno: Number(newEmployee.tckno),
            mobilePhone: Number(phoneNumberFixed),
            gender: Number(newEmployee.gender),
            userType: Number(newEmployee.userType),
            birthDate: newEmployee.birthDate,
            doctorId: props.store.doctor[0].id,
            avatar: ""
        }

        let employeeCreateResponse = await postClinicEmployee(props.store.baseUrl, props.store.token, props.store.doctor[0].id,
            employeeObject)

        //console.log("employeeCreateResponse: ", employeeCreateResponse)

        if (employeeCreateResponse !== null && employeeCreateResponse !== undefined) {
            if (employeeCreateResponse.status === 200) {
                props.store.setMessage("Klinik personeli başarıyla oluşturuldu.", true, "success")
                await getKlinikCalisanListesi(klinikBilgisi.clinicId)
            } else {
                props.store.setMessage("Klinik personeli oluşturulurken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Klinik personeli oluşturulurken hata oluştu.", true, "error")
        }

    }

    const getKlinikCalisanListesi = async (klinikId) => {

        props.store.setLoadingBarStatus(false)

        let getKlinikEmployeeResponse = await getClinicEmployee(props.store.baseUrl, props.store.token, props.store.doctor[0].id, klinikId)
        if (getKlinikEmployeeResponse !== null && getKlinikEmployeeResponse !== undefined) {
            if (getKlinikEmployeeResponse.status === 200) {
                if (getKlinikEmployeeResponse.data !== null) {
                    setEmployeeList(getKlinikEmployeeResponse.data)
                } else {
                    setEmployeeList([])
                }
            }
        }
        props.store.setLoadingBarStatus(true)

    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const deleteKlinikCalisani = async (employeeId) => {
        props.store.setLoadingBarStatus(false)

        let deleteKlinikEmployeeResponse = await deleteClinicEmployee(props.store.baseUrl, props.store.token, props.store.doctor[0].id, employeeId)
        //console.log("deleteKlinikEmployeeResponse", deleteKlinikEmployeeResponse)
        if (deleteKlinikEmployeeResponse !== null && deleteKlinikEmployeeResponse !== undefined) {
            if (deleteKlinikEmployeeResponse.status === 200) {
                props.store.setMessage("Klinik personeli başarıyla silindi.", true, "success")
                await getKlinikCalisanListesi(klinikBilgisi.clinicId)
            } else {
                props.store.setMessage("Klinik personeli silinirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Klinik personeli silinirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const getKlinikGiderListesiReq = async (klinikId) => {
        props.store.setLoadingBarStatus(false)

        let getKlinikGiderListesiResponse = await getClinicExpense(props.store.baseUrl, props.store.token, props.store.doctor[0].id,
            klinikId)
        if (getKlinikGiderListesiResponse !== null && getKlinikGiderListesiResponse !== undefined) {
            if (getKlinikGiderListesiResponse.status === 200) {
                if (getKlinikGiderListesiResponse.data !== null) {

                    //console.log(" getKlinikGiderListesiResponse.data.data: ", getKlinikGiderListesiResponse.data)

                    let giderListesi = getKlinikGiderListesiResponse.data.reverse()

                    setGiderListesi(giderListesi)
                    setBackupGiderListesi(giderListesi)
                    let totalGiderValue = 0
                    for (let index = 0; index < giderListesi.length; index++) {
                        const gider = giderListesi[index];
                        totalGiderValue = totalGiderValue + gider.expenseAmount
                    }
                    setTotalGider(totalGiderValue)
                } else {
                    setGiderListesi([])
                    setBackupGiderListesi([])
                }
            }
        }

        props.store.setLoadingBarStatus(true)
    }


    const handleCloseKlinikGiderEkleMenu = () => {
        setKlinikGiderEkleMenuDisplay(false)
    }

    const onClickGiderEkle = async () => {
        props.store.setLoadingBarStatus(false)

        let klinikObject = {
            clinicId: klinikBilgisi.clinicId,
            expenseAmount: gider.expenseAmount,
            expenseName: gider.expenseName,
            expenseDate: gider.expenseDate,
            categoryId: gider.categoryId,
            fileUrl: gider.fileUrl
        }

        //if (gider.fileUrl !== "") {
        let clinicGiderEkleResponse = await postClinicExpense(props.store.baseUrl, props.store.token,
            props.store.doctor[0].id, klinikBilgisi.clinicId, klinikObject)

        if (clinicGiderEkleResponse !== null && clinicGiderEkleResponse !== undefined) {
            if (clinicGiderEkleResponse.status === 200) {
                props.store.setMessage("Klinik gider bilgisi başarıyla eklendi.", true, "success")
                await getKlinikGiderListesiReq(klinikBilgisi.clinicId)
            } else {
                props.store.setMessage("Klinik gideri eklenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Klinik gideri eklenirken hata oluştu.", true, "error")
        }
        /*} else {
            props.store.setMessage("Gider eklemek için dosya yüklemelisiniz.", true, "error")
        }*/

        props.store.setLoadingBarStatus(true)
    }

    /**
      clinicId: types.optional(types.number, 0),
        expenseAmount: types.optional(types.number, 0),
        expenseName: types.optional(types.string, ""),
        expenseDate: types.optional(types.Date, new Date())
     */

    function getCategoryName(params) {

        let categoryName = ""
        for (let index = 0; index < giderKategoriListesi.length; index++) {
            const kategori = giderKategoriListesi[index];
            if (params.value === kategori.categoryId) {
                categoryName = kategori.categoryName
            }
        }

        return `${categoryName}`;
    }

    const giderColumns = [
        {
            field: "categoryId", headerName: "Kategori", flex: 1,
            valueGetter: getCategoryName
        },
        { field: 'expenseName', headerName: 'Gider İsmi', flex: 1 },
        { field: 'expenseAmount', headerName: 'Miktar (TL)', flex: 1 },
        {
            field: 'expenseDate', headerName: 'Tarih', valueGetter: (params) =>
                `${dayjs(params.value).format("DD.MM.YYYY HH:mm")}`, flex: 1
        },
        {
            field: "expenseId", headerName: "Detaylar", flex: 1,
            renderCell: (params) => (
                <>
                    {
                        (params.row.fileUrl !== null) ?
                            <Tooltip title="Dosya görüntüle" placement="bottom">
                                <Button color="primary" variant="contained"
                                    component={Link} href={props.store.baseUrl + params.row.fileUrl} disabled={(params.row.fileUrl === "") ? true : false} target="_blank">
                                    <ReceiptIcon />
                                </Button>
                            </Tooltip> :
                            <Tooltip title="Dosya görüntüle" placement="bottom">
                                <Button disabled color="primary" variant="contained" onClick={() => onClickOpenGiderBilgisi(params.row)}>
                                    <ReceiptIcon />
                                </Button>
                            </Tooltip>

                    }
                    <Tooltip title="Sil" placement="bottom">
                        <Button color="error" variant="contained" style={{ marginLeft: "10px" }} onClick={() => deleteClinicExpenseReq(params.value)}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>

                </>
            ),
        }
    ];

    const giderCategoryColumns = [
        { field: 'categoryName', headerName: 'Kategori İsmi', flex: 1 },
        {
            field: "categoryId", headerName: "Detaylar", flex: 1,
            renderCell: (params) => (
                <>
                    {
                        (params.row.fileUrl !== null) ?
                            <Tooltip title="Güncelle" placement="bottom">
                                <Button color="primary" variant="contained" onClick={() => onClickOpenUpdateKategoriMenu(params.row)}>
                                    <UpdateIcon />
                                </Button>
                            </Tooltip> :
                            <Tooltip title="Kategori Sil" placement="bottom">
                                <Button disabled color="primary" variant="contained">
                                    <DeleteIcon />
                                </Button>
                            </Tooltip>

                    }
                    <Tooltip title="Sil" placement="bottom">
                        <Button color="error" variant="contained" style={{ marginLeft: "10px" }} onClick={() => deleteClinicExpenseCategoryReq(params.value)}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>

                </>
            ),
        }
    ];

    const onClickOpenGiderEkleMenu = () => {
        setKlinikGiderEkleMenuDisplay(true)
    }

    const handleGiderTipChange = (event) => {
        for (let index = 0; index < giderKategoriListesi.length; index++) {
            const tempKategori = giderKategoriListesi[index];
            if (tempKategori.categoryId === event.target.value) {
                setGider({ ...gider, expenseName: tempKategori.categoryName, categoryId: event.target.value })
            }
        }
    }

    const onChangeGiderTextInput = (event) => {
        setGider({ ...gider, expenseAmount: Number(event.target.value) })
    }

    const onChangeCalendarValue = async (event) => {
        //console.log("year: ", new Date(dayjs(event).valueOf()).getFullYear())
        //console.log("month: ", new Date(dayjs(event).valueOf()).getMonth())
        //console.log("date: ", new Date(dayjs(event).valueOf()).getDate())
        setGider({ ...gider, expenseDate: event })
    }

    const onClickOpenAddProductMenu = () => {
        setProductConsumableList([])
        setAddProductMenuDisplay(true)
    }

    const onClickCloseAddProductMenu = () => {
        setAddProductMenuDisplay(false)
    }

    const onClickOpenAddConsumableMenu = () => {
        setConsumableId(0);
        setConsumableName("");
        setConsumablePcs(0);
        setConsumableUnit("adet");
        setConsumableDesc("");
        setConsumableBrand("");
        setAddConsumableMenuDisplay(true);
    }

    const onClickCloseAddConsumableMenu = () => {
        setAddConsumableMenuDisplay(false)
    }

    const onChangeKlinikHizmetTextInput = (event) => {
        if (event.target.name === "clinicProduct") {
            setClinicProductName(event.target.value)
        } else if (event.target.name === "clinicProductPrice") {
            setClinicProductPrice(!isNaN(Number(event.target.value)) ? Number(event.target.value).toFixed(0) : 0)
        } else if (event.target.name === "clinicProductDuration") {
            setClinicProductDuration(!isNaN(Number(event.target.value)) ? Number(event.target.value).toFixed(0) : 0)
        }
    }

    const onChangeConsumableTextInput = (event) => {
      if (event.target.name === "consumableName") {
        setConsumableName(event.target.value);
      } else if (event.target.name === "consumableBrand") {
        setConsumableBrand(event.target.value);
      } else if (event.target.name === "consumablePcs") {
        setConsumablePcs(event.target.value);
      } else if (event.target.name === "consumableUnit") {
        setConsumableUnit(event.target.value);
      } else if (event.target.name === "consumableDesc") {
        setConsumableDesc(event.target.value);
      }
    };

    const onClickAddKlinikHizmet = async () => {
        props.store.setLoadingBarStatus(false)

        let product = {
            price: Number(clinicProductPrice),
            duration: Number(clinicProductDuration),
            clinicId: klinikBilgisi.clinicId,
            productName: clinicProductName,
            productConsumables: productConsumableList
        }

        let createHizmetResponse = await putClinicProduct(props.store.baseUrl, props.store.token, props.store.doctor[0].id, klinikBilgisi.clinicId, product)
        if (createHizmetResponse !== null && createHizmetResponse !== undefined) {
            if (createHizmetResponse.status === 200) {
                props.store.setMessage("Hizmet ekleme işlemi başarıyla gerçekleştirildi.", true, "success")
                await getClinicProductListRequest(props.data.clinicId)
            } else {
                props.store.setMessage("Hizmet eklenirken hata oluştu", true, "error")
            }
        } else {
            props.store.setMessage("Hizmet eklenirken hata oluştu", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const onClickPutConsumable = async () => {
        if (consumableName.length === 0)
            return

        props.store.setLoadingBarStatus(false)
        const isUpdate = consumableId > 0;

        let consumable = {
            clinicId: klinikBilgisi.clinicId,
            conId: consumableId,
            name: consumableName,
            brand: consumableBrand,
            pcs: consumablePcs,
            unit: consumableUnit,
            desc: consumableDesc,
        }

        let createConsumableResponse = await putConsumable(props.store.baseUrl, props.store.token, props.store.doctor[0].id, klinikBilgisi.clinicId, consumable)
        if (createConsumableResponse !== null && createConsumableResponse !== undefined) {
            if (createConsumableResponse.status === 200) {
                if (isUpdate)
                props.store.setMessage("Ürün güncelleme işlemi başarıyla gerçekleştirildi.", true, "success")
                else
                props.store.setMessage("Ürün ekleme işlemi başarıyla gerçekleştirildi.", true, "success")
                await getClinicConsumableListRequest(props.data.clinicId)
                if (isUpdate)
                    onClickCloseUpdateConsumableMenu();
                else
                    onClickCloseAddConsumableMenu();
            } else {
                if (isUpdate)
                    props.store.setMessage("Ürün güncellenirken hata oluştu", true, "error")
                else
                    props.store.setMessage("Ürün eklenirken hata oluştu", true, "error")
            }
        } else {
            if (isUpdate)
                props.store.setMessage("Ürün güncellenirken hata oluştu", true, "error")
            else
                props.store.setMessage("Ürün eklenirken hata oluştu", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const onClickOpenProductUpdateMenu = (selectedProduct) => {
        getProductConsumablesRequest(klinikBilgisi.clinicId, selectedProduct.productId)

        setClinicProductDuration(selectedProduct.duration)
        setClinicProductPrice(selectedProduct.price)
        setClinicProductId(selectedProduct.productId)
        setClinicProductName(selectedProduct.productName)

        setUpdateProductMenuDisplay(true)

    }

    const onClickCloseProductMenu = () => {
        setUpdateProductMenuDisplay(false)
    }

    const onClickOpenConsumableUpdateMenu = (selectedConsumable) => {

        setConsumableId(selectedConsumable.conId)
        setConsumableName(selectedConsumable.name)
        setConsumablePcs(selectedConsumable.pcs)
        setConsumableUnit(selectedConsumable.unit)
        setConsumableDesc(selectedConsumable.desc)
        setConsumableBrand(selectedConsumable.brand)

        setUpdateConsumableMenuDisplay(true)
    }

    const onClickCloseUpdateConsumableMenu = () => {
        setUpdateConsumableMenuDisplay(false)
    }

    const onClickUpdateKlinikHizmet = async () => {
        props.store.setLoadingBarStatus(false)

        let product = {
            productId: Number(clinicProductId),
            price: Number(clinicProductPrice),
            duration: Number(clinicProductDuration),
            clinicId: klinikBilgisi.clinicId,
            productName: clinicProductName,
            productConsumables: productConsumableList
        }

        let createHizmetResponse = await putClinicProduct(props.store.baseUrl, props.store.token, props.store.doctor[0].id, klinikBilgisi.clinicId, product)
        if (createHizmetResponse !== null && createHizmetResponse !== undefined) {
            if (createHizmetResponse.status === 200) {
                props.store.setMessage("Hizmet güncelleme işlemi başarıyla gerçekleştirildi.", true, "success")
                await getClinicProductListRequest(props.data.clinicId)
            } else {
                props.store.setMessage("Hizmet güncellenirken hata oluştu", true, "error")
            }
        } else {
            props.store.setMessage("Hizmet güncellenirken hata oluştu", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const onClickCloseGiderKategoriEkle = () => {
        setKlinikGiderKategoriDisplay(false)
    }

    const onClickOpenGiderKategoriEkleMenu = () => {
        setKlinikGiderKategoriDisplay(true)
    }
    const onChangeGiderKategorisiTextInput = (event) => {
        setKlinikGiderKategori(event.target.value)
    }

    const onClickAddGiderKategori = async () => {
        props.store.setLoadingBarStatus(false)

        let expenseCategory = {
            CategoryName: klinikGiderKategori
        }

        let putClinicExpenseCategoryResponse = await putClinicExpenseCategory(props.store.baseUrl, props.store.token, props.store.doctor[0].id,
            klinikBilgisi.clinicId, expenseCategory)
        if (putClinicExpenseCategoryResponse !== null && putClinicExpenseCategoryResponse !== undefined) {
            if (putClinicExpenseCategoryResponse.status === 200) {
                props.store.setMessage("Gider kategorisi başarıyla eklendi.", true, "success")
                getClinicExpenseCategoryListRequest(klinikBilgisi.clinicId)
            } else {
                props.store.setMessage("Gider kategorisi eklenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Gider kategorisi eklenirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const deleteClinicProductReq = async (productId) => {
        props.store.setLoadingBarStatus(false)

        let deleteProductResponse = await deleteClinicProduct(props.store.baseUrl, props.store.token, props.store.doctor[0].id,
            klinikBilgisi.clinicId, productId)
        if (deleteProductResponse !== null && deleteProductResponse !== undefined) {
            if (deleteProductResponse.status === 200) {
                await getClinicProductListRequest(props.data.clinicId)
                props.store.setMessage("Hizmet başarıyla silindi.", true, "success")
            } else {
                props.store.setMessage("Hizmet silinirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Hizmet silinirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const deleteClinicConsumableReq = async (conId) => {
        props.store.setLoadingBarStatus(false)

        let deleteConsumableResponse = await deleteConsumable(props.store.baseUrl, props.store.token, props.store.doctor[0].id,
            klinikBilgisi.clinicId, conId)
        if (deleteConsumableResponse !== null && deleteConsumableResponse !== undefined) {
            if (deleteConsumableResponse.status === 200) {
                await getClinicConsumableListRequest(props.data.clinicId)
                props.store.setMessage("Ürün başarıyla silindi.", true, "success")
            } else {
                props.store.setMessage("Ürün silinirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Ürün silinirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const openConsumableLogs = async (conId) => {
        props.store.setLoadingBarStatus(false)
        getConsumableLogListRequest(conId)
        handleConsumableLogDisplay();
        props.store.setLoadingBarStatus(true)
    }

    const getFileUrl = (event) => {
        setGider({ ...gider, fileUrl: event })
    }

    const onClickCloseGiderBilgisiMenu = () => {
        setGiderBilgisiMenuDisplay(false)
    }

    const handleConsumableLogDisplay = () => {
        if (consumableLogDisplay) setConsumableLogList([]);
        setConsumableLogDisplay(!consumableLogDisplay);
    }

    const onClickOpenGiderBilgisi = (gider) => {
        //console.log("gider: ", gider)
        setSelectedGider(gider)
        setGiderBilgisiMenuDisplay(true)
    }

    const onClickPersonelGuncelle = (inputEmployee) => {
        setSelectedEmployee(inputEmployee)
        setUpdateEmployeeMenuDisplay(true)
        console.log("inputEmployee.phoneNumber: ", inputEmployee.phoneNumber)
        setPhoneNumber(inputEmployee.phoneNumber)
    }

    const onClickCloseUpdateEmployeeDisplay = () => {
        setUpdateEmployeeMenuDisplay(false)
    }

    const onUpdateEmployeeTextInputChange = (event) => {
        setSelectedEmployee({ ...selectedEmployee, [event.target.name]: event.target.value })
    }

    const onClickUpdateSelectedEmployee = async () => {
        props.store.setLoadingBarStatus(false)

        let updateEmployeeObject = toJS(selectedEmployee)
        if (phoneNumber !== undefined) {
            //eslint-disable-next-line
            updateEmployeeObject.mobilePhone = phoneNumber.replace(/[\(\)]/g, "").replace("-", "")
        }

        let updateEmployeeResponse = await putClinicEmployee(props.store.baseUrl, props.store.token, props.store.doctor[0].id, updateEmployeeObject)
        if (updateEmployeeResponse !== null && updateEmployeeResponse !== undefined) {
            if (updateEmployeeResponse.status === 200) {
                props.store.setMessage("Klinik Personeli başarıyla güncellendi.", true, "success")
                await getKlinikCalisanListesi(klinikBilgisi.clinicId)
                setUpdateEmployeeMenuDisplay(false)
            } else {
                props.store.setMessage("Klinik Personeli güncellenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Klinik Personeli güncellenirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const handleSelectedConsumable = (event) => {
        let consumable = consumableList.find((consumable)=>consumable.conId === event.target.value)
        setSelectedProductConsumable(event.target.value);
        setConsumableId(consumable.conId)
        setConsumableName(consumable.name)
        setConsumableUnit(consumable.unit)
        setConsumableDesc(consumable.desc)
    }

    const handleAddProductConsumable = () => {
        if (consumablePcs > 0) {
            let addedConsumable = {productId: clinicProductId, clinicId: klinikBilgisi.clinicId, conId: consumableId, name: consumableName, consumePcs: consumablePcs, unit: consumableUnit}
            let consumableList = [...productConsumableList, addedConsumable];
            setProductConsumableList(consumableList)
            setSelectedProductConsumable();
            setConsumableId(0)
            setConsumableName("")
            setConsumablePcs(0)
            setConsumableUnit("adet")
            setConsumableDesc("")
        }
    }

    const handleDeleteProductConsumable = async (id) => {
        let latestList = productConsumableList.filter(consumable => consumable.conId !== id);
        setProductConsumableList(latestList);
    }

    const handleChangeFilterSelectChange = (event) => {
        setSelectedFilter(event.target.value)

        let startDate = null
        let endDate = null

        if (event.target.value >= 0) {
            let filterValue = filterOptions.filter(filter => filter.id === event.target.value)[0]
            let filterDate = dayjs().set("date", 1)
            filterDate = dayjs(filterDate).set("month", filterValue.monthValue)
            filterDate = dayjs(filterDate).set("year", filterValue.year)
            startDate = filterDate.startOf('month')
            endDate = filterDate.endOf('month')
            setStartFilterDate(startDate)
            setEndFilterDate(endDate)
        } else if (event.target.value === -1) {

            startDate = dayjs().subtract(1, 'year').startOf('year')
            endDate = dayjs().subtract(1, 'year').endOf('year')
            setStartFilterDate(startDate)
            setEndFilterDate(endDate)
        } else if (event.target.value === -2) {
            startDate = dayjs().startOf('year')
            endDate = dayjs()
            setStartFilterDate(startDate)
            setEndFilterDate(endDate)
        }

        //console.log("start date: ", startDate)
        //console.log(" end date: ", endDate)

        let filteredIncomeList = backupGiderListesi.filter(filter => dayjs(filter.expenseDate).isAfter(startDate) && dayjs(filter.expenseDate).isBefore(endDate))
        setGiderListesi(filteredIncomeList)
        let totalIncomeValue = 0
        for (let index = 0; index < filteredIncomeList.length; index++) {
            const income = filteredIncomeList[index];
            totalIncomeValue = totalIncomeValue + income.expenseAmount
        }
        //console.log("totalIncomeValue: ", totalIncomeValue)
        setTotalGider(totalIncomeValue)

    }

    const handleStartDateChange = (newValue) => {
        setStartFilterDate(newValue);
    };

    const handleEndDateChange = (newValue) => {
        setEndFilterDate(newValue)
    }

    const onClickDeleteFilter = () => {
        setStartFilterDate(new Date())
        setEndFilterDate(new Date())

        setGiderListesi(backupGiderListesi)

        let totalIncomeValue = 0
        for (let index = 0; index < backupGiderListesi.length; index++) {
            const income = backupGiderListesi[index];
            totalIncomeValue = totalIncomeValue + income.expenseAmount
        }
        setTotalGider(totalIncomeValue)
    }

    const onChangeGiderKategorisiUpdateTextField = (event) => {
        setSelectedKategoriName(event.target.value)

    }

    const onClickOpenUpdateKategoriMenu = (selectedGiderKategori) => {
        setKlinikGiderKategoriMenuDisplay(true)
        setSelectedKategoriName(selectedGiderKategori.categoryName)
        setSelectedKategoriId(selectedGiderKategori.categoryId)
    }

    const onClickUpdateKategori = async () => {

        props.store.setLoadingBarStatus(false)

        let expenseCategory = {
            categoryId: selectedKategoriId,
            categoryName: selectedKategoriName
        }

        let putClinicExpenseCategoryResponse = await putClinicExpenseCategory(props.store.baseUrl, props.store.token, props.store.doctor[0].id,
            klinikBilgisi.clinicId, expenseCategory)
        if (putClinicExpenseCategoryResponse !== null && putClinicExpenseCategoryResponse !== undefined) {
            if (putClinicExpenseCategoryResponse.status === 200) {
                props.store.setMessage("Gider kategorisi başarıyla güncellendi.", true, "success")
                getClinicExpenseCategoryListRequest(klinikBilgisi.clinicId)
            } else {
                props.store.setMessage("Gider kategorisi güncellenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Gider kategorisi güncellenirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)

        setKlinikGiderKategoriMenuDisplay(false)

    }

    const enabizAccount = async () => {
        let enabizAccountResponse = await getEnabizAccount(props.store.baseUrl, props.store.token, props.store.doctor[0].id)
        if (enabizAccountResponse !== null && enabizAccountResponse !== undefined) {
            if (enabizAccountResponse.data.status === 200) {
                if (enabizAccountResponse.data.data !== null) {
                    setEnabizUser(enabizAccountResponse.data.data.username);
                    setEnabizPass(enabizAccountResponse.data.data.password);
                }
            }
        }
    }

    const handleClickShowUsername = () => setShowUsername((show) => !show);

    const handleMouseDownUsername = (event) => {
      event.preventDefault();
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const saveAccount = async () => {
        if(enabizPass === "" || enabizUser === "") {
            return;
        }
        props.store.setLoadingBarStatus(false)

        const putEnabizAccountResponse = await putEnabizAccount(props.store.baseUrl, props.store.token, props.store.doctor[0].id, {username: enabizUser, password: enabizPass})
        if (putEnabizAccountResponse !== null && putEnabizAccountResponse !== undefined && putEnabizAccountResponse.data.status === 200) {
            props.store.setMessage("Enabız hesap bilgileri başarıyla güncellendi.", true, "success")
        } else {
            props.store.setMessage("Bir hata oluştur.", true, "success")
        }
        props.store.setLoadingBarStatus(true)
    }

    return (
        <div className="container-xxl" style={{ marginTop: "0px" }}>
            <Card style={{ marginTop: "8px", height: "auto" }}>
                <CardContent style={{ minHeight: "400px", paddingTop: "0" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="KLİNİK YÖNETİMİ" />
                            <Tab label="GİDER YÖNETİMİ" />
                        </Tabs>
                    </Box>
                    <div hidden={value !== 0}>
                        {(showClinicInfoMenuDisplay) ?
                            <div>
                                <TableContainer style={{ marginTop: "15px", border: "1px solid #e0e0e0" }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>KLİNİK ADI</TableCell>
                                                <TableCell>İşlemler</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={"klinik"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ borderBottom: "1px solid #eaeaea" }}>
                                                <TableCell>
                                                    {klinikBilgisi.name}
                                                    <IconButton onClick={onClickOpenKlinikIsimGuncelle} size="small" color="primary"
                                                        style={{ marginLeft: "10px" }}>
                                                        <EditIcon fontSize='12px' />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    <Button variant="contained" style={{ margin: "10px" }} onClick={onClickKlinikOdaEkleMenuOpen}><AddIcon /> Oda Ekle</Button>
                                                    <Button variant="contained" style={{ margin: "10px" }} onClick={onClickKlinikPersonelEkleMenuOpen}><AddIcon /> Personel Ekle</Button>
                                                    <Button variant="contained" style={{ margin: "10px" }} onClick={onClickOpenAddConsumableMenu}><AddIcon /> Ürün Ekle</Button>
                                                    <Button variant="contained" style={{ margin: "10px" }} onClick={onClickOpenAddProductMenu}><AddIcon /> Hizmet Ekle</Button>

                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Typography color="secondary" variant="body2" style={{ fontWeight: "bold", marginTop: "25px" }}>Klinik Hizmet Listesi</Typography>
                                <TableContainer style={{ marginTop: "15px", border: "1px solid #e0e0e0" }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>HİZMET ADI</TableCell>
                                                <TableCell>HİZMET ÜCRETİ</TableCell>
                                                <TableCell>HİZMET SÜRESİ (DAKİKA)</TableCell>
                                                <TableCell>İŞLEMLER</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {productList.map((product, idx) => (
                                                <TableRow key={idx} >
                                                    <TableCell>
                                                        <Typography variant="body2"> {product.productName}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2"> {product.price} TL</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2"> {product.duration} dk.</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button variant='contained' color="warning" onClick={() => onClickOpenProductUpdateMenu(product)}
                                                            style={{ marginRight: "10px" }}>
                                                            Güncelle
                                                        </Button>
                                                        <Button variant='contained' color="error" onClick={() => deleteClinicProductReq(product.productId)}>
                                                            Sil
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Typography color="secondary" variant="body2" style={{ fontWeight: "bold", marginTop: "25px" }}>Klinik Ürün Listesi</Typography>
                                <TableContainer style={{ marginTop: "15px", border: "1px solid #e0e0e0" }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ÜRÜN ADI</TableCell>
                                                <TableCell>MARKA</TableCell>
                                                <TableCell>ADET</TableCell>
                                                <TableCell>İŞLEMLER</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {consumableList.map((consumable, idx) => (
                                                <TableRow key={idx} >
                                                    <TableCell>
                                                        <Typography variant="body2">{consumable.name}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2">{consumable.brand}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2">{consumable.pcs} {consumable.unit}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button variant='contained' color="warning" onClick={() => onClickOpenConsumableUpdateMenu(consumable)}
                                                            style={{ marginRight: "10px" }}>
                                                            Güncelle
                                                        </Button>
                                                        <Button variant='contained' color="error" onClick={() => deleteClinicConsumableReq(consumable.conId)} style={{ marginRight: "10px" }}>
                                                            Sil
                                                        </Button>
                                                        <Button variant='contained' color="info" onClick={() => openConsumableLogs(consumable.conId)}>
                                                            İncele
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Typography color="secondary" variant="body2" style={{ fontWeight: "bold", marginTop: "25px" }}>Klinik Personel Listesi</Typography>
                                <TableContainer style={{ marginTop: "15px", border: "1px solid #e0e0e0" }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ADI</TableCell>
                                                <TableCell>SOYADI</TableCell>
                                                <TableCell>GÖREVİ</TableCell>
                                                <TableCell>E-POSTA ADRESİ</TableCell>
                                                <TableCell>İŞLEMLER</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {employeeList.map((employee, idx) => (
                                                <TableRow key={idx} >
                                                    <TableCell>
                                                        <Typography variant="body2"> {employee.name}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2"> {employee.surname}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2"> {(employee.userType === 1) ? "Doktor" : (employee.userType === 3) ? "Hemşire" : (employee.userType === 4) ? "Sekreter" : "Diğer"}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2"> {employee.email}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {(employee.userType !== 1) ?
                                                            (props.store.doctor[0] !== undefined) ?
                                                                (employee.doctorId === props.store.doctor[0].id) ? null :
                                                                    <Button variant='contained' color="warning" onClick={() => onClickPersonelGuncelle(employee)}
                                                                        style={{ marginRight: "10px" }}>
                                                                        Güncelle
                                                                    </Button> : null : null}
                                                        {(props.store.doctor[0] !== undefined) ? (employee.doctorId === props.store.doctor[0].id) ? null :
                                                            <Button variant='contained' color="error" onClick={() => deleteKlinikCalisani(employee.doctorId)}>
                                                                Sil
                                                            </Button>
                                                            : null
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Typography color="secondary" variant="body2" style={{ fontWeight: "bold", marginTop: "15px" }}>Klinik Oda Listesi</Typography>

                                <Grid container spacing={3} style={{ minHeight: "250px" }}>
                                    {klinikRoomArray.map((room, idx) => (
                                        <Grid key={idx} item xs={3}>
                                            <Card style={{ background: "rgb(240,240,240)", marginTop: "10px", padding: "10px", paddingTop: "0" }}>
                                                <CardContent style={{ padding: "10px" }}>
                                                    <Typography sx={{ fontSize: 14, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                                                        Klinik Odası
                                                    </Typography>
                                                    <Typography variant="body1" component="div">
                                                        {room.roomName}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button size="small" variant='contained' color="error" style={{ float: "right" }}
                                                        onClick={() => onClickDeleteKlinikRoom(room.roomId)}>
                                                        <DeleteIcon />
                                                        Sil</Button>
                                                    <Button size="small" variant='contained' color='primary' style={{ float: "right" }}
                                                        onClick={() => onClickGuncelleKlinikRoomName(room)}>
                                                        <UpdateIcon />
                                                        Güncelle</Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                                {props.store.userType === 1 && 
                                <>
                                    <Typography color="secondary" variant="body2" style={{ fontWeight: "bold", marginTop: "25px" }}>Enabız Bilgileri</Typography>
                                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Kullanıcı Adı</InputLabel>
                                        <OutlinedInput 
                                            id="outlined-adornment-password"
                                            type={showUsername ? 'text' : 'password'}
                                            value={enabizUser}
                                            onChange={(e)=>setEnabizUser(e.target.value)}
                                            error={enabizUser === ""}
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle username visibility"
                                                onClick={handleClickShowUsername}
                                                onMouseDown={handleMouseDownUsername}
                                                edge="end"
                                                >
                                                {showUsername ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                            label="Kullanıcı Adı"
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Şifre</InputLabel>
                                        <OutlinedInput 
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={enabizPass}
                                            onChange={(e)=>setEnabizPass(e.target.value)}
                                            error={enabizPass === ""}
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                            label="Şifre"
                                        />
                                    </FormControl>
                                    <Button variant="contained" style={{ margin: "16px" }} onClick={saveAccount}>Bilgileri Kaydet</Button>
                                </>
                                }
                            </div> : <div></div>

                        }
                        {
                            (showClinicAddMenuDisplay) ?
                                <div style={{ marginTop: "15px" }}>
                                    <Typography>Henüz bir klinik bilgisi eklemediğiniz için herhangi bir klinik bilgisi gösterilememektedir.</Typography>
                                    <Button variant="contained" style={{ marginRight: "10px" }} onClick={onClickOpenKlinikEkleMenu}><AddIcon /> Klinik Oluştur</Button>
                                </div> : null
                        }
                    </div>
                    <div hidden={value !== 1} style={{ paddingTop: "15px" }}>
                        <Button color='primary' variant='contained' onClick={onClickOpenGiderEkleMenu}>
                            <AddIcon /> Gider Ekle
                        </Button>
                        <Button color='default' variant='outlined' onClick={onClickOpenGiderKategoriEkleMenu} style={{ marginLeft: "10px", float: "right" }}>
                            <SettingsIcon /> Gider Kategori İşlemleri
                        </Button>
                        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                            <FormControl style={{ width: "250px", background: "#e0e0e0", borderRadius: "10px", marginRight: "10px" }}>
                                <Select id="demo-simple-select" variant="outlined" labelId="demo-simple-select-label" value={selectedFilter}
                                    onChange={handleChangeFilterSelectChange} >
                                    <MenuItem key={"seciniz"} value={-99} disabled>Düzenleme Tarihi</MenuItem>
                                    {filterOptions.map((filter, idx) => (
                                        <MenuItem key={idx} value={filter.id}>{(filter.id >= 0) ? filter.monthName + " " + filter.year : filter.monthName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} >
                                <DesktopDatePicker
                                    label="Başlangıç Zamanı"
                                    inputFormat="DD/MM/YYYY"
                                    value={startFilterDate}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => <TextField {...params} style={{ marginRight: "10px" }} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DesktopDatePicker
                                    label="Bitiş Zamanı"
                                    inputFormat="DD/MM/YYYY"
                                    value={endFilterDate}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => <TextField {...params} style={{ marginRight: "10px" }} />} />
                            </LocalizationProvider>

                            <Button color="primary" variant="contained" style={{ float: "right", height: "55px", background: "#e0e0e0", color: "#000" }}
                                onClick={onClickDeleteFilter}>
                                <DeleteIcon /> Temizle
                            </Button>
                        </div>
                        <Box sx={{ height: 425, width: '100%', marginTop: "20px" }}>
                            <DataGrid
                                rows={giderListesi}
                                getRowId={(giderListesi) => giderListesi.expenseId}
                                columns={giderColumns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                checkboxSelection={false}
                                disableSelectionOnClick
                            />
                        </Box>
                        <Typography style={{ float: "right", marginRight: "15px" }}><b>TOPLAM:</b> {totalGider} TL</Typography>
                    </div>
                </CardContent>

            </Card>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={klinikEklemeMenuDisplay}
                onClose={handleKlinikEklemeMenuClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Klinik Oluşturma Menüsü"}
                </DialogTitle>
                <DialogContent style={{ padding: "30px" }}>
                    <Typography variant='body2' style={{ marginBottom: "10px" }}>Bu menüden klinik oluşturma işlemini gerçekleştirebilirsiniz</Typography>
                    <TextField value={klinikIsmi} variant="outlined" label="Klinik İsmi" autoFocus onChange={onChangeKlinikIsim} style={{ width: "100%" }}></TextField>
                    <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <InputLabel id="city-select-label">İl Bilgisi</InputLabel>
                        <Select labelId="city-select-label" label="İl Bilgisi"
                            value={klinikAdress.cityId} id="city-select" style={{ background: "#fff" }}
                            onChange={onChangeSelectIlMenu} MenuProps={MenuProps}>
                            <MenuItem key={"emptyil"} value={0}>İl Seçiniz</MenuItem>
                            {ilListesi.map((il, idx) => (
                                <MenuItem key={idx} value={il.cityId}>{il.cityName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
                        <InputLabel id="city-select-label">İlçe Bilgisi</InputLabel>
                        <Select labelId="city-select-label" label="İlçe Bilgisi"
                            value={klinikAdress.districtId} id="city-select" style={{ background: "#fff" }}
                            onChange={onChangeSelectIlceMenu} MenuProps={MenuProps}>
                            <MenuItem key={"emptyil"} value={0}>İlçe Seçiniz</MenuItem>
                            {ilceListesi.map((ilce, idx) => (
                                <MenuItem key={idx} value={ilce.districtId}>{ilce.districtName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
                        <InputLabel id="city-select-label">Mahalle Bilgisi</InputLabel>
                        <Select labelId="city-select-label" label="Mahalle Bilgisi"
                            value={klinikAdress.neighbourhoodId} id="city-select" style={{ background: "#fff" }}
                            onChange={onChangeSelectMahalleMenu} MenuProps={MenuProps}>
                            <MenuItem key={"emptyil"} value={0}>Mahalle Seçiniz</MenuItem>
                            {mahalleListesi.map((mahalle, idx) => (
                                <MenuItem key={idx} value={mahalle.neighbourhoodId}>{mahalle?.neighbourhoodName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
                        <TextField value={klinikAdress?.address} label="Adres Bilgisi" onChange={onChangeAddressTextInput}></TextField>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleKlinikEklemeMenuClose}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={saveKlinik} >
                        Klinik Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={klinikIsimMenuDisplay}
                onClose={handleKlinikIsimGuncelleMenuClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Klinik İsmi Güncelleme Menüsü"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                    <Typography variant='body2' style={{ marginBottom: "10px" }}>Kliniğiniz ismini buradan değiştirebilirsiniz </Typography>
                    <TextField value={klinikIsmi} variant="outlined" label="Klinik İsmi" autoFocus onChange={onChangeKlinikIsim} style={{ width: "100%" }}></TextField>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleKlinikIsimGuncelleMenuClose}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={handleKlinikIsimGuncelle} >
                        Güncelle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={klinikOdaIsmıGuncelleMenuDisplay}
                onClose={handleKlinikOdaGuncelleMenuClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Klinik Oda İsmi Güncelleme Menüsü"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                    <Typography variant='body2' style={{ marginBottom: "10px" }}>Kliniğiniz için oluşturacağınız odayı, ismini bu ekrandan girerek tanımlayabilirsiniz</Typography>
                    <TextField value={klinikOdaIsmi} variant="outlined" label="Klinik Oda İsmi" autoFocus onChange={onChangeKlinikOdaIsmı} style={{ width: "100%" }}></TextField>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleKlinikOdaGuncelleMenuClose}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={onClickGuncelleKlinikOdaIsmiButton} >
                        Oda İsmi Güncelle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={updateEmployeeMenuDisplay}
                onClose={onClickCloseUpdateEmployeeDisplay}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Klinik Personel Bilgisi Güncelle"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                    <TextField name="tckno" value={selectedEmployee.tckno} label="T.C. Kimlik Numarası*" style={{ width: "100%", marginTop: "10px" }}
                        onChange={onUpdateEmployeeTextInputChange} />
                    <TextField name="name" value={selectedEmployee.name} label="Adı*"
                        style={{ width: "100%", marginTop: "10px" }} onChange={onUpdateEmployeeTextInputChange} />
                    <TextField name="surname" value={selectedEmployee.surname} label="Soyadı*"
                        style={{ width: "100%", marginTop: "10px" }} onChange={onUpdateEmployeeTextInputChange} />
                    <TextField name="email" value={selectedEmployee.email} label="E-posta"
                        style={{ width: "100%", marginTop: "10px" }} onChange={onUpdateEmployeeTextInputChange} />

                    <TextField id="outlined-basic" variant="outlined" name="phoneNumber"
                        value={phoneNumber}
                        type='text'
                        InputProps={{
                            inputComponent: TextMaskCustom,
                        }}
                        style={{ width: "100%", marginBottom: "10px", borderRadius: "10px", marginTop: "10px" }}
                        label="Cep Telefonu Numarası*" onChange={onEmployeePhoneNumberTextInputChange} />


                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                            <DatePicker label="Personel Doğum Tarihi" value={selectedEmployee.birthDate}
                                onChange={(newValue) => setSelectedEmployee({ ...selectedEmployee, birthDate: newValue })}
                                renderInput={(params) => <TextField {...params} />} resizable
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl style={{ marginTop: "10px" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Cinsiyet</FormLabel>
                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={selectedEmployee.gender}>
                            <FormControlLabel value={1} control={<Radio onChange={() => setSelectedEmployee({ ...selectedEmployee, gender: 1 })} />} label="Erkek" />
                            <FormControlLabel value={2} control={<Radio onChange={() => setSelectedEmployee({ ...selectedEmployee, gender: 2 })} />} label="Kadın" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <InputLabel id="demo-simple-select-label">Personel Tür</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedEmployee.userType}
                            label="Personel Tür"
                            onChange={(event) => setSelectedEmployee({ ...selectedEmployee, userType: event.target.value })}>
                            <MenuItem value={1}>Doktor</MenuItem>
                            <MenuItem value={3}>Hemşire</MenuItem>
                            <MenuItem value={4}>Sekreter</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickCloseUpdateEmployeeDisplay}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={onClickUpdateSelectedEmployee} >
                        Güncelle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={klinikOdaEkleMenuDisplay}
                onClose={handleKlinikOdaEkleMenuClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Klinik için Oda Ekleme Menüsü"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                    <Typography variant='body2' style={{ marginBottom: "10px" }}>Kliniğiniz için oluşturacağınız odayı, ismini bu ekrandan girerek tanımlayabilirsiniz</Typography>
                    <TextField value={klinikOdaIsmi} variant="outlined" label="Klinik Oda İsmi" autoFocus onChange={onChangeKlinikOdaIsmı} style={{ width: "100%" }}></TextField>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleKlinikOdaEkleMenuClose}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={onClickSaveKlinikOdaMenuButton} >
                        Oda Ekle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={klinikPersonelEkleMenuDisplay}
                onClose={handleKlinikPersonelEkleMenuClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Klinik Personel Ekle"}
                </DialogTitle>
                <DialogContent style={{ padding: "30px", paddingTop: "0" }}>
                    <TextField name="tckno" value={newEmployee.tckno} label="T.C. Kimlik Numarası*" style={{ width: "100%", marginTop: "10px" }}
                        onChange={onEmployeeTextInputChange} />
                    <TextField name="name" value={newEmployee.name} label="Adı*" style={{ width: "100%", marginTop: "10px" }} onChange={onEmployeeTextInputChange} />
                    <TextField name="surname" value={newEmployee.surname} label="Soyadı*" style={{ width: "100%", marginTop: "10px" }} onChange={onEmployeeTextInputChange} />
                    <TextField name="email" value={newEmployee.email} label="E-posta" style={{ width: "100%", marginTop: "10px" }} onChange={onEmployeeTextInputChange} />

                    <TextField id="outlined-basic" variant="outlined" name="phoneNumber"
                        value={phoneNumber}
                        type='text'
                        InputProps={{
                            inputComponent: TextMaskCustom,
                        }}
                        style={{ width: "100%", marginBottom: "10px", borderRadius: "10px", marginTop: "10px" }}
                        label="Cep Telefonu Numarası*" onChange={onEmployeePhoneNumberTextInputChange} />


                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                            <DatePicker label="Personel Doğum Tarihi" value={newEmployee.birthDate}
                                onChange={(newValue) => setNewEmployee({ ...newEmployee, birthDate: newValue })}
                                renderInput={(params) => <TextField {...params} />} resizable
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl style={{ marginTop: "10px" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Cinsiyet</FormLabel>
                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                            <FormControlLabel value="erkek" control={<Radio onChange={() => setNewEmployee({ ...newEmployee, gender: 1 })} />} label="Erkek" />
                            <FormControlLabel value="kadın" control={<Radio onChange={() => setNewEmployee({ ...newEmployee, gender: 2 })} />} label="Kadın" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <InputLabel id="demo-simple-select-label">Personel Tür</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newEmployee.userType}
                            label="Personel Tür"
                            onChange={(event) => setNewEmployee({ ...newEmployee, userType: event.target.value })}>
                            <MenuItem value={1}>Doktor</MenuItem>
                            <MenuItem value={3}>Hemşire</MenuItem>
                            <MenuItem value={4}>Sekreter</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleKlinikPersonelEkleMenuClose}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={onClickPersonelEkle} >
                        Klinik Personel Ekle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={klinikGiderEkleMenuDisplay}
                onClose={handleCloseKlinikGiderEkleMenu}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Klinik Gider Ekleme Menüsü"}
                </DialogTitle>
                <DialogContent style={{ padding: "30px", paddingTop: "10px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Gider Tipi</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={gider.categoryId}
                            label="Gider Tipi"
                            onChange={handleGiderTipChange} >
                            {giderKategoriListesi.map((giderKategori, idx) => (
                                <MenuItem key={idx} value={giderKategori.categoryId}>{giderKategori.categoryName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField value={gider.expenseAmount} label="Harcama Miktarı (TL)" onChange={onChangeGiderTextInput}
                        style={{ marginTop: "15px", width: "100%" }} />

                    <FormControl fullWidth style={{ marginTop: "15px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                            <DatePicker
                                label="Gider Tarihi"
                                value={gider.expenseDate}
                                onChange={(newValue) => onChangeCalendarValue(newValue)}
                                renderInput={(params) => <TextField {...params} />} resizable
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <div style={{ border: "1px solid #e0e0e0", padding: "10px", marginTop: "10px", borderRadius: "6px" }}>
                        <Typography variant='body2' style={{ marginTop: "10px", fontWeight: "bold" }}>Gider için Dosya Yükle</Typography>
                        <Upload getFileUrl={getFileUrl} fileType={13} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseKlinikGiderEkleMenu}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={onClickGiderEkle} >
                        Klinik Gider Ekle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={addProductMenuDisplay}
                onClose={onClickCloseAddProductMenu}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Klinik Hizmet Ekleme Menüsü"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                    <TextField name="clinicProduct" value={clinicProductName} variant="outlined" label="Klinik Hizmet Adı"
                        onChange={onChangeKlinikHizmetTextInput} style={{ width: "100%", marginTop: "25px" }}></TextField>
                    <TextField name="clinicProductPrice" value={clinicProductPrice} variant="outlined" label="Klinik Hizmet Bedeli (TL)"
                        onChange={onChangeKlinikHizmetTextInput} style={{ width: "100%", marginTop: "25px" }}></TextField>
                    <TextField name="clinicProductDuration" value={clinicProductDuration} variant="outlined" label="Klinik Hizmet Süresi (Dakika)"
                        onChange={onChangeKlinikHizmetTextInput} style={{ width: "100%", marginTop: "25px" }}></TextField>
                            <Grid sx={{ display: "flex", alignItems: 'center' }}>
                    <FormControl style={{ width: "40%", marginTop: "25px" }} >
                        <InputLabel id="consumable-select-label">Tüketilen Ürün</InputLabel>
                        <Select
                            labelId="consumable-select-label"
                            id="consumable-select"
                            value={selectedProductConsumable}
                            label="Tüketilen Ürün"
                            onChange={handleSelectedConsumable} >
                            {consumableList.map((consumable, idx) => {
                                    if (!productConsumableList.find(cons => cons.conId === consumable.conId))
                                    return (
                                    <MenuItem key={idx} value={consumable.conId}>{consumable.name}</MenuItem>
                                )
                                else return null
                            })}
                        </Select>
                    </FormControl>
                    <TextField type="number" name="consumablePcs" value={consumablePcs} variant="outlined" label="Hizmet Başına Ürün Sayısı"
                        onChange={onChangeConsumableTextInput} style={{ width: "30%", marginTop: "25px" }}></TextField>
                        <TextField name="consumableUnit" value={consumableUnit} variant="outlined" label="Ürün Birimi"
                            style={{ width: "20%", marginTop: "25px" }} disabled></TextField>
                            <Tooltip title="Ekle" sx={{ marginTop: '20px' }}>
                            <IconButton onClick={handleAddProductConsumable}>
                                <AddIcon />
                            </IconButton>
                            </Tooltip>
                            </Grid>
                            <Grid style={{ marginTop: "10px" }}>
                                {productConsumableList.map((consumable)=>{return (<Chip sx={{ marginRight: "5px", marginBottom: "5px" }} variant="outlined" label={`${consumable.consumePcs} ${consumable.unit} ${consumable.name}`} key={consumable.conId} onDelete={()=>handleDeleteProductConsumable(consumable.conId)} />)})}
                            </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickCloseAddProductMenu}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={onClickAddKlinikHizmet} >
                        Ekle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={addConsumableMenuDisplay}
                onClose={onClickCloseAddConsumableMenu}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Klinik Ürün Ekleme Menüsü"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                    <TextField error={consumableName.length === 0} name="consumableName" value={consumableName} variant="outlined" label="Ürün Adı"
                        onChange={onChangeConsumableTextInput} style={{ width: "100%" }} inputProps={{ maxLength: 60 }}/>
                            <TextField name="consumableBrand" value={consumableBrand} variant="outlined" label="Ürün Markası"
                                onChange={onChangeConsumableTextInput} style={{ width: "100%", marginTop: "25px" }} inputProps={{ maxLength: 60 }}/>
                    <TextField error={consumablePcs < 0} type="number" name="consumablePcs" value={consumablePcs} variant="outlined" label="Ürün Sayısı"
                        onChange={onChangeConsumableTextInput} style={{ width: "50%", marginTop: "25px" }}/>
                        <TextField name="consumableUnit" value={consumableUnit} variant="outlined" label="Ürün Birimi"
                            onChange={onChangeConsumableTextInput} style={{ width: "50%", marginTop: "25px" }} inputProps={{ maxLength: 20 }}/>
                            <TextField name="consumableDesc" value={consumableDesc} variant="outlined" label="Ürün Ekleme Notu"
                                onChange={onChangeConsumableTextInput} style={{ width: "100%", marginTop: "25px" }} inputProps={{ maxLength: 150 }}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickCloseAddConsumableMenu}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={onClickPutConsumable} >
                        Ekle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={updateProductMenuDisplay}
                onClose={onClickCloseProductMenu}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Klinik Hizmet Güncelleme Menüsü"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>

                    <TextField name="clinicProduct" value={clinicProductName} variant="outlined" label="Klinik Hizmet Adı"
                        onChange={onChangeKlinikHizmetTextInput} style={{ width: "100%", marginTop: "25px" }}></TextField>
                    <TextField name="clinicProductPrice" value={clinicProductPrice} variant="outlined" label="Klinik Hizmet Bedeli (TL)"
                        onChange={onChangeKlinikHizmetTextInput} style={{ width: "100%", marginTop: "25px" }}></TextField>
                    <TextField name="clinicProductDuration" value={clinicProductDuration} variant="outlined" label="Klinik Hizmet Süresi (Dakika)"
                        onChange={onChangeKlinikHizmetTextInput} style={{ width: "100%", marginTop: "25px" }}></TextField>
                        <Grid sx={{ display: "flex", alignItems: 'center' }}>
                    <FormControl style={{ width: "40%", marginTop: "25px" }} >
                        <InputLabel id="consumable-select-label">Tüketilen Ürün</InputLabel>
                        <Select
                            labelId="consumable-select-label"
                            id="consumable-select"
                            value={selectedProductConsumable}
                            label="Tüketilen Ürün"
                            onChange={handleSelectedConsumable} >
                            {consumableList.map((consumable, idx) => {
                                    if (!productConsumableList.find(cons => cons.conId === consumable.conId))
                                    return (
                                    <MenuItem key={idx} value={consumable.conId}>{consumable.name}</MenuItem>
                                )
                                else return null
                            })}
                        </Select>
                    </FormControl>
                    <TextField type="number" name="consumablePcs" value={consumablePcs} variant="outlined" label="Hizmet Başına Ürün Sayısı"
                        onChange={onChangeConsumableTextInput} style={{ width: "30%", marginTop: "25px" }}></TextField>
                        <TextField name="consumableUnit" value={consumableUnit} variant="outlined" label="Ürün Birimi"
                            style={{ width: "20%", marginTop: "25px" }} disabled></TextField>
                            <Tooltip title="Ekle" sx={{ marginTop: '20px' }}>
                            <IconButton onClick={handleAddProductConsumable}>
                                <AddIcon />
                            </IconButton>
                            </Tooltip>
                            </Grid>
                            <Grid style={{ marginTop: "10px" }}>
                                {productConsumableList.map((consumable)=>{return (<Chip sx={{ marginRight: "5px", marginBottom: "5px" }} variant="outlined" label={`${consumable.consumePcs} ${consumable.unit} ${consumable.name}`} key={consumable.conId} onDelete={()=>handleDeleteProductConsumable(consumable.conId)} />)})}
                            </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickCloseProductMenu}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={onClickUpdateKlinikHizmet} >
                        Güncelle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={updateConsumableMenuDisplay}
                onClose={onClickCloseUpdateConsumableMenu}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Klinik Ürün Güncelleme Menüsü"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                <TextField error={consumableName.length === 0} name="consumableName" value={consumableName} variant="outlined" label="Ürün Adı"
                        onChange={onChangeConsumableTextInput} style={{ width: "100%", marginTop: "25px" }} inputProps={{ maxLength: 60 }}/>
                            <TextField name="consumableBrand" value={consumableBrand} variant="outlined" label="Ürün Markası"
                                onChange={onChangeConsumableTextInput} style={{ width: "100%", marginTop: "25px" }} inputProps={{ maxLength: 60 }}/>
                                
                    <TextField error={consumablePcs < 0} type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} name="consumablePcs" value={consumablePcs} variant="outlined" label="Ürün Sayısı"
                        onChange={onChangeConsumableTextInput} style={{ width: "50%", marginTop: "25px" }}/>
                        <TextField name="consumableUnit" value={consumableUnit} variant="outlined" label="Ürün Birimi"
                            onChange={onChangeConsumableTextInput} style={{ width: "50%", marginTop: "25px" }} inputProps={{ maxLength: 20 }}/>
                            <TextField name="consumableDesc" value={consumableDesc} variant="outlined" label="Ürün Güncelleme Notu"
                                onChange={onChangeConsumableTextInput} style={{ width: "100%", marginTop: "25px" }} inputProps={{ maxLength: 150 }}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickCloseUpdateConsumableMenu}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={onClickPutConsumable} >
                        Güncelle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={klinikGiderKategoriDisplay}
                onClose={onClickCloseGiderKategoriEkle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Gider Kategori İşlemleri"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                    <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                        <Grid item xs={9}>
                            <TextField name="clinicProductName" value={klinikGiderKategori} variant="outlined" label="Gider Kategori İsmi"
                                onChange={onChangeGiderKategorisiTextInput} style={{ width: "100%" }}></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <Button color="primary" variant='contained' onClick={onClickAddGiderKategori}
                                style={{ height: "55px" }}>
                                <AddIcon />Ekle
                            </Button>
                        </Grid>
                    </Grid>

                    <Box sx={{ height: 400, width: '100%', marginTop: "20px" }}>
                        <DataGrid rows={giderKategoriListesi}
                            getRowId={(giderKategoriListesi) => giderKategoriListesi.categoryId}
                            columns={giderCategoryColumns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection={false}
                            disableSelectionOnClick
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickCloseGiderKategoriEkle}>Kapat</Button>

                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                open={klinikGiderKategoriMenuDisplay}
                onClose={onClickCloseGiderKategoriEkle}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Gider Kategori Güncelleme Menüsü"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                    <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                        <Grid item xs={9}>
                            <TextField name="clinicProductName" value={selectedKategoriName} variant="outlined" label="Gider Kategori İsmi"
                                onChange={onChangeGiderKategorisiUpdateTextField} style={{ width: "100%" }}></TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickUpdateKategori}>Güncelle</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={giderBilgisiMenuDisplay}
                onClose={onClickCloseGiderBilgisiMenu}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Gider Bilgisi"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                    <img src={props.store.baseUrl + "/" + selectedGider.fileUrl} style={{ width: "150px" }} alt={selectedGider.fileUrl} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickCloseGiderBilgisiMenu}>Kapat</Button>
                    <Button color="primary" variant='contained' onClick={onClickAddGiderKategori} >
                        Ekle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"xl"}
                open={consumableLogDisplay}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Ürün Geçmişi"}
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                <TableContainer style={{ marginTop: "15px", border: "1px solid #e0e0e0" }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Adet</TableCell>
                                                <TableCell>Açıklama</TableCell>
                                                <TableCell>Hizmet Adı</TableCell>
                                                <TableCell>Tarih</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {consumableLogList.map((log, i) => <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ borderBottom: "1px solid #eaeaea", backgroundColor: log.isConsuming ? "#ffc6c6" : "" }}>
                                                <TableCell>
                                                    {log.pcs}
                                                </TableCell>
                                                <TableCell>
                                                    {log.description}
                                                </TableCell>
                                                <TableCell>
                                                    {!!log.name ? log.name : "-"}
                                                </TableCell>
                                                <TableCell>
                                                    {dayjs(log.logDate).format("DD.MM.YYYY HH:mm:ss")}
                                                </TableCell>
                                            </TableRow>)}
                                            
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConsumableLogDisplay}>Kapat</Button>
                </DialogActions>
            </Dialog>


        </div>
    )
}))

//clinicProductName clinicProductPrice