import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "mobx-react";
import { onSnapshot } from "mobx-state-tree";

import App from "./App";

import { WholeMedicsStore } from "./store/WholeMedicsStore";

let colorFontArray = [
	{
		id: 0,
		fontFamily: "'Josefin+Sans', sans-serif",
		type: "light",
		primary: "#6400e5",
		secondary: "#390082",
	},
	{
		id: 1,
		fontFamily: "'Josefin+Sans', sans-serif",
		type: "dark",
		primary: "#b0b0b0",
		secondary: "#b0b0b0",
	},
];

const version = "wholemedics.prod.doc.0.0.3";

let initialState = {
	baseUrl: window.location.href.includes("beta")
		? "https://beta-api.wholemedics.com.tr"
		: window.location.href.includes("localhost")
		? "https://localhost:7097"
		: "https://api.wholemedics.com.tr",
	darkMode: false,
	isLogin: false,
	token: "",
	messageInfo: "",
	messageDisplay: false,
	messageType: "info",
	colorFontArray: colorFontArray,
	selectedColorFont: colorFontArray[0],
};

if (localStorage.getItem(version)) {
	initialState = JSON.parse(localStorage.getItem(version));
}

let wholeMedicsStore = WholeMedicsStore.create(initialState);

onSnapshot(wholeMedicsStore, (snapshot) => {
	localStorage.setItem(version, JSON.stringify(snapshot));
});

function renderApp() {
	ReactDOM.render(
		<Provider store={wholeMedicsStore}>
			<App />
		</Provider>,
		document.getElementById("root")
	);
}

renderApp();
