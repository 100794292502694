import { types } from "mobx-state-tree"

export const DoctorDiploma = types
    .model({
        doctorId: types.optional(types.number, 0),
        diplomaNo: types.optional(types.string, ""),
        univercityId: types.optional(types.number, 0),
        diplomaImage: types.optional(types.string, ""),
        diplomaDate: types.optional(types.Date, new Date())
    }).actions(self => ({

    }))