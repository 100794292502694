import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import dayjs from "dayjs";
import { toJS } from "mobx";

import { DataGrid, trTR } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers";
import isBefore from "date-fns/isBefore";

import "dayjs/locale/tr";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Switch,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import NotesIcon from "@mui/icons-material/Notes";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import SmsIcon from "@mui/icons-material/Sms";
import InventoryIcon from "@mui/icons-material/Inventory";

import { Appointment } from "../../../model/Appointment";
import { DoctorAllSettings } from "../../../model/Doctor/DoctorAllSettings";
import { Patient } from "../../../model/Patient";
import { OfflinePatient } from "../../../model/Patient/OfflinePatient";

import getAppointmentHoursByDayId from "../../../utils/api/Doctor/Appointment/GetAppointmentHoursByDayId";
import getAppointmentHoursByDayIdAndRoomId from "../../../utils/api/Doctor/Appointment/GetAppointmentHoursByDayIdAndRoomId";
import postAppointment from "../../../utils/api/Doctor/Appointment/PostAppointment";
import getAppointmentList from "../../../utils/api/Doctor/Appointment/GetAppointmentList";
import getClinic from "../../../utils/api/Doctor/Clinic/GetClinic";
import getClinicRoom from "../../../utils/api/Doctor/Clinic/GetClinicRoom";
import getClinicProduct from "../../../utils/api/Doctor/Clinic/GetClinicProduct";
import getClinicEmployee from "../../../utils/api/Doctor/Clinic/GetClinicEmployee";
import getDoctorSettings from "../../../utils/api/Doctor/GetDoctorSettings";
import {
	createOfflinePatient,
	updateOfflinePatient,
	changeDoctorPatientStatus,
} from "../../../utils/api/Doctor/Patient/OfflinePatient";
import patientsGetForDoctor from "../../../utils/api/Doctor/GetPatientsforDoctor";
import { getDoctorPromos, insertPatientPromo } from "../../../utils/api/Doctor/Promos";

import PromoCard from "../../common/PromoCard";

export const HastaListesiMenu = inject("store")(
	observer((props) => {
		const [searchText, setSearchText] = useState("");

		const [hastaListesi, setHastaListesi] = useState([]);
		const [backupHastaListesi, setBackupHastaListesi] = useState(null);

		const [randevu, setRandevu] = useState(Appointment.create({}));
		const [sendNoSMS, setSendNoSMS] = useState(false);
		const [freeHoursForSelectedDay, setFreeHoursForSelectedDay] = useState([]);
		const [selectedHourArray, setSelectedHourArray] = useState([]);
		const [appoTime, setAppoTime] = useState(30);
		const [statusType, setStatusType] = useState(0);

		const [randevuDialogMenuDisplay, setRandevuDialogMenuDisplay] = useState(false);
		const [offlineRandevuAccordionDisplay, setOfflineRandevuAccordionDisplay] = useState(false);

		const [pkgDialogMenuDisplay, setPkgDialogMenuDisplay] = useState(false);
		const [selectedPromoId, setSelectedPromoId] = useState(null);
		const [promoList, setPromoList] = useState([]);

		const [selectedPatient, setSelectedPatient] = useState(Patient.create({}));

		const [clinicRoomList, setClinicRoomList] = useState([]);
		const [showRoomListMenu, setShowRoomListMenu] = useState(false);
		const [hizmetList, setHizmetList] = useState([]);
		const [selectedHizmetId, setSelectedHizmetId] = useState(0);
		const [selectedHizmetList, setSelectedHizmetList] = useState([]);
		const [selectedHizmetToplamFiyat, setSelectedHizmetToplamFiyat] = useState(0);

		const [clinicDoctorList, setClinicDoctorList] = useState([]);
		const [selectedDoctorId, setSelectedDoctorId] = useState(0);

		const [doctorSettings, setDoctorSettings] = useState(DoctorAllSettings.create({}));

		const [offlineRandevuGenelToplam, setOfflineRandevuGenelToplam] = useState(0);

		const [addPatientMenuDisplay, setAddPatientMenuDisplay] = useState(false);
		const [updatePatientMenuDisplay, setUpdatePatientMenuDisplay] = useState(false);
		const [offlinePatient, setOfflinePatient] = useState(OfflinePatient.create({}));
		const [showAll, setShowAll] = useState(false);

		useEffect(() => {
			// Update the document title using the browser API
			if (props.data.length > 0) {
				setHastaListesi(props.data);
				setBackupHastaListesi(props.data);
				getPromoList();
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.data]);

		useEffect(() => {
			getDoctorSettingsReq();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			setOfflineRandevuGenelToplam(
				Number(randevu.examinationPrice) + Number(randevu.priceOffline)
			);
		}, [randevu.examinationPrice, randevu.priceOffline]);

		const getDoctorSettingsReq = async () => {
			props.store.setLoadingBarStatus(false);

			let doctorSettings = null;
			let doctorSettingsResponse = await getDoctorSettings(
				props.store.baseUrl,
				props.store.token,
				props.store.user[0].id
			);
			if (doctorSettingsResponse !== undefined && doctorSettingsResponse !== null) {
				if (doctorSettingsResponse.status === 200) {
					setDoctorSettings(doctorSettingsResponse.data.data);

					doctorSettings = doctorSettingsResponse.data.data;

					getClinicRoomHizmetListReq(doctorSettings);
				}
			} else {
				props.store.setMessage("Hasta bilgisi getirilirken hata oluştu.", true, "error");
			}
			props.store.setLoadingBarStatus(true);
		};

		const getPromoList = async () => {
			props.store.setLoadingBarStatus(false);
			let getPromoListRes = await getDoctorPromos(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id
			);
			if (getPromoListRes !== null && getPromoListRes !== undefined) {
				if (getPromoListRes.status === 200) {
					if (getPromoListRes.data !== null) {
						setPromoList(getPromoListRes.data);
					} else {
						setPromoList([]);
					}
				}
			}
			props.store.setLoadingBarStatus(true);
		};

		const getClinicRoomHizmetListReq = async (doctorSettings) => {
			let clinicId = null;
			let getClinicResponse = await getClinic(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id
			);
			if (getClinicResponse !== null && getClinicResponse !== undefined) {
				if (getClinicResponse.status === 200) {
					if (getClinicResponse.data !== null) {
						clinicId = getClinicResponse.data.clinicId;
						setRandevu({
							...randevu,
							clinicId: clinicId,
							examinationPrice: doctorSettings.doctorPrice.offlineExaminationPrice,
						});
					}
				}
			}
			if (clinicId !== null) {
				let clinicRoomListResponse = await getClinicRoom(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					clinicId
				);
				if (clinicRoomListResponse !== null && clinicRoomListResponse !== undefined) {
					if (clinicRoomListResponse.status === 200) {
						setClinicRoomList(clinicRoomListResponse.data);

						let clinicRoomList = clinicRoomListResponse.data;
						for (let index = 0; index < clinicRoomList.length; index++) {
							const clinicRoomTemp = clinicRoomList[index];
							clinicRoomTemp.roomName = "Oda: " + clinicRoomTemp.roomName;
						}
					}
				}
				let clinicHizmetResponse = await getClinicProduct(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					clinicId
				);
				if (clinicHizmetResponse !== null && clinicHizmetResponse !== undefined) {
					if (clinicHizmetResponse.status === 200) {
						if (clinicHizmetResponse.data !== null) {
							setHizmetList(clinicHizmetResponse.data);
						} else {
							setHizmetList([]);
						}
					}
				}

				let clinicEmployeeResponse = await getClinicEmployee(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					clinicId
				);

				if (clinicEmployeeResponse !== null && clinicEmployeeResponse !== undefined) {
					if (clinicEmployeeResponse.status === 200) {
						let tempEmployeeList = clinicEmployeeResponse.data;
						if (clinicEmployeeResponse.data !== null) {
							let doctorList = [];
							for (let index = 0; index < tempEmployeeList.length; index++) {
								const tempEmployee = tempEmployeeList[index];
								//if (tempEmployee.userType === 1) {
								doctorList.push(tempEmployee);
								//}
							}
							setClinicDoctorList(doctorList);
						} else {
							setClinicDoctorList([]);
						}
					}
				}
			}
		};

		/*const getirHastaListesi = async (start, end) => {
        props.store.setLoadingBarStatus(false)

        props.store.setLoadingBarStatus(true)
    }*/

		const columns = [
			{
				field: "tckno",
				headerName: "T.C. Kimlik No",
				flex: 1,
				renderCell: (params) => {
					if (params.value) {
						return (
							<Typography style={{ opacity: params.row.isActive ? "1" : ".5" }}>
								{params.value.toString().substr(0, 3) + "********"}
							</Typography>
						);
					} else {
						return <></>;
					}
				},
			},
			{
				field: "name",
				headerName: "Adı",
				flex: 1,
				renderCell: (params) => (
					<Typography style={{ opacity: params.row.isActive ? "1" : ".5" }}>
						{params.value}
					</Typography>
				),
			},
			{
				field: "surname",
				headerName: "Soyadı",
				flex: 1,
				renderCell: (params) => (
					<Typography style={{ opacity: params.row.isActive ? "1" : ".5" }}>
						{params.value}
					</Typography>
				),
			},
			{
				field: "birthDate",
				headerName: "Yaş",
				flex: 1,
				renderCell: (params) => {
					const yearOld =
						dayjs(params.value).year() > 1900
							? dayjs(new Date()).diff(new Date(params.value), "year")
							: "-";
					if (yearOld) {
						return (
							<Typography style={{ opacity: params.row.isActive ? "1" : ".5" }}>
								{yearOld}
							</Typography>
						);
					} else {
						return <></>;
					}
				},
			},
			{
				field: "gender",
				headerName: "Cinsiyet",
				flex: 1,
				renderCell: (params) => (
					<Typography style={{ opacity: params.row.isActive ? "1" : ".5" }}>
						{params.value === 1 ? "Erkek" : params.value === 2 ? "Kadın" : "Belirsiz"}
					</Typography>
				),
			},
			{
				field: "patientId",
				headerName: "İşlemler",
				width: 450,
				renderCell: (params) => (
					<>
						{params.row.isActive && (
							<>
								<Tooltip title="Randevu oluştur" placement="bottom">
									<Button
										color="primary"
										variant="contained"
										style={{ float: "right" }}
										onClick={() => handleOpenRandevuMenu(params.row)}
									>
										<CalendarMonthIcon style={{ marginRight: "10px" }} />{" "}
										Randevu
									</Button>
								</Tooltip>
								<Tooltip title="Kullanıcıya paket tanımla" placement="bottom">
									<Button
										color="primary"
										variant="contained"
										style={{ marginLeft: "15px", float: "right" }}
										onClick={() => handleOpenPkgMenu(params.row)}
									>
										<InventoryIcon style={{ marginRight: "10px" }} /> Paket
									</Button>
								</Tooltip>
							</>
						)}
						<Tooltip title="Hasta bilgilerini görüntüle" placement="bottom">
							<Button
								href={"/panel/hasta/" + params.value}
								color="primary"
								component={Link}
								variant="contained"
								style={{
									marginLeft: params.row.isActive ? "15px" : "0",
									float: "right",
								}}
							>
								<InfoIcon style={{ marginRight: "10px" }} />
								Bilgi
							</Button>
						</Tooltip>
						{params.row.isActive ? (
							<Tooltip title="Güncelle" placement="bottom">
								<Button
									color="warning"
									variant="contained"
									style={{ marginLeft: "15px", float: "right" }}
									onClick={() => handleUpdatePatient(params.row)}
								>
									<ChangeCircleIcon style={{ marginRight: "10px" }} />
									Güncelle
								</Button>
							</Tooltip>
						) : (
							<Tooltip title="Aktifleştir" placement="bottom">
								<Button
									color="primary"
									variant="contained"
									style={{ marginLeft: "15px", float: "right" }}
									onClick={() => handleStatusPatient(params.row)}
								>
									<ChangeCircleIcon style={{ marginRight: "10px" }} />
									Aktifleştir
								</Button>
							</Tooltip>
						)}
					</>
				),
			},
		];

		const onChangeSearchTextField = async (event) => {
			setSearchText(event.target.value);
		};

		const onClickDeleteSearchText = () => {
			setSearchText("");
			setHastaListesi(backupHastaListesi);
		};

		const onClickHastaSearch = async () => {
			if (searchText.length > 2) {
				if (!isNaN(Number(searchText))) {
					if (searchText.length === 11) {
						const filteredPatientList = backupHastaListesi.filter(
							(hasta) => hasta.tckno === Number(searchText)
						);
						if (filteredPatientList.length > 0) {
							setHastaListesi(filteredPatientList);
						} else {
							props.store.setMessage("Hasta kaydı bulunamadı.", true, "error");
						}
					}
				} else {
					const filteredPatientList = backupHastaListesi.filter(
						(hasta) =>
							hasta.name.includes(searchText.toLocaleUpperCase("TR")) ||
							hasta.surname.includes(searchText.toLocaleUpperCase("TR"))
					);
					if (filteredPatientList.length > 0) {
						setHastaListesi(filteredPatientList);
					} else {
						props.store.setMessage("Hasta kaydı bulunamadı.", true, "error");
					}
				}
			} else {
				setHastaListesi(backupHastaListesi);
			}
		};

		const handleCloseRandevuOlusturMenu = () => {
			setRandevuDialogMenuDisplay(false);
		};

		const handleClosePkgOlusturMenu = () => {
			setPkgDialogMenuDisplay(false);
			setSelectedPromoId(null);
		};

		const handleOpenRandevuMenu = (inputPatient) => {
			setSendNoSMS(false);
			setAppoTime(doctorSettings.doctorPrice.examinationTime);
			setSelectedPatient(inputPatient);
			setRandevuDialogMenuDisplay(true);
			setStatusType(0);
		};

		const handleOpenPkgMenu = (inputPatient) => {
			if (promoList.length > 0) {
				setSelectedPatient(inputPatient);
				setPkgDialogMenuDisplay(true);
			} else {
				props.store.setMessage("Paket bulunamadı.", true, "error");
			}
		};

		const handleUpdatePatient = (inputPatient) => {
			const patient = { ...inputPatient };
			patient.birthDate = dayjs(patient.birthDate).year() > 1900 ? patient.birthDate : null;
			setOfflinePatient(patient);
			handleUpdateMenu();
		};

		const handleStatusPatient = async (inputPatient, closeModal = false) => {
			const patient = { ...inputPatient };
			const changeStatusResponse = await changeDoctorPatientStatus(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id,
				patient.patientId
			);
			if (changeStatusResponse !== null && changeStatusResponse !== undefined) {
				if (changeStatusResponse.status === 200) {
					if (
						changeStatusResponse.result !== null &&
						changeStatusResponse.result === patient.patientId
					) {
						props.store.setMessage("İşlem başarılı.", true, "success");
						if (closeModal) {
							handleUpdateMenu();
						}
						let hastaListesiResponse = await patientsGetForDoctor(
							props.store.baseUrl,
							props.store.token,
							props.store.doctor[0].id
						);
						if (hastaListesiResponse !== undefined && hastaListesiResponse !== null) {
							if (hastaListesiResponse.status === 200) {
								setHastaListesi(hastaListesiResponse.data);
							} else if (hastaListesiResponse.status === 401) {
								props.store.setUserLogout();
								window.location.reload();
							}
						} else {
							this.props.store.setMessage(
								"Hasta listesi getirilirken hata oluştu.",
								true,
								"error"
							);
						}
					}
				}
			}
		};

		const handlePromoId = (event) => {
			setSelectedPromoId(event.target.value);
		};

		const handleChangeGorusmeTipi = async (event) => {
			if (event.target.value === 2) {
				let klinikId = await getKlinikBilgisiReq();

				if (klinikId === null) {
					props.store.setMessage(
						"Henüz sistemde kayıtlı bir klinik bilgisi bulunamadığından offline randevu oluşturamazsınız.",
						true,
						"error"
					);
				} else {
					setOfflineRandevuAccordionDisplay(true);
					setShowRoomListMenu(true);
					setRandevu({ ...randevu, appointmentType: event.target.value });
				}
			} else {
				setShowRoomListMenu(false);
				setOfflineRandevuAccordionDisplay(false);
				let dayValue =
					new Date().getFullYear() +
					"-" +
					(new Date().getMonth() + 1) +
					"-" +
					new Date().getDate();

				let isOffline = false;
				let roomId = 0;
				await getFreeHourList(null, dayValue, isOffline, roomId);

				setRandevu({
					...randevu,
					appointmentType: event.target.value,
					doctorId: props.store.doctor[0].id,
				});
			}
		};

		const getKlinikBilgisiReq = async () => {
			let clinicId = null;
			let getClinicResponse = await getClinic(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id
			);
			if (getClinicResponse !== null && getClinicResponse !== undefined) {
				if (getClinicResponse.status === 200) {
					if (getClinicResponse.data !== null) {
						clinicId = getClinicResponse.data.clinicId;
					}
				}
			}
			return clinicId;
		};

		const getFreeHourList = async (
			doctorId,
			dayValue,
			isOffline,
			roomId,
			pastDate = undefined
		) => {
			const past =
				pastDate ||
				(pastDate === undefined &&
					isBefore(
						randevu.appointmentDate,
						dayjs(new Date()).set("hour", 0).set("minute", 1).valueOf()
					));
			const freeDate = new Date(dayValue);
			const vacationDate = new Date(doctorSettings?.doctorPrice?.vacationDate);
			const vacationEndDate = new Date(doctorSettings?.doctorPrice?.vacationEndDate);
			vacationEndDate.setDate(vacationEndDate.getDate() + 1);
			if (!past && vacationEndDate >= freeDate && freeDate >= vacationDate) {
				setFreeHoursForSelectedDay([]);
				return props.store.setMessage(
					"Seçilen tarih için randevular kapatılmıştır.",
					true,
					"error"
				);
			}
			let getHoursListResponse = null;
			if (isOffline) {
				let isOk = true;
				let errorMessage = "";
				if (doctorId === 0) {
					isOk = false;
					errorMessage = "Doktor seçmeden, müsait saatleri göremezsiniz.";
				}

				let clinicId = await getKlinikBilgisiReq();

				if (clinicId === 0) {
					isOk = false;
					errorMessage =
						"Klinik bilgisi getirilemediğinden müsait saatleri görüntüleyemiyorsunuz.";
				}

				if (roomId === 0) {
					isOk = false;
					errorMessage = "Oda seçmediğiniz için müsait saatleri görüntüleyemezsiniz.";
				}

				if (isOk) {
					getHoursListResponse = await getAppointmentHoursByDayIdAndRoomId(
						props.store.baseUrl,
						props.store.token,
						doctorId,
						dayValue,
						clinicId,
						roomId
					);
				} else {
					props.store.setMessage(errorMessage, true, "error");
				}
			} else {
				getHoursListResponse = await getAppointmentHoursByDayId(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					dayValue
				);
			}

			if (getHoursListResponse !== undefined && getHoursListResponse !== null) {
				if (getHoursListResponse.status === 200) {
					let freeHourList = getHoursListResponse.data;

					if (freeHourList !== null) {
						let freeHours = [];

						for (let index = 0; index < freeHourList.length; index++) {
							let tempFreeHour = freeHourList[index];

							let startHour = tempFreeHour.startDate;
							if (tempFreeHour.isActive) {
								startHour = startHour.split("T")[1];
								startHour = startHour.split(":")[0] + ":" + startHour.split(":")[1];
								freeHours.push(startHour);
							}
						}

						setFreeHoursForSelectedDay(freeHours);
					} else {
						setFreeHoursForSelectedDay([]);
						props.store.setMessage(
							"Seçtiğiniz tarihte, randevuya uygun bir saat bulunamadı.",
							true,
							"error"
						);
					}
				}
			}
		};

		const handleChangeDoctorSelectMenu = (event) => {
			setRandevu({ ...randevu, doctorId: event.target.value });
			setSelectedDoctorId(event.target.value);
		};

		const handleChangeAppointmentRoom = async (event) => {
			let dayValue =
				new Date().getFullYear() +
				"-" +
				(new Date().getMonth() + 1) +
				"-" +
				new Date().getDate();

			await getFreeHourList(selectedDoctorId, dayValue, true, event.target.value);
			setRandevu({ ...randevu, roomId: event.target.value });
		};

		const handleChangeAppointmentHizmet = (event) => {
			setSelectedHizmetId(event.target.value);
		};

		const onClickHizmetEkle = () => {
			let offlineRandevuProductList = toJS(randevu.productsOffline);
			let toplamHizmetFiyat = 0;
			for (let index = 0; index < hizmetList.length; index++) {
				const hizmetTemp = hizmetList[index];

				if (hizmetTemp.productId === selectedHizmetId) {
					if (selectedHizmetList.includes(hizmetTemp)) {
						props.store.setMessage(
							"Seçtiğiniz hizmet, listede zaten mevcut",
							true,
							"warning"
						);
					} else {
						setSelectedHizmetList([...selectedHizmetList, hizmetTemp]);

						setSelectedHizmetToplamFiyat(selectedHizmetToplamFiyat + hizmetTemp.price);
						toplamHizmetFiyat = selectedHizmetToplamFiyat + hizmetTemp.price;
					}
				}
			}

			/*for (let index = 0; index < selectedHizmetList.length; index++) {
            const selectedHizmetTemp = selectedHizmetList[index];
            offlineRandevuProductList.push(selectedHizmetTemp.productId)
        }*/

			if (randevu.productsOffline.includes(selectedHizmetId)) {
			} else {
				offlineRandevuProductList.push(selectedHizmetId);
			}

			setRandevu({
				...randevu,
				productsOffline: offlineRandevuProductList,
				priceOffline: toplamHizmetFiyat,
			});
		};

		const onClickRemoveHizmet = (selectedHizmetId) => {
			let offlineRandevuProductList = toJS(randevu.productsOffline);
			let toplamHizmetFiyat = 0;
			for (let index = 0; index < hizmetList.length; index++) {
				const hizmetTemp = hizmetList[index];

				if (hizmetTemp.productId === selectedHizmetId) {
					setSelectedHizmetToplamFiyat(selectedHizmetToplamFiyat - hizmetTemp.price);
					toplamHizmetFiyat = selectedHizmetToplamFiyat - hizmetTemp.price;
				}
			}
			setSelectedHizmetList(
				selectedHizmetList.filter((a) => a.productId !== selectedHizmetId)
			);
			if (randevu.productsOffline.includes(selectedHizmetId)) {
				offlineRandevuProductList = offlineRandevuProductList.filter(
					(a) => a !== selectedHizmetId
				);
			}
			setRandevu({
				...randevu,
				productsOffline: offlineRandevuProductList,
				priceOffline: toplamHizmetFiyat,
			});
		};

		const onChangeOfflinePriceTextField = (event) => {
			if (event.target.name === "offline")
				setRandevu({ ...randevu, priceOffline: Number(event.target.value) });
			if (event.target.name === "examination")
				setRandevu({ ...randevu, examinationPrice: Number(event.target.value) });
		};

		const onChangeCalendarValue = async (event) => {
			const past = isBefore(
				new Date(dayjs(event).valueOf()),
				dayjs(new Date()).set("hour", 0).set("minute", 1).valueOf()
			);
			let vacation = false;
			const freeDate = new Date(dayjs(event).valueOf());
			const vacationDate = new Date(doctorSettings?.doctorPrice?.vacationDate);
			const vacationEndDate = new Date(doctorSettings?.doctorPrice?.vacationEndDate);
			vacationEndDate.setDate(vacationEndDate.getDate() + 1);
			if (!past && vacationEndDate >= freeDate && freeDate >= vacationDate) {
				setFreeHoursForSelectedDay([]);
				props.store.setMessage(
					"Seçilen tarih için randevular kapatılmıştır.",
					true,
					"error"
				);
				vacation = true;
			}

			let dayValue =
				new Date(dayjs(event).valueOf()).getFullYear() +
				"-" +
				(new Date(dayjs(event).valueOf()).getMonth() + 1) +
				"-" +
				new Date(dayjs(event).valueOf()).getDate();

			let isOffline = false;
			let roomId = 0;
			let doctorId = null;
			if (randevu.appointmentType === 2) {
				isOffline = true;
				roomId = randevu.roomId;
				doctorId = selectedDoctorId;
			}

			let startDate = dayjs(event).valueOf();
			let endDate = dayjs(event).add(30, "minute").valueOf();
			setRandevu({
				...randevu,
				appointmentDate: new Date(startDate),
				appointmentEndDate: new Date(endDate),
			});
			if (past) {
				props.store.setMessage("Geçmiş tarihli randevu oluşturuyorsunuz", true, "info");
				setSendNoSMS(true);
				setRandevu({
					...randevu,
					appointmentDate: new Date(startDate),
					appointmentEndDate: new Date(endDate),
				});
			} else {
				setStatusType(0);
				setRandevu({ ...randevu });
				setRandevu({
					...randevu,
					appointmentDate: new Date(startDate),
					appointmentEndDate: new Date(endDate),
					status: 0,
				});
			}
			!vacation && (await getFreeHourList(doctorId, dayValue, isOffline, roomId, past));
		};

		const onChangeRandevuTextInput = (event) => {
			setRandevu({ ...randevu, description: event.target.value });
		};

		const handleChange = (event) => {
			const {
				target: { value },
			} = event;
			setSelectedHourArray(
				// On autofill we get a stringified value.
				typeof value === "string" ? value.split(",") : value
			);
		};

		const insertPatientPkg = async () => {
			let resInsertPatPatPro = await insertPatientPromo(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id,
				selectedPatient.patientId,
				selectedPromoId
			);

			if (resInsertPatPatPro !== null && resInsertPatPatPro !== undefined) {
				if (resInsertPatPatPro.status === 200) {
					props.store.setMessage("Paket başarıyla tanımlandı.", true, "success");
					handleClosePkgOlusturMenu();
				} else if (resInsertPatPatPro.message) {
					props.store.setMessage(resInsertPatPatPro.message, true, "error");
				} else {
					props.store.setMessage("Paket tanımlanırken hata oluştu", true, "error");
				}
			} else {
				props.store.setMessage("Paket tanımlanırken hata oluştu", true, "error");
			}
		};

		const addEvent = async () => {
			props.store.setLoadingBarStatus(false);

			let randevuObjectOkStatus = false;
			let errorMessage = "";
			let randevuObject = toJS(randevu);

			if (selectedHourArray.length > 0) {
				let selectedStartHour = selectedHourArray[0].split(":")[0];
				let selectedStartMinutes = selectedHourArray[0].split(":")[1];
				let selectedEndHour = selectedHourArray[selectedHourArray.length - 1].split(":")[0];
				let selectedEndMinutes =
					selectedHourArray[selectedHourArray.length - 1].split(":")[1];
				if (randevuObject.appointmentType !== 0) {
					randevuObject.appointmentDate.setHours(selectedStartHour);
					randevuObject.appointmentDate.setMinutes(selectedStartMinutes);

					randevuObject.appointmentEndDate = new Date();

					randevuObject.appointmentEndDate.setHours(selectedEndHour);
					randevuObject.appointmentEndDate.setMinutes(
						Number(selectedEndMinutes) + appoTime
					);
					if (selectedPatient.id !== 0) {
						randevuObject.doctorId = props.store.doctor[0].id;
						randevuObject.patientId = selectedPatient.patientId;
						randevuObject.appointmentDate = dayjs(randevuObject.appointmentDate)
							.format()
							.split("+")[0];
						randevuObject.appointmentEndDate = dayjs(randevuObject.appointmentEndDate)
							.format()
							.split("+")[0];
						randevuObject.title = selectedPatient.name + " " + selectedPatient.surname;
						randevuObject.noSms = sendNoSMS;
						randevuObject.status = statusType;
						//delete randevuObject.appointmentId
						delete randevuObject.id;

						if (randevuObject.appointmentType !== 2) {
							delete randevuObject.roomId;
							delete randevuObject.clinicId;
							delete randevuObject.priceOffline;
							delete randevuObject.productsOffline;
						}

						delete randevuObject.appointmentId;
						delete randevuObject.patientBirthDate;
						delete randevuObject.patientGender;
						delete randevuObject.patientHeight;
						delete randevuObject.patientName;
						delete randevuObject.patientSurname;
						delete randevuObject.patientWeight;

						if (randevuObject.appointmentType === 2) {
							if (randevuObject.roomId !== 0) {
								randevuObjectOkStatus = true;
							}
						} else {
							randevuObjectOkStatus = true;
						}
					} else {
						errorMessage =
							"Hasta seçmediğiniz için randevu oluşturamazsınız. Lütfen hasta seçiniz.";
					}
					//console.log("randevu: ", randevu)
				} else {
					errorMessage =
						"Görüşme tipini seçmediğiniz için randevu oluşturamazsınız. Lütfen görüşme tipini seçiniz.";
				}
			} else {
				errorMessage =
					"Randevu saatini seçmediğiniz için randevu oluşturamazsınız. Lütfen randevu saatini seçiniz.";
			}

			if (randevuObjectOkStatus) {
				let createRandevuResponse = await postAppointment(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					randevuObject
				);

				if (createRandevuResponse !== null && createRandevuResponse !== undefined) {
					if (createRandevuResponse.status === 200) {
						props.store.setMessage("Randevu başarıyla oluşturuldu.", true, "success");
						await getAppointmentListReq();
						setRandevu(Appointment.create({}));
						setSelectedHourArray([]);
						setRandevuDialogMenuDisplay(false);
					} else {
						props.store.setMessage("Randevu oluşturulurken hata oluştu", true, "error");
					}
				} else {
					props.store.setMessage("Randevu oluşturulurken hata oluştu", true, "error");
				}
			} else {
				props.store.setMessage(errorMessage, true, "error");
			}
			props.store.setLoadingBarStatus(true);
		};

		const getAppointmentListReq = async () => {
			let appointmentListResponse = await getAppointmentList(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id
			);
			if (appointmentListResponse !== undefined && appointmentListResponse !== null) {
				if (appointmentListResponse.status === 200) {
					//console.log("app", appointmentListResponse.data)
					props.store.setRandevuListesi(appointmentListResponse.data);
				}
			}
		};

		const onClinicCloseAddPatientMenu = () => {
			setOfflinePatient(OfflinePatient.create({}));
			setAddPatientMenuDisplay(false);
		};

		const onClinicOpenAddPatientMenu = () => {
			setAddPatientMenuDisplay(true);
		};

		const handleUpdateMenu = () => {
			if (updatePatientMenuDisplay) setOfflinePatient(OfflinePatient.create({}));
			setUpdatePatientMenuDisplay((display) => !display);
		};

		const onChangePatientTextField = (event) => {
			setOfflinePatient({ ...offlinePatient, [event.target.name]: event.target.value });
		};

		const onChangePatientNumberTextField = (event) => {
			if (Number(event.target.value) >= 0 && Number(event.target.value) < 10000000000000000)
				setOfflinePatient({
					...offlinePatient,
					[event.target.name]: Number(event.target.value),
				});
		};

		const onChangePatientGender = (event) => {
			setOfflinePatient({ ...offlinePatient, gender: Number(event.target.value) });
		};

		const onChangePatientBloodGroup = (event) => {
			setOfflinePatient({ ...offlinePatient, bloodGroup: event.target.value });
		};

		const onChangeBirthDateCalendarValue = (event) => {
			setOfflinePatient({ ...offlinePatient, birthDate: event ? new Date(event) : event });
		};

		const onClickCreateHasta = async () => {
			props.store.setLoadingBarStatus(false);
			let createOfflinePatientResponse = await createOfflinePatient(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id,
				offlinePatient
			);
			if (
				createOfflinePatientResponse !== null &&
				createOfflinePatientResponse !== undefined
			) {
				if (createOfflinePatientResponse.status === 200) {
					props.store.setMessage(
						"Hasta kaydı başarılı bir şekilde gerçekleştirildi.",
						true,
						"success"
					);
					let hastaListesiResponse = await patientsGetForDoctor(
						props.store.baseUrl,
						props.store.token,
						props.store.doctor[0].id
					);
					//console.log("hastaListesiResponse: ", hastaListesiResponse)
					if (hastaListesiResponse !== undefined && hastaListesiResponse !== null) {
						if (hastaListesiResponse.status === 200) {
							setHastaListesi(hastaListesiResponse.data);
						} else if (hastaListesiResponse.status === 401) {
							this.props.store.setUserLogout();
							window.location.reload();
						}
					} else {
						this.props.store.setMessage(
							"Hasta listesi getirilirken hata oluştu.",
							true,
							"error"
						);
					}

					setAddPatientMenuDisplay(false);
				} else {
					props.store.setMessage(
						createOfflinePatientResponse.result.message,
						true,
						"error"
					);
				}
			} else {
				props.store.setMessage("Hasta kaydı kaydedilirken hata oluştu.", true, "error");
			}
			props.store.setLoadingBarStatus(true);
		};

		const onClickUpdatePatient = async () => {
			props.store.setLoadingBarStatus(false);
			let updateOfflinePatientResponse = await updateOfflinePatient(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id,
				offlinePatient
			);
			if (
				updateOfflinePatientResponse !== null &&
				updateOfflinePatientResponse !== undefined
			) {
				if (updateOfflinePatientResponse?.result?.msg) {
					handleUpdateMenu();
					props.store.setLoadingBarStatus(true);
					return props.store.setMessage(
						updateOfflinePatientResponse.result.msg,
						true,
						"warning"
					);
				}
				if (updateOfflinePatientResponse.status === 200) {
					props.store.setMessage(
						"Hasta bilgileri başarılı bir şekilde güncelleştirildi.",
						true,
						"success"
					);
					let hastaListesiResponse = await patientsGetForDoctor(
						props.store.baseUrl,
						props.store.token,
						props.store.doctor[0].id
					);
					if (hastaListesiResponse !== undefined && hastaListesiResponse !== null) {
						if (hastaListesiResponse.status === 200) {
							setHastaListesi(hastaListesiResponse.data);
						} else if (hastaListesiResponse.status === 401) {
							this.props.store.setUserLogout();
							window.location.reload();
						}
						handleUpdateMenu();
					} else {
						this.props.store.setMessage(
							"Hasta listesi getirilirken hata oluştu.",
							true,
							"error"
						);
					}
				} else {
					props.store.setMessage(
						updateOfflinePatientResponse.result.message,
						true,
						"error"
					);
				}
			} else {
				props.store.setMessage(
					"Hasta bilgileri güncelleştirilirken hata oluştu.",
					true,
					"error"
				);
			}
			props.store.setLoadingBarStatus(true);
		};

		return (
			<>
				<Grid container spacing={3}>
					<Grid item xs={9}>
						<Typography color="secondary" variant="h5" style={{ fontWeight: "bold" }}>
							Hasta Listesi
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<Button
							color="primary"
							variant="contained"
							style={{ height: "55px", float: "right" }}
							onClick={onClinicOpenAddPatientMenu}
						>
							<AddIcon /> Hasta Ekle
						</Button>
					</Grid>
				</Grid>
				<Card style={{ marginTop: "8px", height: "calc(100vh - 200px)" }}>
					<CardContent style={{ height: "calc(100vh - 222px)", padding: "30px" }}>
						<TextField
							id="outlined-basic"
							variant="outlined"
							label="Arama Kutusu (T.C. Kimlik veya Hasta Adı)"
							focused
							placeholder="Hasta ara"
							value={searchText}
							style={{ width: "50%", background: "#fff", borderRadius: "10px" }}
							onChange={onChangeSearchTextField}
							InputProps={{
								endAdornment: (
									<Tooltip title="Temizle">
										<IconButton onClick={onClickDeleteSearchText}>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								),
							}}
						/>
						<Button
							variant="contained"
							color="primary"
							style={{ height: "55px", marginLeft: "10px" }}
							onClick={onClickHastaSearch}
						>
							Hasta Ara
						</Button>

						{!!hastaListesi.find((hasta) => !hasta.isActive) && (
							<FormControlLabel
								control={
									<Switch
										checked={showAll}
										onChange={() => setShowAll(!showAll)}
									/>
								}
								label="Sadece aktif hastaları göster"
								style={{
									marginInlineStart: "16px",
									backgroundColor: "#cbcbcbcb",
									borderRadius: "11px",
									padding: "4px",
									paddingInlineEnd: "15px",
									height: "55px",
								}}
							/>
						)}

						<DataGrid
							rows={
								hastaListesi !== null
									? hastaListesi.filter((hasta) => !showAll || hasta.isActive)
									: []
							}
							columns={columns}
							pageSize={10}
							disableSelectionOnClick
							localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
							color="primary"
							style={{ border: "0" }}
						/>
					</CardContent>
				</Card>

				<Dialog open={randevuDialogMenuDisplay} onClose={handleCloseRandevuOlusturMenu}>
					<DialogTitle>Yeni Randevu Oluştur</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Bu menüden kayıtlı hastalarınız için ileri tarihli online randevu veya
							klinik tanımlaması yapılmış ise yüzyüze randevu oluşturabilirsiniz.
						</DialogContentText>

						<Grid container spacing={2} style={{ marginTop: "15px", width: "100%" }}>
							<Grid item xs={1}>
								<CameraswitchIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<FormControl fullWidth style={{ marginTop: "15px" }}>
									<InputLabel id="demo-simple-select-label">
										Görüşme Tipi
									</InputLabel>
									<Select
										id="demo-simple-select"
										labelId="demo-simple-select-label"
										value={randevu.appointmentType}
										label="Görüşme Tipi"
										onChange={handleChangeGorusmeTipi}
										variant="outlined"
									>
										<MenuItem value={1}>Online</MenuItem>
										<MenuItem value={2}>Yüzyüze</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={1}>
								<MeetingRoomIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<Accordion
									expanded={offlineRandevuAccordionDisplay}
									style={{ background: "#fcfcfc" }}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1bh-content"
										id="panel1bh-header"
									>
										<Typography sx={{ width: "33%", flexShrink: 0 }}>
											Yüzyüze Görüşme Ayarları
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<FormControl fullWidth style={{ marginTop: "0px" }}>
													<InputLabel id="demo-simple-select-label">
														Doktor Seçiniz
													</InputLabel>
													<Select
														id="demo-simple-select"
														labelId="demo-simple-select-label"
														value={randevu.doctorId}
														label="Doktor Seçiniz"
														onChange={handleChangeDoctorSelectMenu}
														variant="filled"
														disabled={!showRoomListMenu}
													>
														{clinicDoctorList
															.filter(
																(doc) =>
																	doc.userType === 1 ||
																	doc.userType === 3
															)
															.map((doctor, idx) => (
																<MenuItem
																	key={idx}
																	value={doctor.doctorId}
																>
																	{" "}
																	{(doctor.userType === 1
																		? "Doktor "
																		: "Hemşire ") +
																		doctor.name +
																		" " +
																		doctor.surname}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={12}>
												<FormControl
													fullWidth
													style={{ marginTop: "15px" }}
												>
													<InputLabel id="demo-simple-select-label">
														Oda Seçiniz
													</InputLabel>
													<Select
														id="demo-simple-select"
														labelId="demo-simple-select-label"
														value={randevu.roomId}
														label="Oda Seçiniz"
														onChange={handleChangeAppointmentRoom}
														variant="filled"
														disabled={!showRoomListMenu}
													>
														{clinicRoomList.map((room, idx) => (
															<MenuItem key={idx} value={room.roomId}>
																{room.roomName}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={10}>
												<FormControl
													fullWidth
													style={{ marginTop: "15px" }}
												>
													<InputLabel id="demo-simple-select-label">
														Hizmet Seçiniz
													</InputLabel>
													<Select
														id="demo-simple-select"
														labelId="demo-simple-select-label"
														value={selectedHizmetId}
														label="Hizmet Seçiniz"
														onChange={handleChangeAppointmentHizmet}
														variant="filled"
														disabled={!showRoomListMenu}
													>
														{hizmetList.map((hizmet, idx) => (
															<MenuItem
																key={idx}
																value={hizmet.productId}
															>
																{hizmet.productName}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={2}>
												<IconButton
													color="primary"
													variant="contained"
													style={{ marginTop: "20px", float: "right" }}
													disabled={!showRoomListMenu}
													onClick={onClickHizmetEkle}
												>
													<AddIcon />
												</IconButton>
											</Grid>
											<Grid item xs={12}>
												<TableContainer>
													<Table style={{ width: "100%" }} size="small">
														<TableHead>
															<TableRow>
																<TableCell>
																	SEÇİLEN HİZMET ADI
																</TableCell>
																<TableCell>ÜCRETİ</TableCell>
																<TableCell>İŞLEMLER</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{selectedHizmetList.map(
																(hizmet, idx) => (
																	<TableRow key={idx}>
																		<TableCell>
																			<Typography variant="body2">
																				{hizmet.productName}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography
																				key={idx}
																				variant="body2"
																			>
																				{hizmet.price +
																					" TL"}
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<IconButton
																				color="error"
																				onClick={() =>
																					onClickRemoveHizmet(
																						hizmet.productId
																					)
																				}
																			>
																				<DeleteIcon />
																			</IconButton>
																		</TableCell>
																	</TableRow>
																)
															)}
														</TableBody>
													</Table>
												</TableContainer>
												<Typography
													variant="body2"
													style={{ marginTop: "15px" }}
												>
													<b>Toplam Hizmet Bedeli:</b>{" "}
													{selectedHizmetToplamFiyat} TL
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<FormControl
													fullWidth
													style={{ marginTop: "15px" }}
												>
													<TextField
														name="offline"
														label="Toplam Hizmet Ücreti (TL)"
														value={randevu.priceOffline}
														variant="filled"
														onChange={onChangeOfflinePriceTextField}
														disabled={!showRoomListMenu}
													/>
												</FormControl>
											</Grid>
											<Grid item xs={12}>
												<FormControl
													fullWidth
													style={{ marginTop: "15px" }}
												>
													<TextField
														name="examination"
														label="Yüzyüze Muayene Ücreti (TL)"
														value={randevu.examinationPrice}
														variant="filled"
														onChange={onChangeOfflinePriceTextField}
														disabled={!showRoomListMenu}
													/>
												</FormControl>
											</Grid>
											<Grid item xs={12}>
												<FormControl
													fullWidth
													style={{ marginTop: "15px" }}
												>
													<TextField
														label="Genel Toplam Ücreti (TL)"
														value={offlineRandevuGenelToplam}
														variant="filled"
														disabled={true}
													/>
												</FormControl>
											</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
							<Grid item xs={1}>
								<SmsIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<FormControlLabel
									control={
										<Switch
											checked={sendNoSMS}
											onChange={() => setSendNoSMS(!sendNoSMS)}
										/>
									}
									label="Randevuyu SMS olarak gönderme"
									style={{ marginTop: "8px" }}
								/>
							</Grid>
							<Grid item xs={1}>
								<QueryBuilderIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<FormControl fullWidth style={{ marginTop: "15px" }}>
											<LocalizationProvider
												dateAdapter={AdapterDayjs}
												adapterLocale={"tr"}
												style={{ width: "calc(100% - 100px)" }}
											>
												<DatePicker
													label="Randevu tarihi seçiniz"
													value={randevu.appointmentDate}
													onChange={(newValue) =>
														onChangeCalendarValue(newValue)
													}
													renderInput={(params) => (
														<TextField {...params} />
													)}
													resizable
												/>
											</LocalizationProvider>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">
												Müsait Saatler:
											</InputLabel>
											<Select
												multiple
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={selectedHourArray}
												label="Müsait Saatler:"
												onChange={handleChange}
											>
												{freeHoursForSelectedDay.map((freeHour, idx) => (
													<MenuItem key={idx} value={freeHour}>
														{freeHour}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl fullWidth>
											<TextField
												labelId="appo-time-label"
												label="Randevu Süresi (dk)"
												value={appoTime}
												onChange={(e) => {
													setAppoTime(e.target.value);
												}}
												type="number"
												required
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<InputLabel id="appointment-status">
												Randevu durumunu seçiniz
											</InputLabel>
											<Select
												labelId="appointment-status"
												id="appointment-status-select"
												value={statusType}
												label="Randevu durumunu seçiniz"
												onChange={(event) => {
													setStatusType(event.target.value);
												}}
											>
												<MenuItem value={0}>Belirtilmedi</MenuItem>
												<MenuItem value={3}>Geldi</MenuItem>
												<MenuItem value={7}>Gelmedi</MenuItem>
												<MenuItem value={6}>İptal Edildi</MenuItem>
												<MenuItem value={9}>Ödeme Bekleniyor</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={1}>
								<NotesIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<FormControl fullWidth style={{ marginTop: "15px" }}>
									<TextField
										label="Randevu Açıklama"
										value={randevu.description}
										multiline
										onChange={onChangeRandevuTextInput}
										rows={3}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseRandevuOlusturMenu}>Kapat</Button>
						<Button onClick={addEvent} variant="contained">
							Oluştur
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={pkgDialogMenuDisplay} onClose={handleClosePkgOlusturMenu}>
					<DialogTitle>Kullanıcıya Paket Tanımla</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Kayıtlı hastalarınıza paket atamak için bu menüyü kullanabilirsiniz.
						</DialogContentText>
						<DialogContentText style={{ fontWeight: "bold", color: "#2d2d2d" }}>
							Paket tanımlandığında hastadan ücret talep edilmeyecektir.
						</DialogContentText>
						<Grid container spacing={2} style={{ marginTop: "15px", width: "100%" }}>
							<Grid item xs={1}>
								<InventoryIcon style={{ marginTop: "30px" }} />
							</Grid>
							<Grid item xs={11}>
								<FormControl fullWidth style={{ marginTop: "15px" }}>
									<InputLabel id="demo-simple-select-label">
										Paket Seçiniz
									</InputLabel>
									<Select
										id="demo-simple-select"
										labelId="demo-simple-select-label"
										value={selectedPromoId}
										label="Görüşme Tipi"
										onChange={handlePromoId}
										variant="outlined"
									>
										{promoList.length > 0
											? promoList.map((promo, idx) => (
													<MenuItem key={idx} value={promo.promoId}>
														{`${promo.count} randevu - %${
															promo.discount
														} indirim - ${promo.priceAfterDiscount} TL${
															promo.description
																? " - " + promo.description
																: ""
														}`}
													</MenuItem>
											  ))
											: null}
									</Select>
								</FormControl>
							</Grid>
							{selectedPromoId && (
								<Grid item xs={12}>
									<PromoCard
										promo={promoList.find(
											(promo) => promo.promoId === selectedPromoId
										)}
										btnText={
											selectedPatient.name + " " + selectedPatient.surname
										}
									/>
								</Grid>
							)}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClosePkgOlusturMenu}>Kapat</Button>
						<Button onClick={insertPatientPkg} variant="contained">
							Tanımla
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					fullWidth={true}
					maxWidth={"sm"}
					open={addPatientMenuDisplay}
					onClose={onClinicCloseAddPatientMenu}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{"Hasta Ekleme Menüsü"}</DialogTitle>
					<DialogContent style={{ padding: "30px", paddingTop: "10px" }}>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<TextField
									name="name"
									error={offlinePatient.name.length === 0}
									value={offlinePatient.name}
									variant="outlined"
									label="Hasta Adı"
									onChange={onChangePatientTextField}
									style={{ width: "100%" }}
								></TextField>
								<TextField
									name="surname"
									error={offlinePatient.surname.length === 0}
									value={offlinePatient.surname}
									variant="outlined"
									label="Hasta Soyadı"
									onChange={onChangePatientTextField}
									style={{ width: "100%", marginTop: "15px" }}
								></TextField>
								<TextField
									name="tckno"
									value={offlinePatient.tckno}
									variant="outlined"
									label="Hasta T.C. Kimlik Numarası"
									onChange={onChangePatientNumberTextField}
									style={{ width: "100%", marginTop: "15px" }}
								></TextField>
								<TextField
									name="email"
									value={offlinePatient.email}
									variant="outlined"
									label="Hasta e-mail adresi"
									onChange={onChangePatientTextField}
									style={{ width: "100%", marginTop: "15px" }}
								></TextField>
								<TextField
									name="mobilePhone"
									value={offlinePatient.mobilePhone}
									variant="outlined"
									label="Hasta Telefon Numarası"
									onChange={onChangePatientNumberTextField}
									style={{ width: "100%", marginTop: "15px" }}
								></TextField>
							</Grid>
							<Grid item xs={6}>
								<FormControl>
									<FormLabel id="demo-row-radio-buttons-group-label">
										Cinsiyet
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="row-radio-buttons-group"
										value={offlinePatient.gender}
										onChange={onChangePatientGender}
									>
										<FormControlLabel
											value={2}
											control={<Radio />}
											label="Kadın"
										/>
										<FormControlLabel
											value={1}
											control={<Radio />}
											label="Erkek"
										/>
									</RadioGroup>
								</FormControl>
								<TextField
									name="weight"
									value={offlinePatient.weight}
									variant="outlined"
									label="Hasta Kilo (kg)"
									onChange={onChangePatientNumberTextField}
									style={{ width: "100%", marginTop: "5px" }}
								></TextField>

								<TextField
									name="height"
									value={offlinePatient.height}
									variant="outlined"
									label="Hasta Boyu (cm)"
									onChange={onChangePatientNumberTextField}
									style={{ width: "100%", marginTop: "15px" }}
								></TextField>

								<FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
									<InputLabel id="demo-simple-select-label">Kan Grubu</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={offlinePatient.bloodGroup}
										label="Kan Grubu"
										onChange={onChangePatientBloodGroup}
									>
										<MenuItem value={0}>Bilmiyorum</MenuItem>
										<MenuItem value={1}>A RH +</MenuItem>
										<MenuItem value={2}>A RH -</MenuItem>
										<MenuItem value={3}>B RH +</MenuItem>
										<MenuItem value={4}>B RH -</MenuItem>
										<MenuItem value={5}>AB RH +</MenuItem>
										<MenuItem value={6}>AB RH -</MenuItem>
										<MenuItem value={7}>0 RH +</MenuItem>
										<MenuItem value={8}>0 RH -</MenuItem>
									</Select>
								</FormControl>
								<FormControl fullWidth style={{ marginTop: "15px" }}>
									<LocalizationProvider
										dateAdapter={AdapterDayjs}
										adapterLocale={"tr"}
										style={{ width: "calc(100% - 100px)" }}
									>
										<DatePicker
											label="Doğum Tarihi"
											disableFuture
											value={offlinePatient.birthDate}
											onChange={(newValue) =>
												onChangeBirthDateCalendarValue(newValue)
											}
											renderInput={(params) => <TextField {...params} />}
											resizable
										/>
									</LocalizationProvider>
								</FormControl>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClinicCloseAddPatientMenu}>Kapat</Button>
						<Button color="primary" variant="contained" onClick={onClickCreateHasta}>
							Ekle
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					fullWidth={true}
					maxWidth={"sm"}
					open={updatePatientMenuDisplay}
					onClose={handleUpdateMenu}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{"Hasta Güncelleme Menüsü"}</DialogTitle>
					<DialogContent style={{ padding: "30px", paddingTop: "10px" }}>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<TextField
									name="name"
									error={offlinePatient.name.length === 0}
									value={offlinePatient.name}
									variant="outlined"
									label="Hasta Adı"
									onChange={onChangePatientTextField}
									style={{ width: "100%" }}
								></TextField>
								<TextField
									name="surname"
									error={offlinePatient.surname.length === 0}
									value={offlinePatient.surname}
									variant="outlined"
									label="Hasta Soyadı"
									onChange={onChangePatientTextField}
									style={{ width: "100%", marginTop: "15px" }}
								></TextField>
								<TextField
									name="tckno"
									value={offlinePatient.tckno}
									variant="outlined"
									label="Hasta T.C. Kimlik Numarası"
									onChange={onChangePatientNumberTextField}
									style={{ width: "100%", marginTop: "15px" }}
								></TextField>
								<TextField
									name="email"
									value={offlinePatient.email}
									variant="outlined"
									label="Hasta e-mail adresi"
									onChange={onChangePatientTextField}
									style={{ width: "100%", marginTop: "15px" }}
								></TextField>
							</Grid>
							<Grid item xs={6}>
								<FormControl>
									<FormLabel id="demo-row-radio-buttons-group-label">
										Cinsiyet
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="row-radio-buttons-group"
										value={offlinePatient.gender}
										onChange={onChangePatientGender}
									>
										<FormControlLabel
											value={2}
											control={<Radio />}
											label="Kadın"
										/>
										<FormControlLabel
											value={1}
											control={<Radio />}
											label="Erkek"
										/>
									</RadioGroup>
								</FormControl>
								<FormControl fullWidth style={{ width: "100%", marginTop: "8px" }}>
									<InputLabel id="demo-simple-select-label">Kan Grubu</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={offlinePatient.bloodGroup}
										label="Kan Grubu"
										onChange={onChangePatientBloodGroup}
									>
										<MenuItem value={0}>Bilmiyorum</MenuItem>
										<MenuItem value={1}>A RH +</MenuItem>
										<MenuItem value={2}>A RH -</MenuItem>
										<MenuItem value={3}>B RH +</MenuItem>
										<MenuItem value={4}>B RH -</MenuItem>
										<MenuItem value={5}>AB RH +</MenuItem>
										<MenuItem value={6}>AB RH -</MenuItem>
										<MenuItem value={7}>0 RH +</MenuItem>
										<MenuItem value={8}>0 RH -</MenuItem>
									</Select>
								</FormControl>
								<FormControl fullWidth style={{ marginTop: "15px" }}>
									<LocalizationProvider
										dateAdapter={AdapterDayjs}
										adapterLocale={"tr"}
										style={{ width: "calc(100% - 100px)" }}
									>
										<DatePicker
											label="Doğum Tarihi"
											disableFuture
											value={offlinePatient.birthDate}
											onChange={(newValue) =>
												onChangeBirthDateCalendarValue(newValue)
											}
											renderInput={(params) => <TextField {...params} />}
											resizable
										/>
									</LocalizationProvider>
								</FormControl>
								<TextField
									name="mobilePhone"
									value={offlinePatient.mobilePhone}
									variant="outlined"
									label="Hasta Telefon Numarası"
									onChange={onChangePatientNumberTextField}
									style={{ width: "100%", marginTop: "15px" }}
								></TextField>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleUpdateMenu}>Kapat</Button>
						<Button
							color="error"
							onClick={() => handleStatusPatient(offlinePatient, true)}
						>
							Pasife al
						</Button>
						<Button color="primary" variant="contained" onClick={onClickUpdatePatient}>
							Güncelle
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	})
);
