import axios from "axios";

const getMahalleListesi = async (baseUrl, token, districtId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + `/Common/city/district/neighbourhood/` + districtId, config)
        .then((res) => {
            getResponse = {
                status: res.status,
                result: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    result:  error.response.status
                }
            }
        });
    return getResponse
}

export default getMahalleListesi;