import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
	CardHeader,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	FormControlLabel,
	Switch,
} from "@mui/material";

import "dayjs/locale/tr";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-big-calendar/lib/css/react-big-calendar.css";

import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import isBefore from "date-fns/isBefore";

import { tr } from "date-fns/locale";

import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import NotesIcon from "@mui/icons-material/Notes";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MoneyIcon from "@mui/icons-material/Money";
import { HiVideoCamera } from "react-icons/hi";
import { FaUserInjured } from "react-icons/fa";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CloseIcon from "@mui/icons-material/Close";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AddIcon from "@mui/icons-material/Add";
import SmsIcon from "@mui/icons-material/Sms";

import dayjs from "dayjs";

import { Appointment } from "../../../model/Appointment";
import { Patient } from "../../../model/Patient";

import getDoctorPatientInfo from "../../../utils/api/Doctor/GetDoctorPatientInfo";
import postAppointment from "../../../utils/api/Doctor/Appointment/PostAppointment";
import putAppointment from "../../../utils/api/Doctor/Appointment/PutAppointment";
import getAppointmentHoursByDayId from "../../../utils/api/Doctor/Appointment/GetAppointmentHoursByDayId";
import getAppointmentHoursByDayIdAndRoomId from "../../../utils/api/Doctor/Appointment/GetAppointmentHoursByDayIdAndRoomId";
import getAppointmentList from "../../../utils/api/Doctor/Appointment/GetAppointmentList";
import getClinic from "../../../utils/api/Doctor/Clinic/GetClinic";
import getClinicRoom from "../../../utils/api/Doctor/Clinic/GetClinicRoom";
import getClinicProduct from "../../../utils/api/Doctor/Clinic/GetClinicProduct";
import getClinicEmployee from "../../../utils/api/Doctor/Clinic/GetClinicEmployee";
import getDoctorSettings from "../../../utils/api/Doctor/GetDoctorSettings";
import postDoctorPatient from "../../../utils/api/Doctor/PostDoctorPatient";
import findPatient from "../../../utils/api/Doctor/FindPatient";
import getDoctorCouponsList from "../../../utils/api/Doctor/Coupon/GetDoctorCouponsList";

import "./YearCalendar.css";
//import { HastaEkleMenu } from '../hasta/HastaEkleMenu';
import { DoctorAllSettings } from "../../../model/Doctor/DoctorAllSettings";

const locales = {
	"tr-Tr": tr,
};

const localizer = dateFnsLocalizer({
	format,
	parse,
	startOfWeek,
	getDay,
	locales,
});

localizer.formats.yearHeaderFormat = "yyyy";

export const TakvimMenu = inject("store")(
	observer((props) => {
		const [randevuDialogMenuDisplay, setRandevuDialogMenuDisplay] = useState(false);
		const [randevu, setRandevu] = useState(Appointment.create({}));

		const [randevuInfoDialogMenuDisplay, setRandevuInfoDialogMenuDisplay] = useState(false);
		const [selectedRandevu, setSelectedRandevu] = useState(Appointment.create({}));
		const [selectedRandevuPassed, setSelectedRandevuPassed] = useState(false);

		const [patientList, setPatientList] = useState([]);
		const [selectedPatient, setSelectedPatient] = useState(Patient.create({}));
		//const [value, setValue] = React.useState("");

		const [events, setEvents] = useState([]);
		const [sendNoSMS, setSendNoSMS] = useState(false);

		const [freeHoursForSelectedDay, setFreeHoursForSelectedDay] = useState([]);
		const [selectedHourArray, setSelectedHourArray] = useState([]);
		const [appoTime, setAppoTime] = useState(30);
		const [statusType, setStatusType] = useState(0);

		const [clinicRoomList, setClinicRoomList] = useState([]);
		const [showRoomListMenu, setShowRoomListMenu] = useState(false);
		const [hizmetList, setHizmetList] = useState([]);
		const [selectedHizmetId, setSelectedHizmetId] = useState(0);
		const [selectedHizmetList, setSelectedHizmetList] = useState([]);
		const [selectedHizmetToplamFiyat, setSelectedHizmetToplamFiyat] = useState(0);

		const [clinicDoctorList, setClinicDoctorList] = useState([]);
		const [selectedDoctorId, setSelectedDoctorId] = useState(0);

		const [doctorSettings, setDoctorSettings] = useState(DoctorAllSettings.create({}));
		const [offlineRandevuAccordionDisplay, setOfflineRandevuAccordionDisplay] = useState(false);

		const [appointmentList, setAppointmentList] = useState([]);
		const [backupAppointmentList, setBackupAppointmentList] = useState([]);
		const [couponList, setCouponList] = useState([]);
		const [selectedCoupon, setSelectedCoupon] = useState("");
		const [filterOptions, setFilterOptions] = useState([
			{ roomId: -1, roomName: "Online Randevular" },
			{ roomId: -2, roomName: "Yüzyüze Randevular" },
		]);
		const [selectedFilter, setSelectedFilter] = useState(0);
		const [offlineRandevuGenelToplam, setOfflineRandevuGenelToplam] = useState(0);

		const [patientSearchForm, setPatientSearchForm] = useState(false);
		const [searchTC, setSearchTC] = useState("");
		const [foundPatient, setFoundPatient] = useState(null);

		useEffect(() => {
			// Update the document title using the browser API
			//console.log("props.data: ", props.data)

			//console.log("props.store.randevuListesi: ", toJS(props.store.randevuListesi))

			if (props.store.randevuListesi.length > 0) {
				setAppointmentList(toJS(props.store.randevuListesi));
				setBackupAppointmentList(toJS(props.store.randevuListesi));
				let eventListesi = createEventList(props.store.randevuListesi);
				//props.store.randevuListesi
				setEvents(eventListesi);
			}

			if (props.hastaData !== null) {
				//console.log("props.hasta data: ", props.hastaData)
				setPatientList(props.hastaData);
			}

			if (props.doktor !== null) {
				setRandevu({ ...randevu, doctorId: props.doktor });
			}

			/*let dayValue = (new Date().getFullYear())
            + "-" + (new Date().getMonth() + 1)
            + "-" + (new Date().getDate())*/

			//getFreeHourList(dayValue, false, 0)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.store.randevuListesi, props.hastaData]);

		useEffect(() => {
			getDoctorSettingsReq();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			setOfflineRandevuGenelToplam(
				Number(randevu.examinationPrice) + Number(randevu.priceOffline)
			);
		}, [randevu.examinationPrice, randevu.priceOffline]);

		//CheckIcon HourglassTopIcon MoneyIcon
		const createEventList = (appointmentList) => {
			let eventListesi = [];
			for (let index = 0; index < appointmentList.length; index++) {
				const tempAppointment = appointmentList[index];
				//console.log("tempAppointment: ", tempAppointment)
				let tempEvent = {
					start: tempAppointment.appointmentDate, // 10.00 AM
					end: tempAppointment.appointmentEndDate, // 2.00 PM
					title: isBefore(tempAppointment.appointmentDate, new Date()) ? (
						<>
							{" "}
							{tempAppointment.status === 0 ? (
								<HourglassTopIcon color="warning" style={{ fontSize: "18px" }} />
							) : tempAppointment.status === 1 ? (
								<HourglassTopIcon style={{ fontSize: "18px", color: "#ffeb3b" }} />
							) : tempAppointment.status === 2 ||
							  tempAppointment.status === 3 ||
							  tempAppointment.status === 4 ||
							  tempAppointment.status === 5 ? (
								<CheckIcon style={{ fontSize: "16px", color: "#52be80" }} />
							) : tempAppointment.status === 6 ? (
								<CloseIcon color="error" style={{ fontSize: "10px" }} />
							) : tempAppointment.status === 7 || tempAppointment.status === 8 ? (
								<CloseIcon color="error" style={{ fontSize: "16px" }} />
							) : tempAppointment.status === 9 ? (
								<MoneyIcon color="error" style={{ fontSize: "16px" }} />
							) : null}
							{tempAppointment.appointmentType === 0 ||
							tempAppointment.appointmentType === 1 ? (
								<VideoCameraFrontIcon
									color="primary"
									style={{ fontSize: "16px", marginRight: "5px" }}
								/>
							) : (
								<PersonOutlineIcon
									color="primary"
									style={{ fontSize: "16px", marginRight: "5px" }}
								/>
							)}
							<span style={{ color: "gray" }}>
								{dayjs(tempAppointment.appointmentDate).format("HH:mm")} -{" "}
								{tempAppointment.title}
							</span>
						</>
					) : (
						<>
							{tempAppointment.status === 0 ? (
								<HourglassTopIcon style={{ fontSize: "18px", color: "#ffeb3b" }} />
							) : tempAppointment.status === 1 ? (
								<HourglassTopIcon style={{ fontSize: "18px", color: "#ffeb3b" }} />
							) : tempAppointment.status === 2 ||
							  tempAppointment.status === 3 ||
							  tempAppointment.status === 4 ||
							  tempAppointment.status === 5 ? (
								<CheckIcon style={{ fontSize: "16px", color: "#52be80" }} />
							) : tempAppointment.status === 6 ? (
								<CloseIcon color="error" style={{ fontSize: "10px" }} />
							) : tempAppointment.status === 7 || tempAppointment.status === 8 ? (
								<CloseIcon color="error" style={{ fontSize: "16px" }} />
							) : tempAppointment.status === 9 ? (
								<MoneyIcon color="error" style={{ fontSize: "16px" }} />
							) : null}
							{tempAppointment.appointmentType === 0 ||
							tempAppointment.appointmentType === 1 ? (
								<VideoCameraFrontIcon
									color="primary"
									style={{ fontSize: "16px", marginRight: "5px" }}
								/>
							) : (
								<PersonOutlineIcon
									color="primary"
									style={{ fontSize: "16px", marginRight: "5px" }}
								/>
							)}
							<span style={{ color: "black" }}>
								{dayjs(tempAppointment.appointmentDate).format("HH:mm")} -{" "}
								{tempAppointment.title}
							</span>
						</>
					),
					hexColor: "#fff",
					id: index,
					allDay: false,
					randevu: toJS(tempAppointment),
				};
				eventListesi.push(tempEvent);
			}
			return eventListesi;
		};

		const getDoctorSettingsReq = async () => {
			props.store.setLoadingBarStatus(false);

			let doctorSettings = null;
			let doctorSettingsResponse = await getDoctorSettings(
				props.store.baseUrl,
				props.store.token,
				props.store.user[0].id
			);
			let getDoctorCouponList = await getDoctorCouponsList(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id
			);
			if (getDoctorCouponList !== null && getDoctorCouponList !== undefined) {
				if (getDoctorCouponList.status === 200) {
					let couponListData = getDoctorCouponList.data;

					if (couponListData !== null) {
						for (let index = 0; index < couponListData.length; index++) {
							const coupon = couponListData[index];
							coupon.id = index + 1;
						}
						setCouponList(couponListData.sort((a, b) => a.createdDate - b.createdDate));
					}
				}
			}
			if (doctorSettingsResponse !== undefined && doctorSettingsResponse !== null) {
				if (doctorSettingsResponse.status === 200) {
					setDoctorSettings(doctorSettingsResponse.data.data);

					doctorSettings = doctorSettingsResponse.data.data;

					getClinicRoomHizmetListReq(doctorSettings);

				}
			} else {
				props.store.setMessage("Hasta bilgisi getirilirken hata oluştu.", true, "error");
			}
			props.store.setLoadingBarStatus(true);
		};

		const getAppointmentListReq = async () => {
			let appointmentListResponse = await getAppointmentList(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id
			);
			if (appointmentListResponse !== undefined && appointmentListResponse !== null) {
				if (appointmentListResponse.status === 200) {
					props.store.setRandevuListesi(appointmentListResponse.data);
					const eventListesi = createEventList(appointmentListResponse.data);
					setEvents(eventListesi);
				}
			}
		};

		const getKlinikBilgisiReq = async () => {
			let clinicId = null;
			let getClinicResponse = await getClinic(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id
			);

			if (getClinicResponse !== null && getClinicResponse !== undefined) {
				if (getClinicResponse.status === 200) {
					if (getClinicResponse.data !== null) {
						clinicId = getClinicResponse.data.clinicId;
					}
				}
			}

			return clinicId;
		};

		const getClinicRoomHizmetListReq = async (doctorSettings) => {
			let clinicId = null;
			let getClinicResponse = await getClinic(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id
			);

			if (getClinicResponse !== null && getClinicResponse !== undefined) {
				if (getClinicResponse.status === 200) {
					if (getClinicResponse.data !== null) {
						clinicId = getClinicResponse.data.clinicId;
						setRandevu({
							...randevu,
							clinicId: clinicId,
							examinationPrice: doctorSettings.doctorPrice.offlineExaminationPrice,
						});
					}
				}
			}

			if (clinicId !== null) {
				let clinicRoomListResponse = await getClinicRoom(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					clinicId
				);

				//console.log("clinicRoomListResponse: ", clinicRoomListResponse)
				if (clinicRoomListResponse !== null && clinicRoomListResponse !== undefined) {
					if (clinicRoomListResponse.status === 200) {
						setClinicRoomList(clinicRoomListResponse.data);

						let clinicRoomList = clinicRoomListResponse.data;
						for (let index = 0; index < clinicRoomList.length; index++) {
							const clinicRoomTemp = clinicRoomList[index];
							clinicRoomTemp.roomName = "Oda: " + clinicRoomTemp.roomName;
						}

						setFilterOptions(filterOptions.concat(clinicRoomList));
					}
				}

				let clinicHizmetResponse = await getClinicProduct(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					clinicId
				);

				//console.log("clinicHizmetResponse: ", clinicHizmetResponse)
				if (clinicHizmetResponse !== null && clinicHizmetResponse !== undefined) {
					if (clinicHizmetResponse.status === 200) {
						if (clinicHizmetResponse.data !== null) {
							setHizmetList(clinicHizmetResponse.data);
						} else {
							setHizmetList([]);
						}
					}
				}

				let clinicEmployeeResponse = await getClinicEmployee(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					clinicId
				);

				//console.log("clinicEmployeeResponse: ", clinicEmployeeResponse)
				if (clinicEmployeeResponse !== null && clinicEmployeeResponse !== undefined) {
					if (clinicEmployeeResponse.status === 200) {
						let tempEmployeeList = clinicEmployeeResponse.data;

						if (clinicEmployeeResponse.data !== null) {
							let doctorList = [];
							for (let index = 0; index < tempEmployeeList.length; index++) {
								const tempEmployee = tempEmployeeList[index];
								//if (tempEmployee.userType === 1) {
								doctorList.push(tempEmployee);
								//}
							}
							setClinicDoctorList(doctorList);
						} else {
							setClinicDoctorList([]);
						}
					}
				}
			}
		};

		const getFreeHourList = async (
			doctorId,
			dayValue,
			isOffline,
			roomId,
			pastDate = undefined,
			predefinedDate = undefined
		) => {
			const past =
				pastDate ||
				(pastDate === undefined &&
					isBefore(
						randevu.appointmentDate,
						dayjs(new Date()).set("hour", 0).set("minute", 1).valueOf()
					));
			const freeDate = new Date(dayValue);
			const vacationDate = new Date(doctorSettings?.doctorPrice?.vacationDate);
			const vacationEndDate = new Date(doctorSettings?.doctorPrice?.vacationEndDate);
			vacationEndDate.setDate(vacationEndDate.getDate() + 1);
			if (!past && vacationEndDate >= freeDate && freeDate >= vacationDate) {
				setFreeHoursForSelectedDay([]);
				return props.store.setMessage(
					"Seçilen tarih için randevular kapatılmıştır.",
					true,
					"error"
				);
			}
			let getHoursListResponse = null;
			if (isOffline) {
				let isOk = true;
				let errorMessage = "";

				if (doctorId === 0) {
					isOk = false;
					errorMessage = "Doktor seçmeden, müsait saatleri göremezsiniz.";
				}

				let clinicId = await getKlinikBilgisiReq();

				if (clinicId === 0) {
					isOk = false;
					errorMessage =
						"Klinik bilgisi getirilemediğinden müsait saatleri görüntüleyemiyorsunuz.";
				}

				if (roomId === 0) {
					isOk = false;
					errorMessage = "Oda seçmediğiniz için müsait saatleri görüntüleyemezsiniz.";
				}

				if (isOk) {
					getHoursListResponse = await getAppointmentHoursByDayIdAndRoomId(
						props.store.baseUrl,
						props.store.token,
						doctorId,
						dayValue,
						clinicId,
						roomId
					);
				} else {
					props.store.setMessage(errorMessage, true, "error");
				}
			} else {
				getHoursListResponse = await getAppointmentHoursByDayId(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					dayValue
				);
			}

			if (getHoursListResponse !== undefined && getHoursListResponse !== null) {
				if (getHoursListResponse.status === 200) {
					let freeHourList = getHoursListResponse.data;

					if (freeHourList !== null) {
						let freeHours = [];

						for (let index = 0; index < freeHourList.length; index++) {
							let tempFreeHour = freeHourList[index];

							let startHour = tempFreeHour.startDate;
							if (tempFreeHour.isActive || isOffline) {
								startHour = startHour.split("T")[1];
								startHour = startHour.split(":")[0] + ":" + startHour.split(":")[1];
								freeHours.push(startHour);
							}
						}

						if (predefinedDate && !freeHours.includes(predefinedDate)) {
							freeHours.push(predefinedDate);
							freeHours.sort();
						}
						setFreeHoursForSelectedDay(freeHours);
					} else {
						setFreeHoursForSelectedDay([]);
						props.store.setMessage(
							"Seçtiğiniz tarihte, randevuya uygun bir saat bulunamadı.",
							true,
							"error"
						);
					}
				}
			}
		};

		const handleEventSelect = async (event) => {
			setSelectedCoupon("");
			props.store.setLoadingBarStatus(false);
			setSelectedRandevu(event.randevu);

			if (new Date() > event.randevu.appointmentDate) {
				setSelectedRandevuPassed(true);
			} else {
				setSelectedRandevuPassed(false);
			}

			let getPatientInfoResponse = await getDoctorPatientInfo(
				props.store.baseUrl,
				props.store.token,
				event.randevu.doctorId,
				event.randevu.patientId
			);
			if (getPatientInfoResponse !== undefined && getPatientInfoResponse !== null) {
				if (getPatientInfoResponse.status === 200) {
					if (getPatientInfoResponse.data !== null) {
						let tempPatient = toJS(getPatientInfoResponse.data);
						tempPatient.label = tempPatient.name + " " + tempPatient.surname;
						setSelectedPatient(tempPatient);
					} else {
						setSelectedPatient(null);
					}
				}
			}

			let dayValue =
				new Date(dayjs(event.randevu.appointmentDate).valueOf()).getFullYear() +
				"-" +
				(new Date(dayjs(event.randevu.appointmentDate).valueOf()).getMonth() + 1) +
				"-" +
				new Date(dayjs(event.randevu.appointmentDate).valueOf()).getDate();

			let isOffline = event.randevu.appointmentType === 2 ? true : false;

			if (isOffline) {
				setOfflineRandevuAccordionDisplay(true);
				setSelectedDoctorId(event.randevu.doctorId);
			} else {
				setOfflineRandevuAccordionDisplay(false);
			}
			let roomId = event.randevu.appointmentType === 2 ? event.randevu.roomId : 0;

			const appointmentDate = event.randevu.appointmentDate;
			const hour = appointmentDate.getHours();
			const minute = appointmentDate.getMinutes();
			const hourString = `${hour.toString().padStart(2, "0")}:${minute
				.toString()
				.padStart(2, "0")}`;
			await getFreeHourList(
				event.randevu.doctorId,
				dayValue,
				isOffline,
				roomId,
				undefined,
				hourString
			);
			setSelectedHourArray([hourString]);
			setRandevuInfoDialogMenuDisplay(true);
			setAppoTime(
				(event.randevu.appointmentEndDate - event.randevu.appointmentDate) / (60 * 1000)
			);
			setStatusType(event.randevu.status);
			props.store.setLoadingBarStatus(true);
		};

		const changeDate = async (e) => {
			props.store.setLoadingBarStatus(false);
			const date = new Date(e.getTime());
			let pastMonth = new Date(e.getTime());
			let nextMonth = new Date(e.getTime());
			pastMonth = new Date(pastMonth.setDate(date.getDate() - 40))
				.toISOString()
				.split("T")[0];
			nextMonth = new Date(nextMonth.setDate(date.getDate() + 40))
				.toISOString()
				.split("T")[0];
			let appointmentListResponse = await getAppointmentList(
				props.store.baseUrl,
				props.store.token,
				props.store.doctor[0].id,
				pastMonth,
				nextMonth
			);
			if (appointmentListResponse !== undefined && appointmentListResponse !== null) {
				if (appointmentListResponse.status === 200) {
					//"console.log("appointmentListResponse", appointmentListResponse.data)
					props.store.setRandevuListesi(appointmentListResponse.data);
				} else if (appointmentListResponse.status === 401) {
					props.store.setUserLogout();
					window.location.reload();
				}
			}
			let eventListesi = createEventList(appointmentListResponse.data);
			setEvents(eventListesi);
			props.store.setLoadingBarStatus(true);
		};

		const handleCloseRandevuOlusturMenu = () => {
			setRandevuDialogMenuDisplay(false);
		};

		const handleClosePatientSearchForm = () => {
			setSearchTC("");
			setPatientSearchForm(false);
			setFoundPatient(null);
		};

		const handleOpenRandevuMenu = () => {
			setSendNoSMS(false);
			setAppoTime(doctorSettings.doctorPrice.examinationTime);
			setRandevuDialogMenuDisplay(true);
			setStatusType(0);
			setSelectedCoupon("");
		};

		const handleCloseRandevuBilgiMenu = () => {
			setRandevuInfoDialogMenuDisplay(false);
		};

		const handleChangeGorusmeTipi = async (event) => {
			if (event.target.value === 2) {
				let klinikId = await getKlinikBilgisiReq();

				if (klinikId === null) {
					props.store.setMessage(
						"Henüz sistemde kayıtlı bir klinik bilgisi bulunamadığından offline randevu oluşturamazsınız.",
						true,
						"error"
					);
				} else {
					setOfflineRandevuAccordionDisplay(true);
					setShowRoomListMenu(true);
					setRandevu({ ...randevu, appointmentType: event.target.value });
				}
			} else {
				setSelectedCoupon("");
				setShowRoomListMenu(false);
				setOfflineRandevuAccordionDisplay(false);
				let dayValue =
					new Date().getFullYear() +
					"-" +
					(new Date().getMonth() + 1) +
					"-" +
					new Date().getDate();

				let isOffline = false;
				let roomId = 0;
				await getFreeHourList(null, dayValue, isOffline, roomId);

				setRandevu({
					...randevu,
					appointmentType: event.target.value,
					doctorId: props.store.doctor[0].id,
				});
			}
		};

		const onChangeAutoCompletePatient = (selectedPatient) => {
			if (selectedPatient?.id === 0) {
				setPatientSearchForm(true);
			} else {
				if (selectedPatient !== null && selectedPatient !== undefined) {
					setSelectedPatient(selectedPatient);
				}
			}
		};

		const onChangeCalendarValue = async (event) => {
			const past = isBefore(
				new Date(dayjs(event).valueOf()),
				dayjs(new Date()).set("hour", 0).set("minute", 1).valueOf()
			);
			let vacation = false;
			const freeDate = new Date(dayjs(event).valueOf());
			const vacationDate = new Date(doctorSettings?.doctorPrice?.vacationDate);
			const vacationEndDate = new Date(doctorSettings?.doctorPrice?.vacationEndDate);
			vacationEndDate.setDate(vacationEndDate.getDate() + 1);
			if (!past && vacationEndDate >= freeDate && freeDate >= vacationDate) {
				setFreeHoursForSelectedDay([]);
				props.store.setMessage(
					"Seçilen tarih için randevular kapatılmıştır.",
					true,
					"error"
				);
				vacation = true;
			}

			let dayValue =
				new Date(dayjs(event).valueOf()).getFullYear() +
				"-" +
				(new Date(dayjs(event).valueOf()).getMonth() + 1) +
				"-" +
				new Date(dayjs(event).valueOf()).getDate();

			let isOffline = false;
			let roomId = 0;
			let doctorId = null;
			if (randevu.appointmentType === 2) {
				isOffline = true;
				roomId = randevu.roomId;
				doctorId = selectedDoctorId;
			}

			let startDate = dayjs(event).valueOf();
			let endDate = dayjs(event).add(30, "minute").valueOf();
			if (past) {
				props.store.setMessage("Geçmiş tarihli randevu oluşturuyorsunuz", true, "info");
				setSendNoSMS(true);
				setRandevu({
					...randevu,
					appointmentDate: new Date(startDate),
					appointmentEndDate: new Date(endDate),
				});
			} else {
				setStatusType(0);
				setRandevu({ ...randevu });
				setRandevu({
					...randevu,
					appointmentDate: new Date(startDate),
					appointmentEndDate: new Date(endDate),
					status: 0,
				});
			}
			!vacation && (await getFreeHourList(doctorId, dayValue, isOffline, roomId, past));
		};

		const onChangeRandevuTextInput = (event) => {
			setRandevu({ ...randevu, description: event.target.value });
		};

		const onChangeRandevuBilgiTextInput = (event) => {
			setSelectedRandevu({ ...selectedRandevu, description: event.target.value });
		};

		const addEvent = async () => {
			props.store.setLoadingBarStatus(false);

			let randevuObjectOkStatus = false;
			let errorMessage = "";
			let randevuObject = toJS(randevu);
			let tempEvent = null;

			if (selectedHourArray.length > 0) {
				let selectedStartHour = selectedHourArray[0].split(":")[0];
				let selectedStartMinutes = selectedHourArray[0].split(":")[1];

				let selectedEndHour = selectedHourArray[selectedHourArray.length - 1].split(":")[0];
				let selectedEndMinutes =
					selectedHourArray[selectedHourArray.length - 1].split(":")[1];

				if (randevuObject.appointmentType !== 0) {
					randevuObject.appointmentDate.setHours(selectedStartHour);
					randevuObject.appointmentDate.setMinutes(selectedStartMinutes);

					randevuObject.appointmentEndDate = new Date();

					randevuObject.appointmentEndDate.setHours(selectedEndHour);
					randevuObject.appointmentEndDate.setMinutes(
						Number(selectedEndMinutes) + appoTime
					);

					if (selectedPatient.id !== 0) {
						randevuObject.patientId = selectedPatient.patientId;
						randevuObject.appointmentDate = dayjs(randevuObject.appointmentDate)
							.format()
							.split("+")[0];
						randevuObject.appointmentEndDate = dayjs(randevuObject.appointmentEndDate)
							.format()
							.split("+")[0];
						randevuObject.title = selectedPatient.name + " " + selectedPatient.surname;
						randevuObject.noSms = sendNoSMS;
						randevuObject.coupon = selectedCoupon;
						randevuObject.status = statusType;
						delete randevuObject.id;

						if (randevuObject.appointmentType !== 2) {
							delete randevuObject.roomId;
							delete randevuObject.clinicId;
							delete randevuObject.priceOffline;
							delete randevuObject.productsOffline;
						}

						delete randevuObject.appointmentId;
						delete randevuObject.patientBirthDate;
						delete randevuObject.patientGender;
						delete randevuObject.patientHeight;
						delete randevuObject.patientName;
						delete randevuObject.patientSurname;
						delete randevuObject.patientWeight;

						if (randevuObject.appointmentType === 2) {
							if (randevuObject.roomId !== 0) {
								randevuObjectOkStatus = true;
							}
						} else {
							randevuObjectOkStatus = true;
						}
						tempEvent = {
							start: randevuObject.appointmentDate, // 10.00 AM
							end: randevuObject.appointmentEndDate, // 2.00 PM
							title: (
								<>
									{statusType === 0 ? (
										<HourglassTopIcon
											style={{ fontSize: "18px", color: "#ffeb3b" }}
										/>
									) : statusType === 1 ? (
										<HourglassTopIcon
											style={{ fontSize: "18px", color: "#ffeb3b" }}
										/>
									) : statusType === 2 ||
									  statusType === 3 ||
									  statusType === 4 ||
									  statusType === 5 ? (
										<CheckIcon style={{ fontSize: "16px", color: "#52be80" }} />
									) : statusType === 6 ? (
										<CloseIcon color="error" style={{ fontSize: "10px" }} />
									) : statusType === 7 || statusType === 8 ? (
										<CloseIcon color="error" style={{ fontSize: "16px" }} />
									) : statusType === 9 ? (
										<MoneyIcon color="error" style={{ fontSize: "16px" }} />
									) : null}
									{randevuObject.appointmentType === 0 ||
									randevuObject.appointmentType === 1 ? (
										<VideoCameraFrontIcon
											color="primary"
											style={{ fontSize: "16px", marginRight: "5px" }}
										/>
									) : (
										<PersonOutlineIcon
											color="primary"
											style={{ fontSize: "16px", marginRight: "5px" }}
										/>
									)}
									<span style={{ color: "gray" }}>
										{dayjs(randevuObject.appointmentDate).format("HH:mm")} -{" "}
										{randevuObject.title}
									</span>
								</>
							),
							description: randevuObject.description,
							hexColor: "#fff",
							id: 0,
							allDay: false,
							randevu: randevuObject,
						};
					} else {
						errorMessage =
							"Hasta seçmediğiniz için randevu oluşturamazsınız. Lütfen hasta seçiniz.";
					}
					//console.log("randevu: ", randevu)
				} else {
					errorMessage =
						"Görüşme tipini seçmediğiniz için randevu oluşturamazsınız. Lütfen görüşme tipini seçiniz.";
				}
			} else {
				errorMessage =
					"Randevu saatini seçmediğiniz için randevu oluşturamazsınız. Lütfen randevu saatini seçiniz.";
			}

			if (randevuObjectOkStatus) {
				let createRandevuResponse = await postAppointment(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					randevuObject
				);

				if (createRandevuResponse !== null && createRandevuResponse !== undefined) {
					if (createRandevuResponse.status === 200) {
						props.store.setMessage("Randevu başarıyla oluşturuldu.", true, "success");
						setEvents((events) => [...events, tempEvent]);
						await getAppointmentListReq();
						setRandevu(Appointment.create({}));
						setSelectedHourArray([]);
						setRandevuDialogMenuDisplay(false);
					} else if (createRandevuResponse.status === 201) {
						props.store.setMessage(createRandevuResponse.data.message, true, "success");
						setEvents((events) => [...events, tempEvent]);
						await getAppointmentListReq();
						setRandevu(Appointment.create({}));
						setSelectedHourArray([]);
						setRandevuDialogMenuDisplay(false);
					} else {
						setRandevu(Appointment.create({}));
						setSelectedHourArray([]);
						setRandevuDialogMenuDisplay(false);
						props.store.setMessage("Randevu oluşturulurken hata oluştu", true, "error");
					}
				} else {
					setRandevu(Appointment.create({}));
					setSelectedHourArray([]);
					setRandevuDialogMenuDisplay(false);
					props.store.setMessage("Randevu oluşturulurken hata oluştu", true, "error");
				}
			} else {
				props.store.setMessage(errorMessage, true, "error");
			}
			props.store.setLoadingBarStatus(true);
		};

		const updateEvent = async () => {
			props.store.setLoadingBarStatus(false);

			let randevuObjectOkStatus = false;
			let errorMessage = "";
			let randevuObject = toJS(selectedRandevu);
			let tempEvent5 = null;

			if (selectedHourArray.length > 0) {
				let selectedStartHour = selectedHourArray[0].split(":")[0];
				let selectedStartMinutes = selectedHourArray[0].split(":")[1];

				if (randevuObject.appointmentType !== 0) {
					randevuObject.appointmentDate = new Date(randevuObject.appointmentDate);
					randevuObject.appointmentDate.setHours(Number(selectedStartHour));
					randevuObject.appointmentDate.setMinutes(Number(selectedStartMinutes));

					randevuObject.appointmentEndDate = new Date(randevuObject.appointmentDate);
					randevuObject.appointmentEndDate = new Date(
						randevuObject.appointmentEndDate.setMinutes(
							randevuObject.appointmentEndDate.getMinutes() + appoTime
						)
					);

					if (selectedPatient.id !== 0) {
						randevuObject.patientId = selectedPatient.patientId;
						randevuObject.appointmentDate = dayjs(randevuObject.appointmentDate)
							.format()
							.split("+")[0];
						randevuObject.appointmentEndDate = dayjs(randevuObject.appointmentEndDate)
							.format()
							.split("+")[0];
						randevuObject.title = selectedPatient.name + " " + selectedPatient.surname;
						randevuObject.status = statusType;
						randevuObject.duration = appoTime;
						delete randevuObject.id;

						if (randevuObject.appointmentType !== 2) {
							delete randevuObject.roomId;
							delete randevuObject.clinicId;
							delete randevuObject.priceOffline;
							delete randevuObject.productsOffline;
						}

						delete randevuObject.patientBirthDate;
						delete randevuObject.patientGender;
						delete randevuObject.patientHeight;
						delete randevuObject.patientName;
						delete randevuObject.patientSurname;
						delete randevuObject.patientWeight;

						if (randevuObject.appointmentType === 2) {
							if (randevuObject.roomId !== 0) {
								randevuObjectOkStatus = true;
							}
						} else {
							randevuObjectOkStatus = true;
						}

						tempEvent5 = {
							start: randevuObject.appointmentDate, // 10.00 AM
							end: randevuObject.appointmentEndDate, // 2.00 PM
							title: (
								<>
									{statusType === 0 ? (
										<HourglassTopIcon
											style={{ fontSize: "18px", color: "#ffeb3b" }}
										/>
									) : statusType === 1 ? (
										<HourglassTopIcon
											style={{ fontSize: "18px", color: "#ffeb3b" }}
										/>
									) : statusType === 2 ||
									  statusType === 3 ||
									  statusType === 4 ||
									  statusType === 5 ? (
										<CheckIcon style={{ fontSize: "16px", color: "#52be80" }} />
									) : statusType === 6 ? (
										<CloseIcon color="error" style={{ fontSize: "10px" }} />
									) : statusType === 7 || statusType === 8 ? (
										<CloseIcon color="error" style={{ fontSize: "16px" }} />
									) : statusType === 9 ? (
										<MoneyIcon color="error" style={{ fontSize: "16px" }} />
									) : null}
									{randevuObject.appointmentType === 0 ||
									randevuObject.appointmentType === 1 ? (
										<VideoCameraFrontIcon
											color="primary"
											style={{ fontSize: "16px", marginRight: "5px" }}
										/>
									) : (
										<PersonOutlineIcon
											color="primary"
											style={{ fontSize: "16px", marginRight: "5px" }}
										/>
									)}
									<span style={{ color: "gray" }}>
										{dayjs(randevuObject.appointmentDate).format("HH:mm")} -{" "}
										{randevuObject.title}
									</span>
								</>
							),
							description: randevuObject.description,
							hexColor: "#fff",
							id: 0,
							allDay: false,
							randevu: randevuObject,
						};
					} else {
						errorMessage =
							"Hasta seçmediğiniz için randevu güncelleyemezsiniz. Lütfen hasta seçiniz.";
					}
				} else {
					errorMessage =
						"Görüşme tipini seçmediğiniz için randevu güncelleyemezsiniz. Lütfen görüşme tipini seçiniz.";
				}
			} else {
				errorMessage =
					"Randevu saatini seçmediğiniz için randevu güncelleyemezsiniz. Lütfen randevu saatini seçiniz.";
			}

			if (randevuObjectOkStatus) {
				let updateRandevuResponse = await putAppointment(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					randevuObject.appointmentId,
					randevuObject
				);

				if (updateRandevuResponse !== null && updateRandevuResponse !== undefined) {
					if (updateRandevuResponse.data.status === 200) {
						props.store.setMessage("Randevu başarıyla güncellendi.", true, "success");
						await getAppointmentListReq();
						const updatedEvents = events.map((item) => {
							if (item.randevu && item.randevu.id === tempEvent5.randevu.id) {
								return tempEvent5;
							} else {
								return item;
							}
						});
						setEvents(updatedEvents);
						setRandevu(Appointment.create({}));
						setSelectedHourArray([]);
						setRandevuInfoDialogMenuDisplay(false);
					} else {
						props.store.setMessage(
							updateRandevuResponse.data.message ??
								"Randevu oluşturulurken hata oluştu",
							true,
							"error"
						);
					}
				} else {
					props.store.setMessage("Randevu oluşturulurken hata oluştu", true, "error");
				}
			} else {
				props.store.setMessage(errorMessage, true, "error");
			}
			props.store.setLoadingBarStatus(true);
		};

		const handleChange = (event) => {
			const {
				target: { value },
			} = event;
			setSelectedHourArray(
				// On autofill we get a stringified value.
				typeof value === "string" ? value.split(",") : value
			);
		};

		const startVideoCall = async (appointment) => {
			props.history.push(
				"/panel/gorusme/" + appointment.appointmentId + "/" + appointment.patientId
			);
		};

		const handleChangeAppointmentRoom = async (event) => {
			let dayValue =
				new Date().getFullYear() +
				"-" +
				(new Date().getMonth() + 1) +
				"-" +
				new Date().getDate();

			await getFreeHourList(selectedDoctorId, dayValue, true, event.target.value);
			setRandevu({ ...randevu, roomId: event.target.value });
		};

		const handleChangeAppointmentHizmet = (event) => {
			setSelectedHizmetId(event.target.value);
		};

		const onClickHizmetEkle = () => {
			let offlineRandevuProductList = toJS(randevu.productsOffline);
			let toplamHizmetFiyat = 0;
			for (let index = 0; index < hizmetList.length; index++) {
				const hizmetTemp = hizmetList[index];

				if (hizmetTemp.productId === selectedHizmetId) {
					if (selectedHizmetList.includes(hizmetTemp)) {
						props.store.setMessage(
							"Seçtiğiniz hizmet, listede zaten mevcut",
							true,
							"warning"
						);
					} else {
						setSelectedHizmetList([...selectedHizmetList, hizmetTemp]);

						setSelectedHizmetToplamFiyat(selectedHizmetToplamFiyat + hizmetTemp.price);
						toplamHizmetFiyat = selectedHizmetToplamFiyat + hizmetTemp.price;
					}
				}
			}

			if (randevu.productsOffline.includes(selectedHizmetId)) {
			} else {
				offlineRandevuProductList.push(selectedHizmetId);
			}

			setRandevu({
				...randevu,
				productsOffline: offlineRandevuProductList,
				priceOffline: toplamHizmetFiyat,
			});
		};

		const onClickRemoveHizmet = (selectedHizmetId) => {
			let offlineRandevuProductList = toJS(randevu.productsOffline);
			let toplamHizmetFiyat = 0;
			for (let index = 0; index < hizmetList.length; index++) {
				const hizmetTemp = hizmetList[index];

				if (hizmetTemp.productId === selectedHizmetId) {
					setSelectedHizmetToplamFiyat(selectedHizmetToplamFiyat - hizmetTemp.price);
					toplamHizmetFiyat = selectedHizmetToplamFiyat - hizmetTemp.price;
				}
			}

			setSelectedHizmetList(
				selectedHizmetList.filter((a) => a.productId !== selectedHizmetId)
			);

			if (randevu.productsOffline.includes(selectedHizmetId)) {
				offlineRandevuProductList = offlineRandevuProductList.filter(
					(a) => a !== selectedHizmetId
				);
			}

			//console.log("offlineRandevuProductList: ", offlineRandevuProductList)

			setRandevu({
				...randevu,
				productsOffline: offlineRandevuProductList,
				priceOffline: toplamHizmetFiyat,
			});
		};

		const onChangeOfflinePriceTextField = (event) => {
			if (event.target.name === "offline")
				setRandevu({ ...randevu, priceOffline: Number(event.target.value) });
			if (event.target.name === "examination")
				setRandevu({ ...randevu, examinationPrice: Number(event.target.value) });
		};

		const handleChangeDoctorSelectMenu = (event) => {
			setRandevu({ ...randevu, doctorId: event.target.value });
			setSelectedDoctorId(event.target.value);
		};

		const onClickTumRandevuBilgileri = () => {
			props.history.push("/panel/randevularim");
		};

		const handleChangeFilterSelectChange = (event) => {
			setSelectedFilter(event.target.value);

			if (event.target.value !== 0) {
				let filterAppointmentList = [];
				if (event.target.value === -1) {
					for (let index = 0; index < appointmentList.length; index++) {
						const tempAppointment = appointmentList[index];
						if (tempAppointment.appointmentType === 1) {
							filterAppointmentList.push(tempAppointment);
						}
					}
				} else if (event.target.value === -2) {
					for (let index = 0; index < appointmentList.length; index++) {
						const tempAppointment = appointmentList[index];
						if (tempAppointment.appointmentType === 2) {
							filterAppointmentList.push(tempAppointment);
						}
					}
				} else if (event.target.value > 0) {
					for (let index = 0; index < appointmentList.length; index++) {
						const tempAppointment = appointmentList[index];
						if (tempAppointment.roomId === event.target.value) {
							filterAppointmentList.push(tempAppointment);
						}
					}
				}
				let eventListesi = createEventList(filterAppointmentList);
				setEvents(eventListesi);
			} else {
				let eventListesi = createEventList(backupAppointmentList);
				setEvents(eventListesi);
			}
		};

		const onClickClearFilter = () => {
			setAppointmentList(backupAppointmentList);
			let eventListesi = createEventList(backupAppointmentList);
			setEvents(eventListesi);
		};
		const setSearchPatientTC = async () => {
			let findPatientRes = await findPatient(
				props.store.baseUrl,
				props.store.token,
				props.store.user[0].id,
				searchTC
			);
			if (findPatientRes !== undefined && findPatientRes !== null) {
				if (findPatientRes.status === 200) {
					setFoundPatient(findPatientRes.data.data);
				} else if (findPatientRes.status === 400 || findPatientRes.status === 404) {
					props.store.setMessage(findPatientRes.data.message, true, "error");
					setFoundPatient(null);
				} else {
					props.store.setMessage(
						"Hasta bilgisi getirilirken hata oluştu.",
						true,
						"error"
					);
					setFoundPatient(null);
				}
			} else {
				props.store.setMessage("Hasta bilgisi getirilirken hata oluştu.", true, "error");
			}
		};

		const insertDoctorPatient = async () => {
			foundPatient.patientId = foundPatient.id;
			foundPatient.label = foundPatient.name + " " + foundPatient.surname;
			setPatientList([...patientList, foundPatient]);
			setSelectedPatient(foundPatient);
			handleClosePatientSearchForm();
		};

		return (
			<div>
				<Card style={{ marginTop: "8px", height: "auto" }}>
					<CardHeader
						title="Ajandam"
						style={{ paddingBottom: "0", paddingTop: "15px" }}
						action={
							<>
								<Button
									variant="contained"
									style={{ height: "55px", margin: "10px", float: "right" }}
									onClick={handleOpenRandevuMenu}
								>
									<AddIcon /> Yeni Randevu Oluştur
								</Button>

								<Button
									onClick={onClickTumRandevuBilgileri}
									color="primary"
									variant="contained"
									style={{
										height: "55px",
										margin: "10px",
										marginRight: "0",
										float: "right",
										background: "#e0e0e0",
										color: "#000",
									}}
								>
									<ListAltIcon style={{ marginRight: "10px" }} /> Randevu Listesi
								</Button>

								<Button
									style={{
										height: "56px",
										margin: "9px",
										marginRight: "0",
										float: "right",
										background: "#e0e0e0",
										color: "#000",
										marginLeft: "0",
										borderTopLeftRadius: "0",
										borderBottomLeftRadius: "0",
									}}
									onClick={onClickClearFilter}
								>
									<DeleteIcon />
								</Button>

								<FormControl
									style={{
										marginTop: "9px",
										width: "250px",
										float: "right",
										background: "#e0e0e0",
										borderRadius: "0px",
										marginRight: "0",
									}}
								>
									<InputLabel id="demo-simple-select-label">Filtrele</InputLabel>
									<Select
										id="demo-simple-select"
										variant="outlined"
										labelId="demo-simple-select-label"
										value={selectedFilter}
										label="Filtrele"
										onChange={handleChangeFilterSelectChange}
									>
										<MenuItem key={"seciniz"} value={0}>
											Seçiniz
										</MenuItem>
										{filterOptions.map((room, idx) => (
											<MenuItem key={idx} value={room.roomId}>
												{room.roomName}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</>
						}
					></CardHeader>
					<CardContent style={{ height: "auto", padding: "30px", paddingTop: "0" }}>
						<Calendar
							culture={"tr-Tr"}
							localizer={localizer}
							events={events}
							startAccessor="start"
							endAccessor="end"
							style={{ minHeight: "100vh" }}
							selectable
							views={{
								day: true,
								week: true,
								month: true,
							}}
							messages={{
								agenda: "Ajanda",
								allDay: "Tüm Günler",
								month: "Ay",
								day: "Gün",
								today: "Bugün",
								previous: "Önceki",
								next: "Sonraki",
								date: "Gün",
								noEventsInRange: "Seçtiğiniz aralıkta herhangi bir etkinlik yok",
								time: "Zaman",
								tomorrow: "Yarın",
								week: "Hafta",
								work_week: "Çalışma Haftası",
								yesterday: "Dün",
								event: "Etkinlik",
								year: "Yıl",
								showMore: function showMore(total) {
									return (
										<span
											style={{
												fontSize: "12px",
												marginLeft: "10px",
												color: "#000",
											}}
										>
											{" "}
											{total} tane daha
										</span>
									);
								},
							}}
							eventPropGetter={(event, start, end, isSelected) => {
								let newStyle = {
									backgroundColor: event.hexColor,
									color: "black",
								};

								return {
									className: "",
									style: newStyle,
								};
							}}
							tooltipAccessor={(event) =>
								(event.randevu.appointmentType === 1
									? "Online Randevu: "
									: "Yüzyüze Randevu: ") +
								" " +
								dayjs(event.randevu.appointmentDate).format("DD.MM.YYYY HH:mm") +
								" " +
								event.randevu.title +
								" " +
								event.randevu.description
							}
							onSelectEvent={props.store.userType !== 3 ? handleEventSelect : null}
							onNavigate={changeDate}
						/>
					</CardContent>
				</Card>
				<Dialog open={randevuDialogMenuDisplay} onClose={handleCloseRandevuOlusturMenu}>
					<DialogTitle>Yeni Randevu Oluştur</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Bu menüden kayıtlı hastalarınız için ileri tarihli online randevu veya
							klinik tanımlaması yapılmış ise yüzyüze randevu oluşturabilirsiniz.
						</DialogContentText>

						<Grid container spacing={2} style={{ marginTop: "15px", width: "100%" }}>
							<Grid item xs={1}>
								<CameraswitchIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<FormControl fullWidth style={{ marginTop: "15px" }}>
									<InputLabel id="demo-simple-select-label">
										Görüşme Tipi
									</InputLabel>
									<Select
										id="demo-simple-select"
										labelId="demo-simple-select-label"
										value={randevu.appointmentType}
										label="Görüşme Tipi"
										onChange={handleChangeGorusmeTipi}
										variant="outlined"
									>
										<MenuItem value={1}>Online</MenuItem>
										<MenuItem value={2}>Yüzyüze</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							{randevu.appointmentType === 2 && (
								<>
									<Grid item xs={1}>
										<MeetingRoomIcon style={{ marginTop: "15px" }} />
									</Grid>
									<Grid item xs={11}>
										<Accordion
											expanded={offlineRandevuAccordionDisplay}
											style={{ background: "#fcfcfc" }}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1bh-content"
												id="panel1bh-header"
											>
												<Typography sx={{ width: "33%", flexShrink: 0 }}>
													Yüzyüze Görüşme Ayarları
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<FormControl
															fullWidth
															style={{ marginTop: "0px" }}
														>
															<InputLabel id="demo-simple-select-label">
																Doktor Seçiniz
															</InputLabel>
															<Select
																id="demo-simple-select"
																labelId="demo-simple-select-label"
																value={randevu.doctorId}
																label="Doktor Seçiniz"
																onChange={
																	handleChangeDoctorSelectMenu
																}
																variant="filled"
																disabled={!showRoomListMenu}
															>
																{clinicDoctorList
																	.filter(
																		(doc) =>
																			doc.userType === 1 ||
																			doc.userType === 3
																	)
																	.map((doctor, idx) => (
																		<MenuItem
																			key={idx}
																			value={doctor.doctorId}
																		>
																			{" "}
																			{(doctor.userType === 1
																				? "Doktor "
																				: "Hemşire ") +
																				doctor.name +
																				" " +
																				doctor.surname}
																		</MenuItem>
																	))}
															</Select>
														</FormControl>
													</Grid>
													<Grid item xs={12}>
														<FormControl
															fullWidth
															style={{ marginTop: "15px" }}
														>
															<InputLabel id="demo-simple-select-label">
																Oda Seçiniz
															</InputLabel>
															<Select
																id="demo-simple-select"
																labelId="demo-simple-select-label"
																value={randevu.roomId}
																label="Oda Seçiniz"
																onChange={
																	handleChangeAppointmentRoom
																}
																variant="filled"
																disabled={!showRoomListMenu}
															>
																{clinicRoomList.map((room, idx) => (
																	<MenuItem
																		key={idx}
																		value={room.roomId}
																	>
																		{room.roomName}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													</Grid>
													<Grid item xs={10}>
														<FormControl
															fullWidth
															style={{ marginTop: "15px" }}
														>
															<InputLabel id="demo-simple-select-label">
																Hizmet Seçiniz
															</InputLabel>
															<Select
																id="demo-simple-select"
																labelId="demo-simple-select-label"
																value={selectedHizmetId}
																label="Hizmet Seçiniz"
																onChange={
																	handleChangeAppointmentHizmet
																}
																variant="filled"
																disabled={!showRoomListMenu}
															>
																{hizmetList.map((hizmet, idx) => (
																	<MenuItem
																		key={idx}
																		value={hizmet.productId}
																	>
																		{hizmet.productName}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													</Grid>
													<Grid item xs={2}>
														<IconButton
															color="primary"
															variant="contained"
															style={{
																marginTop: "20px",
																float: "right",
															}}
															disabled={!showRoomListMenu}
															onClick={onClickHizmetEkle}
														>
															<AddIcon />
														</IconButton>
													</Grid>
													<Grid item xs={12}>
														<TableContainer>
															<Table
																style={{ width: "100%" }}
																size="small"
															>
																<TableHead>
																	<TableRow>
																		<TableCell>
																			SEÇİLEN HİZMET ADI
																		</TableCell>
																		<TableCell>
																			ÜCRETİ
																		</TableCell>
																		<TableCell>
																			İŞLEMLER
																		</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{selectedHizmetList.map(
																		(hizmet, idx) => (
																			<TableRow key={idx}>
																				<TableCell>
																					<Typography variant="body2">
																						{
																							hizmet.productName
																						}
																					</Typography>
																				</TableCell>
																				<TableCell>
																					<Typography
																						key={idx}
																						variant="body2"
																					>
																						{hizmet.price +
																							" TL"}
																					</Typography>
																				</TableCell>
																				<TableCell>
																					<IconButton
																						color="error"
																						onClick={() =>
																							onClickRemoveHizmet(
																								hizmet.productId
																							)
																						}
																					>
																						<DeleteIcon />
																					</IconButton>
																				</TableCell>
																			</TableRow>
																		)
																	)}
																</TableBody>
															</Table>
														</TableContainer>
														<Typography
															variant="body2"
															style={{ marginTop: "15px" }}
														>
															<b>Toplam Hizmet Bedeli:</b>{" "}
															{selectedHizmetToplamFiyat} TL
														</Typography>
													</Grid>
													<Grid item xs={12}>
														<FormControl
															fullWidth
															style={{ marginTop: "15px" }}
														>
															<TextField
																name="offline"
																label="Toplam Hizmet Ücreti (TL)"
																value={randevu.priceOffline}
																variant="filled"
																onChange={
																	onChangeOfflinePriceTextField
																}
																disabled={!showRoomListMenu}
															/>
														</FormControl>
													</Grid>
													<Grid item xs={12}>
														<FormControl
															fullWidth
															style={{ marginTop: "15px" }}
														>
															<TextField
																name="examination"
																label="Yüzyüze Muayene Ücreti (TL)"
																value={randevu.examinationPrice}
																variant="filled"
																onChange={
																	onChangeOfflinePriceTextField
																}
																disabled={!showRoomListMenu}
															/>
														</FormControl>
													</Grid>
													<Grid item xs={12}>
														<FormControl
															fullWidth
															style={{ marginTop: "15px" }}
														>
															<TextField
																label="Genel Toplam Ücreti (TL)"
																value={offlineRandevuGenelToplam}
																variant="filled"
																disabled={true}
															/>
														</FormControl>
													</Grid>
												</Grid>
											</AccordionDetails>
										</Accordion>
									</Grid>
								</>
							)}
							<Grid item xs={1}>
								<FaUserInjured style={{ marginTop: "15px", fontSize: "22px" }} />
							</Grid>
							<Grid item xs={11}>
								<FormControl fullWidth style={{ marginTop: "15px" }}>
									<Autocomplete
										id="select-patient"
										options={[
											{
												id: 0,
												name: "Hasta Ara",
												label: "Hasta Ara",
											},
											...patientList.filter((patient) => patient.isActive),
										]}
										noOptionsText="Hasta bilgisi mevcut değil"
										getOptionLabel={(option) => option.label}
										sx={{ width: "100%" }}
										disabled={randevu.type === 1 ? true : false}
										filterOptions={(options, state) => {
											if (state.inputValue === null) return options;
											else {
												return options.filter((option) =>
													option.label
														.toLocaleUpperCase("TR")
														.includes(
															state.inputValue.toLocaleUpperCase("TR")
														)
												);
											}
										}}
										onChange={(event, newValue) => {
											//setValue(newValue)
											onChangeAutoCompletePatient(newValue);
										}}
										renderInput={(params) => (
											<TextField {...params} label="Hasta Seçiniz" />
										)}
										value={selectedPatient}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={1}>
								<SmsIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<FormControlLabel
									control={
										<Switch
											checked={sendNoSMS}
											onChange={() => setSendNoSMS(!sendNoSMS)}
										/>
									}
									label="Randevuyu SMS olarak gönderme"
									style={{ marginTop: "8px" }}
								/>
							</Grid>
							<Grid item xs={1}>
								<QueryBuilderIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<FormControl fullWidth style={{ marginTop: "15px" }}>
											<LocalizationProvider
												dateAdapter={AdapterDayjs}
												adapterLocale={"tr"}
												style={{ width: "calc(100% - 100px)" }}
											>
												<DatePicker
													label="Randevu tarihi seçiniz"
													value={randevu.appointmentDate}
													onChange={(newValue) =>
														onChangeCalendarValue(newValue)
													}
													renderInput={(params) => (
														<TextField {...params} />
													)}
													resizable
												/>
											</LocalizationProvider>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">
												Müsait Saatler:
											</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												//value={selectedHourForRandevu}
												value={selectedHourArray}
												label="Müsait Saatler:"
												onChange={handleChange}
												/*onChange={handleFreeHoursChange}*/
											>
												{freeHoursForSelectedDay.map((freeHour, idx) => (
													<MenuItem key={idx} value={freeHour}>
														{freeHour}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl fullWidth>
											<TextField
												labelId="appo-time-label"
												label="Randevu Süresi (dk)"
												value={appoTime}
												onChange={(e) => {
													setAppoTime(e.target.value);
												}}
												type="number"
												required
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<InputLabel id="appointment-status">
												Randevu durumunu seçiniz
											</InputLabel>
											<Select
												labelId="appointment-status"
												id="appointment-status-select"
												value={statusType}
												label="Randevu durumunu seçiniz"
												onChange={(event) => {
													setStatusType(event.target.value);
												}}
												disabled={selectedCoupon}
											>
												<MenuItem value={0}>Belirtilmedi</MenuItem>
												<MenuItem value={3}>Geldi</MenuItem>
												<MenuItem value={7}>Gelmedi</MenuItem>
												<MenuItem value={6}>İptal Edildi</MenuItem>
												<MenuItem value={9}>Ödeme Bekleniyor</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									{randevu.appointmentType === 1 && (
										<Grid item xs={12}>
											<FormControl fullWidth>
												<InputLabel id="coupons">Kupon listesi</InputLabel>
												<Select
													labelId="coupons"
													id="coupons-select"
													value={selectedCoupon}
													label="Kupon listesi"
													onChange={(event) => {
														if (event.target.value) {
															setStatusType(0);
														}
														setSelectedCoupon(event.target.value);
													}}
												>
													<MenuItem value={""}>Seçilmedi</MenuItem>
													{couponList.map((coupon, idx) => {
														if (coupon.codeStatus === 1)
															return (
																<MenuItem
																	key={idx}
																	value={coupon.couponCode}
																>
																	{coupon.couponCode}
																</MenuItem>
															);
													})}
												</Select>
											</FormControl>
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid item xs={1}>
								<NotesIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<FormControl fullWidth style={{ marginTop: "15px" }}>
									<TextField
										label="Randevu Açıklama"
										value={randevu.description}
										multiline
										onChange={onChangeRandevuTextInput}
										rows={3}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseRandevuOlusturMenu}>Kapat</Button>
						<Button onClick={addEvent} variant="contained">
							Oluştur
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={randevuInfoDialogMenuDisplay} onClose={handleCloseRandevuOlusturMenu}>
					<DialogTitle>Randevu Bilgi Menüsü</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Bu menüden seçtiğiniz randevu bilgilerini görüntüleyebilirsiniz. Randevu
							durumu güncellemeye müsait ise randevu bilgilerini güncelleyebilirsiniz.
							Randevu online görüşme ekranına buradan girebilirsiniz.
						</DialogContentText>

						<Grid container spacing={2} style={{ marginTop: "15px", width: "100%" }}>
							<Grid item xs={1}>
								<CameraswitchIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<FormControl fullWidth style={{ marginTop: "15px" }}>
									<InputLabel id="demo-simple-select-label">
										Görüşme Tipi
									</InputLabel>
									<Select
										id="demo-simple-select"
										labelId="demo-simple-select-label"
										value={selectedRandevu.appointmentType}
										label="Görüşme Tipi"
										onChange={handleChangeGorusmeTipi}
										variant="outlined"
										disabled
									>
										<MenuItem value={0}>Online</MenuItem>
										<MenuItem value={1}>Online</MenuItem>
										<MenuItem value={2}>Yüzyüze</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							{selectedRandevu.appointmentType === 2 && (
								<>
									<Grid item xs={1}>
										<MeetingRoomIcon style={{ marginTop: "15px" }} />
									</Grid>
									<Grid item xs={11}>
										<Accordion
											expanded={offlineRandevuAccordionDisplay}
											style={{ background: "#fcfcfc" }}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1bh-content"
												id="panel1bh-header"
											>
												<Typography sx={{ width: "33%", flexShrink: 0 }}>
													Yüzyüze Görüşme Ayarları
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<FormControl
															fullWidth
															style={{ marginTop: "15px" }}
														>
															<InputLabel id="demo-simple-select-label">
																Doktor Seçiniz
															</InputLabel>
															<Select
																id="demo-simple-select"
																labelId="demo-simple-select-label"
																value={selectedRandevu.doctorId}
																label="Doktor Seçiniz"
																onChange={
																	handleChangeDoctorSelectMenu
																}
																variant="filled"
																disabled={!showRoomListMenu}
															>
																{clinicDoctorList
																	.filter(
																		(doc) =>
																			doc.userType === 1 ||
																			doc.userType === 3
																	)
																	.map((doctor, idx) => (
																		<MenuItem
																			key={idx}
																			value={doctor.doctorId}
																		>
																			{(doctor.userType === 1
																				? "Doktor "
																				: "Hemşire ") +
																				doctor.name +
																				" " +
																				doctor.surname}
																		</MenuItem>
																	))}
															</Select>
														</FormControl>
													</Grid>
													<Grid item xs={12}>
														<FormControl
															fullWidth
															style={{ marginTop: "15px" }}
														>
															<InputLabel id="demo-simple-select-label">
																Oda Seçiniz
															</InputLabel>
															<Select
																id="demo-simple-select"
																labelId="demo-simple-select-label"
																value={selectedRandevu.roomId}
																label="Oda Seçiniz"
																onChange={
																	handleChangeAppointmentRoom
																}
																variant="filled"
																disabled={
																	!showRoomListMenu ||
																	selectedRandevuPassed
																}
															>
																{clinicRoomList.map((room, idx) => (
																	<MenuItem
																		key={idx}
																		value={room.roomId}
																	>
																		{room.roomName}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													</Grid>
													<Grid item xs={10}>
														<FormControl
															fullWidth
															style={{ marginTop: "15px" }}
														>
															<InputLabel id="demo-simple-select-label">
																Hizmet Seçiniz
															</InputLabel>
															<Select
																id="demo-simple-select"
																labelId="demo-simple-select-label"
																value={selectedHizmetId}
																label="Hizmet Seçiniz"
																onChange={
																	handleChangeAppointmentHizmet
																}
																variant="filled"
																disabled={!showRoomListMenu}
															>
																{hizmetList.map((hizmet, idx) => (
																	<MenuItem
																		key={idx}
																		value={hizmet.productId}
																	>
																		{hizmet.productName}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													</Grid>
													<Grid item xs={2}>
														<IconButton
															color="primary"
															variant="contained"
															style={{
																marginTop: "20px",
																float: "right",
															}}
															disabled={!showRoomListMenu}
															onClick={onClickHizmetEkle}
														>
															<AddIcon />
														</IconButton>
													</Grid>
													<Grid item xs={12}>
														<TableContainer>
															<Table
																style={{ width: "100%" }}
																size="small"
															>
																<TableHead>
																	<TableRow>
																		<TableCell>
																			SEÇİLEN HİZMET ADI
																		</TableCell>
																		<TableCell>
																			ÜCRETİ
																		</TableCell>
																		<TableCell>
																			İŞLEMLER
																		</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{selectedRandevu.productsOffline.map(
																		(hizmet, idx) => (
																			<TableRow key={idx}>
																				<TableCell>
																					<Typography variant="body2">
																						{
																							hizmet.productName
																						}
																					</Typography>
																				</TableCell>
																				<TableCell>
																					<Typography
																						key={idx}
																						variant="body2"
																					>
																						{hizmet.price +
																							" TL"}
																					</Typography>
																				</TableCell>
																				<TableCell>
																					<IconButton
																						color="error"
																						onClick={() =>
																							onClickRemoveHizmet(
																								hizmet.productId
																							)
																						}
																					>
																						<DeleteIcon />
																					</IconButton>
																				</TableCell>
																			</TableRow>
																		)
																	)}
																</TableBody>
															</Table>
														</TableContainer>
														<Typography
															variant="body2"
															style={{ marginTop: "15px" }}
														>
															<b>Toplam Hizmet Bedeli:</b>{" "}
															{selectedRandevu.priceOffline} TL
														</Typography>
													</Grid>
													<Grid item xs={12}>
														<FormControl
															fullWidth
															style={{ marginTop: "15px" }}
														>
															<TextField
																name="offline"
																label="Toplam Hizmet Ücreti (TL)"
																value={selectedRandevu.priceOffline}
																variant="filled"
																onChange={
																	onChangeOfflinePriceTextField
																}
																disabled={!showRoomListMenu}
															/>
														</FormControl>
														<Grid item xs={12}>
															<FormControl
																fullWidth
																style={{ marginTop: "15px" }}
															>
																<TextField
																	name="examination"
																	label="Yüzyüze Muayene Ücreti (TL)"
																	value={randevu.examinationPrice}
																	variant="filled"
																	onChange={
																		onChangeOfflinePriceTextField
																	}
																	disabled={!showRoomListMenu}
																/>
															</FormControl>
														</Grid>
														<Grid item xs={12}>
															<FormControl
																fullWidth
																style={{ marginTop: "15px" }}
															>
																<TextField
																	label="Genel Toplam Ücreti (TL)"
																	value={
																		offlineRandevuGenelToplam
																	}
																	variant="filled"
																	disabled={true}
																/>
															</FormControl>
														</Grid>
													</Grid>
												</Grid>
											</AccordionDetails>
										</Accordion>
									</Grid>
								</>
							)}
							<Grid item xs={1}>
								<FaUserInjured style={{ marginTop: "15px", fontSize: "22px" }} />
							</Grid>
							<Grid item xs={11}>
								<Autocomplete
									id="select-patient"
									options={patientList}
									noOptionsText="Hasta bilgisi mevcut değil"
									getOptionLabel={(option) => option.label}
									sx={{ width: "100%" }}
									disabled={selectedRandevuPassed}
									onChange={(event, newValue) => {
										//setValue(newValue)
										onChangeAutoCompletePatient(newValue);
									}}
									filterOptions={(options, state) => {
										if (state.inputValue === null) return options;
										else {
											return options.filter((option) =>
												option.label
													.toLocaleUpperCase("TR")
													.includes(
														state.inputValue.toLocaleUpperCase("TR")
													)
											);
										}
									}}
									inputValue={selectedPatient.label}
									onInputChange={(event, newInputValue) => {
										if (event !== null) {
											setSelectedPatient({
												...selectedPatient,
												label: event.target.value,
											});
										}
									}}
									renderInput={(params) => (
										<TextField {...params} label="Hasta Seçiniz" disabled />
									)}
								/>
							</Grid>
							<Grid item xs={1}>
								<QueryBuilderIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<FormControl fullWidth style={{ marginTop: "15px" }}>
											<LocalizationProvider
												dateAdapter={AdapterDayjs}
												adapterLocale={"tr"}
												style={{ width: "calc(100% - 100px)" }}
											>
												<DatePicker
													label="Randevu tarihi seçiniz"
													disabled={selectedRandevuPassed}
													value={selectedRandevu.appointmentDate}
													onChange={(newValue) =>
														setSelectedRandevu({
															...selectedRandevu,
															appointmentDate: newValue,
														})
													}
													renderInput={(params) => (
														<TextField {...params} />
													)}
													resizable
												/>
											</LocalizationProvider>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">
												Müsait Saatler:
											</InputLabel>
											<Select
												disabled={selectedRandevuPassed}
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												//value={selectedHourForRandevu}
												value={selectedHourArray}
												label="Müsait Saatler:"
												onChange={handleChange}
												/*onChange={handleFreeHoursChange}*/
											>
												{freeHoursForSelectedDay.map((freeHour, idx) => (
													<MenuItem key={idx} value={freeHour}>
														{freeHour}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<FormControl fullWidth>
											<TextField
												disabled={selectedRandevuPassed}
												labelId="appo-time-label"
												label="Randevu Süresi (dk)"
												value={appoTime}
												onChange={(e) => {
													setAppoTime(e.target.value);
												}}
												type="number"
												required
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<InputLabel id="appointment-status">
												Randevu durumunu seçiniz
											</InputLabel>
											<Select
												labelId="appointment-status"
												id="appointment-status-select"
												value={statusType}
												label="Randevu durumunu seçiniz"
												onChange={(event) => {
													setStatusType(event.target.value);
												}}
											>
												<MenuItem value={3}>Geldi</MenuItem>
												<MenuItem value={7}>Gelmedi</MenuItem>
												<MenuItem value={6}>İptal Edildi</MenuItem>
												<MenuItem value={9}>Ödeme Bekleniyor</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={1}>
								<NotesIcon style={{ marginTop: "15px" }} />
							</Grid>
							<Grid item xs={11}>
								<FormControl fullWidth style={{ marginTop: "15px" }}>
									<TextField
										label="Randevu Açıklama"
										value={selectedRandevu.description}
										multiline
										onChange={onChangeRandevuBilgiTextInput}
										rows={3}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseRandevuBilgiMenu}>Kapat</Button>
						<Button onClick={updateEvent} variant="contained">
							Güncelle
						</Button>
						{props.store.userType !== 3 && (
							<Button
								variant={"contained"}
								onClick={() => startVideoCall(selectedRandevu)}
								disabled={selectedRandevu.appointmentType === 2 ? true : false}
								style={{ float: "right" }}
							>
								<HiVideoCamera style={{ fontSize: "20px", marginRight: "5px" }} />{" "}
								Görüşme Odasına Gir
							</Button>
						)}
					</DialogActions>
				</Dialog>
				<Dialog open={patientSearchForm} onClose={handleClosePatientSearchForm}>
					<DialogTitle>Hasta Ara</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Sistemde kayıtlı olan hastaları arayabilirsiniz.
						</DialogContentText>
						<Grid container spacing={2} style={{ marginTop: "15px", width: "100%" }}>
							<Grid item xs={10}>
								<FormControl fullWidth>
									<TextField
										label="TC Kimlik Numarası"
										value={searchTC}
										onChange={(e) => {
											setSearchTC(e.target.value);
										}}
										error={searchTC.length !== 11}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={2}>
								<FormControl fullWidth>
									<Button
										onClick={setSearchPatientTC}
										disabled={searchTC.length !== 11}
									>
										Ara
									</Button>
								</FormControl>
							</Grid>
						</Grid>
						{foundPatient && (
							<Grid
								container
								spacing={2}
								style={{ marginTop: "15px", width: "100%" }}
							>
								<Grid item xs={6}>
									<TextField label="İsim" value={foundPatient?.name} disabled />
								</Grid>
								<Grid item xs={6}>
									<TextField
										label="Soyisim"
										value={foundPatient?.surname}
										disabled
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										label="TC Kimlik No"
										value={foundPatient?.tckno}
										disabled
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										label="Telefon Numarası"
										value={foundPatient?.mobilePhone}
										disabled
									/>
								</Grid>
							</Grid>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClosePatientSearchForm}>Kapat</Button>
						{foundPatient && (
							<Button onClick={insertDoctorPatient} variant="contained">
								Ekle
							</Button>
						)}
					</DialogActions>
				</Dialog>
			</div>
		);
	})
);
