import { types } from "mobx-state-tree"

export const Address = types
    .model({
        cityId: types.optional(types.number, 0),
        cityName: types.optional(types.string, ""),
        districtId: types.optional(types.number, 0),
        districtName: types.optional(types.string, ""),
        neighbourhoodId: types.optional(types.number, 0),
        neighbourhoodName: types.optional(types.string, ""),
        address: types.optional(types.string, "")
    }).actions(self => ({

    }))
