import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { toJS } from 'mobx';
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';
import { Avatar, Button, Card, CardContent, Container, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import DialogTitle from '@mui/material/DialogTitle';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import { IMaskInput } from 'react-imask';

import { AutocompleteTani } from '../../components/common/AutoCompleteTani';
import { ClosePageComponent } from '../../components/common/ClosePageComponent'

import { TopBarMenu } from '../../components/common/TopBarMenu';
import { FaVideo, FaUserMd, FaHospitalUser } from 'react-icons/fa'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GiMedicines } from 'react-icons/gi'
import { ImLab } from 'react-icons/im'
import { HiDocumentText } from 'react-icons/hi'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import CloseIcon from '@mui/icons-material/Close';
import CallEndIcon from '@mui/icons-material/CallEnd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import AddLinkIcon from '@mui/icons-material/AddLink';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import ErrorIcon from '@mui/icons-material/Error'
import CheckIcon from '@mui/icons-material/Check';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

import { Note } from '../../model/Doctor/Note';
import { Patient } from '../../model/Patient';
import { Appointment } from '../../model/Appointment';
import { Medicine } from '../../model/Doctor/Medicine';
import { Diagnosis } from '../../model/Diagnosis';

import getAppointmentNote from '../../utils/api/Doctor/Appointment/Note/GetAppointmentNote'
import getDoctorPatientInfo from '../../utils/api/Doctor/GetDoctorPatientInfo'
import getAppointmentById from '../../utils/api/Doctor/Appointment/GetAppointmentById'
import getAppointmentAllergyList from '../../utils/api/Doctor/Appointment/Allergy/GetAppointmentAllergyList'
import getAppointmentActions from '../../utils/api/Doctor/Appointment/GetAppointmentActions'
import getReport from '../../utils/api/Doctor/Appointment/Report/GetReport'
import getTaniList from '../../utils/api/Doctor/Diagnosis/GetDiagnosis'
import getPrescription from '../../utils/api/Doctor/Appointment/Prescription/GetPrescriptionInfo'
import getConsultDoctorList from '../../utils/api/Doctor/Consultation/GetConsultDoctorList'
import getAppointmentPrivateNote from '../../utils/api/Doctor/Appointment/DoctorPrivateNote/GetAppointmentPrivateNote'
import getDiagnosis from '../../utils/api/Doctor/Diagnosis/GetDiagnosis'
import getPatientInsuranceList from '../../utils/api/Doctor/Appointment/InsuranceForm/GetPatientInsuranceList'
import getAppointmentInsuranceForm from '../../utils/api/Doctor/Appointment/InsuranceForm/GetAppointmentInsuranceForm'
import getAppointmentConsult from '../../utils/api/Doctor/Appointment/Consult/GetAppointmentConsult'
import getExaminationFirmList from '../../utils/api/Doctor/Examination/GetExaminationFirmList'
import getAppointmentExamination from '../../utils/api/Doctor/Examination/GetAppointmentExamination'
import getAppointmentGoruntuleme from '../../utils/api/Doctor/Examination/GetAppointmentGoruntuleme'

import postAppointmentComplaint from '../../utils/api/Doctor/Appointment/Complaint/PostAppointmentComplaint'
import postAppointmentSymptom from '../../utils/api/Doctor/Appointment/Symptom/PostAppointmentSymptom'
import postAppointmentSmooking from '../../utils/api/Doctor/Appointment/Smooking/PostAppointmentSmooking'
import postAppointmentAlcohol from '../../utils/api/Doctor/Appointment/Alcohol/PostAppointmentAlcohol'
import postAppointmentHistory from '../../utils/api/Doctor/Appointment/History/PostAppointmentHistory'
import postAppointmentFamilyillness from '../../utils/api/Doctor/Appointment/Familyillness/PostAppointmentFamilyillness'
import postAppointmentMedicine from '../../utils/api/Doctor/Appointment/Medicine/PostAppointmentMedicine';
import postAppointmentAllergy from '../../utils/api/Doctor/Appointment/Allergy/PostAppointmentAllergy'
import putForStartVideoCall from '../../utils/api/Doctor/Appointment/PutForStartVideoCall'
import postConsultationTalep from '../../utils/api/Doctor/Consultation/PostConsultationTalep'
import postDoctorPrivateNote from '../../utils/api/Doctor/Appointment/DoctorPrivateNote/PostAppointmentPrivateNote'
import postDiagnosis from '../../utils/api/Doctor/Diagnosis/PostDiagnosis'
import postAppointmentActions from '../../utils/api/Doctor/Appointment/PostAppointmentActions'
import inviteVideoCallWithGsmNo from '../../utils/api/Doctor/Appointment/InviteVideoCallWithGsmNo'
import enabizRequest from '../../utils/api/Doctor/Appointment/Enabiz/EnabizRequest'
import deleteConsultTalep from '../../utils/api/Doctor/Consultation/DeleteConsultDoctorTalep'
import putAppointment from '../../utils/api/Doctor/Appointment/PutAppointment'
import putAppointmentComplete from '../../utils/api/Doctor/Appointment/PutAppointmentComplete'

import { ReceteMenu } from '../../components/doktor/recete/ReceteMenuV2'
import { RaporMenu } from '../../components/doktor/rapor/RaporMenuv2';
import { SigortaBilgiFormuMenu } from '../../components/doktor/rapor/SigortaBilgiFormuMenuv2';
import { TetkikMenu } from '../../components/doktor/tetkik/TetkikMenuV2';
import { GoruntulemeMenu } from '../../components/doktor/tetkik/GoruntulemeMenuv2'
import { Chat } from '../../components/doktor/chat/Chat';
import TextEditor from '../../components/common/TextEditor';

const CryptoJS = require('crypto-js');

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(#00)000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

class VideoCallPage extends Component {
    canDiagnose = this.props.store.permissions.includes(2);
    canExamination = this.props.store.permissions.includes(3);
    canReport = this.props.store.permissions.includes(4);
    canPrescription = (this.props.store.permissions.includes(5) || this.props.store.permissions.includes(6));
    canHistory = this.props.store.permissions.includes(7);

    constructor(props) {
        super(props)
        this.state = {
            gorusmeOncesiBilgilendirmeDisplay: false,
            acceptedAllBilgilendirme: false,

            videoUrl: "",
            complaints: "",
            note: Note.create({}),
            appointment: Appointment.create({}),
            patient: Patient.create({}),
            patientId: 0,

            allergyList: [],
            allergyName: "",
            activeTab: this.canDiagnose ? 0 : this.canHistory ? 1 : 2,
            expanded: false,

            chatList: [],
            chatItem: "",

            taniId: 0,
            taniSearchTextValue: "",
            queryTaniList: [],

            consultDoctorList: [],

            kronikHastalikListesi: [],
            alerjiListesi: [],
            ameliyatListesi: [],
            yakinKronikHastaListesi: [],
            ilacListesi: [],

            doctorSpecialNote: "",

            diagnosis: Diagnosis.create({}),

            receteDurum: false,
            raporDurum: false,
            tetkikDurum: false,
            konsultasyonDurum: false,
            randevuDurum: false,
            tedaviIslem: [],
            tedaviIslemAciklama: "",

            receteDialogMenuDisplay: false,
            raporDialogMenuDisplay: false,
            consultDoctorDialogDisplay: false,
            sigortaBilgiFormuMenuDisplay: false,

            report: null,
            prescription: null,
            patientInsuranceList: [],
            appointmentInsuranceForm: null,
            appointmentConsult: null,

            davetGsmNo: "",
            gsmDavetEtDialogMenuDisplay: false,

            examinationListFirm: [],
            examinations: null,
            pageCloseWarningDisplay: false,
            goruntulemeTalep: null
        }
    }
    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/panel/kullanici-girisi");
        } else {
            this.props.store.setLoadingBarStatus(true)

            window.addEventListener("beforeunload", (e) => {
                e.preventDefault();
            });

            if (this.props.store.doctor[0] !== undefined) {
                let noteResponse = await getAppointmentNote(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, this.props.match.params.id)
                //console.log("noteResponse: ", noteResponse)
                if (noteResponse !== null && noteResponse !== undefined) {
                    if (noteResponse.status === 200) {
                        this.setState({
                            note: noteResponse.data
                        })
                    } else if (noteResponse.status === 401) {
                        this.props.store.setUserLogout()
                        window.location.reload()
                    }
                }

                let appointmentResponse = await getAppointmentById(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, this.props.match.params.id)
                //console.log("appointmentResponse.data: ", appointmentResponse.data)
                let patientData = null
                if (appointmentResponse !== null && appointmentResponse !== undefined) {
                    if (appointmentResponse.status === 200) {
                        this.setState({
                            appointment: appointmentResponse.data,
                            patientId: appointmentResponse.data.patientId
                        })

                        let patientResponse = await getDoctorPatientInfo(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, appointmentResponse.data.patientId)
                        if (patientResponse !== null && patientResponse !== undefined) {
                            if (patientResponse.status === 200) {
                                this.setState({
                                    patient: patientResponse.data,
                                    patientId: patientResponse.data.patientId
                                })
                                patientData = patientResponse.data
                            }
                        }
                        //console.log("patientResponse: ", patientResponse.data)
                    } else if (appointmentResponse.status === 401) {
                        this.props.store.setUserLogout()
                        window.location.reload()
                    }
                }
                //console.log("patientData: ", patientData)
                if (patientData !== null) {
                    //console.log("patientData: ", patientData)
                    let medicalInfoResultArray = patientData.medicalInfos

                    let kronikHastalikArray = []
                    let alerjiArray = []
                    let ameliyatArray = []
                    let yakinKronikHastalikArray = []
                    let ilacArray = []
                    for (let index = 0; index < medicalInfoResultArray.length; index++) {
                        const tempMedicalInfoTemp = medicalInfoResultArray[index];
                        //kronik hastalıklar
                        if (tempMedicalInfoTemp.medicalInfoTypeId === 3) {
                            kronikHastalikArray.push(tempMedicalInfoTemp)
                            //alerji
                        } else if (tempMedicalInfoTemp.medicalInfoTypeId === 10) {
                            alerjiArray.push(tempMedicalInfoTemp)
                            //ameliyat
                        } else if (tempMedicalInfoTemp.medicalInfoTypeId === 9) {
                            ameliyatArray.push(tempMedicalInfoTemp)
                            //yakinların kronik hasta listesi
                        } else if (tempMedicalInfoTemp.medicalInfoTypeId === 11) {
                            yakinKronikHastalikArray.push(tempMedicalInfoTemp)
                            //ilac
                        } else if (tempMedicalInfoTemp.medicalInfoTypeId === 8) {
                            ilacArray.push(tempMedicalInfoTemp)
                            //yakinların kronik hasta listesi
                        }
                    }

                    //console.log("alerjiArray: ", alerjiArray)

                    this.setState({
                        kronikHastalikListesi: kronikHastalikArray,
                        alerjiListesi: alerjiArray,
                        ameliyatListesi: ameliyatArray,
                        yakinKronikHastaListesi: yakinKronikHastalikArray,
                        ilacListesi: ilacArray
                    })

                }

                // allergy list disabled
                // await this.getAllergyList()
                await this.getConsultDoctorList()
                await this.getDoctorSpecialNoteReq();
                if (this.canPrescription) await this.getPrescriptionReq();
                if (this.canDiagnose) await this.getDiagnosisReq()
                await this.getAppointmentActionsReq();
                if (this.canReport) await this.getReportReq()
                await this.getAppointmentInsuranceFormReq()
                await this.getPatientInsuranceListReq()
                await this.getAppointmentConsultReq()
                if (this.canExamination) await this.getAppointmentExaminationReq()
                await this.getAppointmentGoruntulemeReq()

                this.props.store.setLoadingBarStatus(true)
                //console.log("noteResponse: ", noteResponse.data)
                //console.log("appointmentResponse: ", appointmentResponse.data)
            }
        }
    }

    startVideoProcess = async () => {

        this.setState({
            gorusmeOncesiBilgilendirmeDisplay: true
        })

    }

    getAppointmentExaminationReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let examinationInfoResponse = await getAppointmentExamination(this.props.store.baseUrl, this.props.store.token,
            this.props.store.doctor[0].id, this.props.match.params.id)



        if (examinationInfoResponse !== null && examinationInfoResponse !== undefined) {
            if (examinationInfoResponse.status === 200) {
                this.setState({
                    examinations: examinationInfoResponse.data
                })
            }
        }
        //console.log("examinationInfoResponse: ", examinationInfoResponse)
        this.props.store.setLoadingBarStatus(true)
    }

    //

    getAppointmentGoruntulemeReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let goruntulemeInfoResponse = await getAppointmentGoruntuleme(this.props.store.baseUrl, this.props.store.token,
            this.props.store.doctor[0].id, this.props.match.params.id)

        if (goruntulemeInfoResponse !== null && goruntulemeInfoResponse !== undefined) {
            if (goruntulemeInfoResponse.status === 200) {
                this.setState({
                    goruntulemeTalep: goruntulemeInfoResponse.data
                })
            }
        }

        //console.log("goruntulemeInfoResponse: ", goruntulemeInfoResponse.data)

        this.props.store.setLoadingBarStatus(true)
    }

    getExaminationFirmListReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let examinationResponseListResponse = await getExaminationFirmList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id)
        if (examinationResponseListResponse !== null && examinationResponseListResponse !== undefined) {
            if (examinationResponseListResponse.status === 200) {
                //console.log("examinationResponseListResponse: ", examinationResponseListResponse)
                this.setState({
                    examinationListFirm: examinationResponseListResponse.data
                })
            }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    getReportReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let reportResponse = await getReport(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id)
        if (reportResponse !== null && reportResponse !== undefined) {
            if (reportResponse.status === 200) {
                //console.log("reportResponse: ", reportResponse)
                this.setState({
                    report: reportResponse.data
                })
            }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    getAppointmentInsuranceFormReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let getInsuranceFormRequestResponse = await getAppointmentInsuranceForm(this.props.store.baseUrl, this.props.store.token,
            this.props.store.doctor[0].id, this.props.match.params.id)

        //console.log("getInsuranceFormRequestResponse: ", getInsuranceFormRequestResponse)

        if (getInsuranceFormRequestResponse !== null && getInsuranceFormRequestResponse !== undefined) {
            if (getInsuranceFormRequestResponse.status === 200) {
                this.setState({
                    appointmentInsuranceForm: getInsuranceFormRequestResponse.data
                })
            }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    getPatientInsuranceListReq = async () => {
        this.props.store.setLoadingBarStatus(false)
        let patientInsuranceListResponse = await getPatientInsuranceList(this.props.store.baseUrl, this.props.store.token,
            this.props.store.doctor[0].id, this.state.patient.patientId)

        //console.log("patientInsuranceListResponse: ", patientInsuranceListResponse)
        if (patientInsuranceListResponse !== null && patientInsuranceListResponse !== undefined) {
            if (patientInsuranceListResponse.status === 200) {
                this.setState({
                    patientInsuranceList: patientInsuranceListResponse.data
                })
            }
        }
        this.props.store.setLoadingBarStatus(true)
    }

    getAppointmentConsultReq = async () => {
        this.props.store.setLoadingBarStatus(false)
        let appointmentConsult = await getAppointmentConsult(this.props.store.baseUrl, this.props.store.token,
            this.props.store.doctor[0].id, this.props.match.params.id)

        //console.log("appointmentConsult: ", appointmentConsult)

        if (appointmentConsult !== null && appointmentConsult !== undefined) {
            if (appointmentConsult.status === 200) {
                this.setState({
                    appointmentConsult: appointmentConsult.data
                })
            }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    onLoadIframe = () => {
        //guestNameInput
        //const iframeEl = document.getElementById('meeting-iframe')

        //const innerDoc = iframeEl.contentDocument || iframeEl.contentWindow.document

        /*let guessNameElement = innerDoc.getElementById('guestNameInput');
        guessNameElement.value = this.props.store.doctor[0].name + " " + this.props.store.doctor[0].surname*/
    }

    onChangeDoktorOzelAlanTextChange = (event) => {
        this.setState({
            doctorSpecialNote: event.target.value
        })
    }

    onChangeComplaintTextChange = (event) => {
        this.setState({
            note: { ...this.state.note, complaint: event.target.value }
        })
    }

    onChangeSymptomTextChange = (event) => {
        this.setState({
            note: { ...this.state.note, symptom: event.target.value }
        })
    }

    onChangeHistoryText = (event) => {
        this.setState({
            note: { ...this.state.note, history: event.target.value }
        })
    }

    onChangeFamilyHistoryText = (event) => {
        this.setState({
            note: { ...this.state.note, familyIllness: event.target.value }
        })
    }

    onChangeAlkolSwitchChange = (event) => {
        this.setState({
            note: { ...this.state.note, alcohol: (event.target.checked) ? 1 : 0 }
        })
    }

    onChangeSigaraSwitchChange = (event) => {
        this.setState({
            note: { ...this.state.note, smooking: (event.target.checked) ? 1 : 0 }
        })
    }

    onChangeAllergyTextInput = (event) => {
        this.setState({
            allergyName: event.target.value
        })
    }

    onChangeChatItemTextInput = (event) => {
        this.setState({
            chatItem: event.target.value
        })
    }

    saveDoctorSpecialNote = async () => {
        this.props.store.setLoadingBarStatus(false)

        //console.log("this.props.store.selectedDoctorNote: ", this.props.store.selectedDoctorNote)

        let encrypt = this.encryptWithAES(this.props.store.selectedDoctorNote)
        //console.log("deneme: ", encrypt)

        let doctorSpecialNoteCreateResponse = await postDoctorPrivateNote(this.props.store.baseUrl, this.props.store.token,
            this.props.store.doctor[0].id, this.props.match.params.id, encrypt)

        if (doctorSpecialNoteCreateResponse !== null && doctorSpecialNoteCreateResponse !== undefined) {
            if (doctorSpecialNoteCreateResponse.status === 200) {
                this.props.store.setMessage("Doktora özel notlar kaydedildi.", true, "success")
            } else {
                this.props.store.setMessage("Doktora özel notlar kaydedilirken hata oluştu.", true, "error")
            }
        } else {
            this.props.store.setMessage("Doktora özel notlar kaydedilirken hata oluştu.", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    getDoctorSpecialNoteReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let doctorSpecialNoteResponse = await getAppointmentPrivateNote(this.props.store.baseUrl, this.props.store.token,
            this.props.store.doctor[0].id, this.props.match.params.id)
        //console.log("doctorSpecialNoteResponse: ", doctorSpecialNoteResponse)

        if (doctorSpecialNoteResponse !== null && doctorSpecialNoteResponse !== undefined) {
            if (doctorSpecialNoteResponse.status === 200) {
                if (doctorSpecialNoteResponse.data !== null && doctorSpecialNoteResponse.data !== "") {
                    if (doctorSpecialNoteResponse.data[0].doctorNote !== null) {
                        let doctorNote = this.decryptWithAES(doctorSpecialNoteResponse.data[0].doctorNote)
                        //console.log("doctorNote: ", doctorNote)
                        this.props.store.setSelectedDoctorNote(doctorNote)
                    }
                } else {
                    this.props.store.setSelectedDoctorNote("")
                }
            } else {
                this.props.store.setSelectedDoctorNote("")
            }
        }
        this.props.store.setLoadingBarStatus(true)
    }

    saveComplaints = async () => {
        this.props.store.setLoadingBarStatus(false)

        let complaintResponse = await postAppointmentComplaint(this.props.store.baseUrl, this.props.store.token,
            this.props.store.doctor[0].id, this.props.match.params.id, this.state.note.complaint)

        if (complaintResponse !== null && complaintResponse !== undefined) {
            if (complaintResponse.status === 200) {
                this.props.store.setMessage("Hasta şikayetleri başarıyla kaydedildi.", true, "success")
            } else {
                this.props.store.setMessage("Hasta şikayetleri kaydedilirken hata oluştu.", true, "error")
            }
        } else {
            this.props.store.setMessage("Hasta şikayetleri kaydedilirken hata oluştu.", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    saveSymptom = async () => {
        this.props.store.setLoadingBarStatus(false)

        let symptomResponse = await postAppointmentSymptom(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id, this.state.note.symptom)
        if (symptomResponse !== null && symptomResponse !== undefined) {
            if (symptomResponse.status === 200) {
                this.props.store.setMessage("Hasta semptomları başarıyla kaydedildi.", true, "success")
            } else {
                this.props.store.setMessage("Hasta semptomları kaydedilirken hata oluştu.", true, "error")
            }
        } else {
            this.props.store.setMessage("Hasta semptomları kaydedilirken hata oluştu.", true, "error")
        }
        this.props.store.setLoadingBarStatus(true)
    }

    saveSmookingAlcohol = async () => {
        this.props.store.setLoadingBarStatus(false)

        /*let smookingResponse = */await postAppointmentSmooking(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id, this.state.note.smooking)

        /*let alcoholResponse = */await postAppointmentAlcohol(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
                this.props.match.params.id, this.state.note.alcohol)

        //console.log("smookingResponse: ", smookingResponse)
        //console.log("alcoholResponse: ", alcoholResponse)

        this.props.store.setLoadingBarStatus(true)
    }

    saveHistory = async () => {
        this.props.store.setLoadingBarStatus(false)

        let historyResponse = await postAppointmentHistory(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id, this.state.note.history)

        if (historyResponse !== null && historyResponse !== undefined) {
            if (historyResponse.status === 200) {
                this.props.store.setMessage("Hasta geçmişiyle ilgili not başarıyla kaydedildi.", true, "success")
            } else {
                this.props.store.setMessage("Hasta geçmişiyle ilgili not kaydedilirken hata oluştu.", true, "error")
            }
        } else {
            this.props.store.setMessage("Hasta geçmişiyle ilgili not kaydedilirken hata oluştu.", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    saveFamilyHistory = async () => {
        this.props.store.setLoadingBarStatus(false)

        let familyHistoryResponse = await postAppointmentFamilyillness(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id, this.state.note.familyIllness)

        if (familyHistoryResponse !== null && familyHistoryResponse !== undefined) {
            if (familyHistoryResponse.status === 200) {
                this.props.store.setMessage("Hastanın yakınlarının geçmişiyle ilgili not başarıyla kaydedildi.", true, "success")
            } else {
                this.props.store.setMessage("Hastanın yakınlarının geçmişiyle ilgili not kaydedilirken hata oluştu.", true, "error")
            }
        } else {
            this.props.store.setMessage("Hastanın yakınlarının geçmişiyle ilgili not kaydedilirken hata oluştu.", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    saveMedicine = async () => {
        this.props.store.setLoadingBarStatus(false)

        let medicine = Medicine.create({
            patientId: 0,
            appointmentId: Number(this.props.match.params.id),
            doctorId: this.props.store.doctor[0].id,
            medicineName: this.state.medicineName,
            medicinePeriod: this.state.medicinePeriod,
            medicineDose: Number(this.state.medicineDoz)
        })

        let medicineList = toJS(this.state.medicineList)
        medicineList.push(medicine)

        let medicineCreateResponse = await postAppointmentMedicine(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id, medicineList)

        if (medicineCreateResponse !== null && medicineCreateResponse !== undefined) {
            if (medicineCreateResponse.status === 200) {
                this.props.store.setMessage("Kullanılan ilaç bilgileri başarıyla kaydedildi.", true, "success")
                await this.getMedicineList()
            } else {
                this.props.store.setMessage("Kullanılan ilaç bilgileri kaydedilirken hata oluştu.", true, "error")
            }
        } else {
            this.props.store.setMessage("Kullanılan ilaç bilgileri kaydedilirken hata oluştu.", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    getAllergyList = async () => {
        this.props.store.setLoadingBarStatus(false)

        let allergyListResponse = await getAppointmentAllergyList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id)

        //console.log("allergyListResponse.data: ", allergyListResponse)

        if (allergyListResponse !== null && allergyListResponse !== undefined) {
            if (allergyListResponse.status === 200) {

                this.setState({
                    allergyList: allergyListResponse.data
                })
            }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    saveAllergy = async () => {
        this.props.store.setLoadingBarStatus(false)

        let allergy = {
            allergyId: 0,
            patientId: 0,
            doctorId: this.props.store.doctor[0].id,
            appointmentId: this.props.match.params.id,
            allergyName: this.state.allergyName
        }

        let allergyList = toJS(this.state.allergyList)
        allergyList.push(allergy)

        let allergyCreateResponse = await postAppointmentAllergy(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id, allergyList)

        if (allergyCreateResponse !== null && allergyCreateResponse !== undefined) {
            if (allergyCreateResponse.status === 200) {
                await this.getAllergyList()
                this.props.store.setMessage("Alerji notu başarıyla kaydedildi.", true, "success")
            } else {
                this.props.store.setMessage("Alerji notu kaydedilirken hata oluştu.", true, "error")
            }
        } else {
            this.props.store.setMessage("Alerji notu kaydedilirken hata oluştu.", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            activeTab: newValue
        })
    }

    handleAccordionChange = (panel) => (event, isExpanded) => {
        //console.log("panel: ", panel)
        //console.log("isExpanded: ", isExpanded)
        this.setState({
            expanded: isExpanded ? panel : false
        })
    };


    getConsultDoctorList = async () => {
        let consultDoctorListResponse = await getConsultDoctorList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id)
        //console.log("consultDoctorListResponse: ", consultDoctorListResponse.data)
        if (consultDoctorListResponse !== null && consultDoctorListResponse !== undefined) {
            if (consultDoctorListResponse.status === 200) {
                this.setState({
                    consultDoctorList: consultDoctorListResponse.data
                })
            }
        }
    }

    openConsultDoctorDialogMenu = async () => {
        await this.getAppointmentConsultReq()

        this.setState({
            consultDoctorDialogDisplay: true
        })
    }

    closeConsultDoctorDialogMenu = () => {
        this.setState({
            consultDoctorDialogDisplay: false
        })
    }

    addKonsultasyonTalep = async (consultDoctorId) => {
        this.props.store.setLoadingBarStatus(false)

        let consultationTalep = {
            consultDoctorId: consultDoctorId,
            patientId: this.state.patient.patientId,
            appointmentId: this.props.match.params.id
        }

        let consultationTalepResult = await postConsultationTalep(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, consultationTalep)
        if (consultationTalepResult !== null && consultationTalepResult !== undefined) {
            if (consultationTalepResult.status === 200) {
                this.setState({
                    consultDoctorDialogDisplay: false
                })
                this.props.store.setMessage("Konsültasyon talebi başarıyla oluşturuldu.", true, "success")
            } else {
                this.props.store.setMessage("Konsültasyon talebi oluşturulurken hata oluştu.", true, "error")
            }
        } else {
            this.props.store.setMessage("Konsültasyon talebi oluşturulurken hata oluştu.", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    cancelKonsultasyonTalep = async (consultDoctorId) => {
        this.props.store.setLoadingBarStatus(false)

        let deleteConsultTalepResponse = await deleteConsultTalep(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id, consultDoctorId)

        if (deleteConsultTalepResponse !== null && deleteConsultTalepResponse !== undefined) {
            if (deleteConsultTalepResponse.status === 200) {
                this.props.store.setMessage("Talep başarıyla iptal edildi.", true, "success")
                this.setState({
                    consultDoctorDialogDisplay: false
                })
            } else {
                this.props.store.setMessage("Talep iptal edilirken hata oluştu", true, "error")
            }
        } else {
            this.props.store.setMessage("Talep iptal edilirken hata oluştu", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    handleChangeTaniTextInput = (event) => {
        this.setState({
            selectedTani: event.target.value
        })
    }

    getPrescriptionReq = async () => {
        let getPrescriptionResponse = await getPrescription(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id, this.state.patientId)

        if (getPrescriptionResponse !== null && getPrescriptionResponse !== undefined) {
            if (getPrescriptionResponse.status === 200) {

                //console.log("getPrescriptionResponse.data: ", getPrescriptionResponse.data)

                if (getPrescriptionResponse.data.length > 0) {
                    this.setState({
                        prescription: getPrescriptionResponse.data
                    })

                    this.props.store.setReceteInfo(getPrescriptionResponse.data)
                } else {
                    this.setState({
                        receteIsExist: false
                    })
                }

            }
        }
    }

    onClickReceteButton = async () => {
        this.props.store.setLoadingBarStatus(false)

        await this.getDiagnosisReq()

        let getPrescriptionResponse = await getPrescription(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id, this.state.patientId)
        //console.log("getPrescriptionResponse: ", getPrescriptionResponse)

        if (getPrescriptionResponse !== null && getPrescriptionResponse !== undefined) {
            if (getPrescriptionResponse.status === 200) {
                //console.log("getPrescriptionResponse.data: ", getPrescriptionResponse.data)
                if (getPrescriptionResponse.data !== null) {
                    if (getPrescriptionResponse.data.length > 0) {
                        this.setState({
                            prescription: getPrescriptionResponse.data
                        })
                    }
                } else {
                    this.props.store.setReceteDialogMenuDisplay(true)
                }
                this.props.store.setReceteDialogMenuDisplay(true)
            }
        }

        this.props.store.setReceteDialogMenuDisplay(true)

        this.props.store.setLoadingBarStatus(true)
    }

    onChangeSearchTaniListesiInputText = async (event) => {
        //console.log("event: ", event)
        this.setState({
            taniSearchTextValue: event
        })

        if (event !== null) {

            if (event.diagnosisId !== undefined) {
                //console.log("diagnosisId id: ", event.diagnosisId)

                this.setState({
                    taniId: event.diagnosisId
                })

            } else {
                if (event.length > 3) {
                    let taniListResponse = await getTaniList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, event)
                    //console.log("taniListResponse: ", taniListResponse)

                    let tempTaniList = taniListResponse.data
                    let taniListFixed = []
                    for (let index = 0; index < tempTaniList.length; index++) {
                        const tempTani = tempTaniList[index];
                        tempTani.label = tempTani.code + " " + tempTani.diagnosisName
                        taniListFixed.push(tempTani)
                    }
                    this.setState({
                        queryTaniList: taniListFixed
                    })
                }
            }
        } else {
            this.setState({
                taniId: 0,
                queryTaniList: []
            })
        }
    }

    openRaporDialogMenu = async () => {

        //if (this.state.taniSearchTextValue.diagnosisName !== undefined) {

        await this.getDiagnosisReq()

        this.props.store.setRaporDialogMenuDisplay(true)
        /*} else {
            this.props.store.setMessage("Tanı koyma işlemi gerçekleştirmediğiniz için rapor oluşturamazsınız.", true, "error")
        }*/
    }

    openSigortaBilgiFormuDialogMenu = async () => {
        await this.getDiagnosisReq()
        this.props.store.setSigortaBilgiFormuMenuDisplay(true)
    }


    closeRaporDialogMenu = () => {
        this.setState({
            raporDialogMenuDisplay: false
        })
    }

    onAcceptedGorusmeOncesiBilgilendirme = async () => {
        this.setState({
            acceptedAllBilgilendirme: true,
            gorusmeOncesiBilgilendirmeDisplay: false
        })

        this.props.store.setLoadingBarStatus(false)
        //${this.match.params.id}
        let startVideoCallResponse = await putForStartVideoCall(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, this.props.match.params.id)
        if (
          startVideoCallResponse !== null &&
          startVideoCallResponse !== undefined
        ) {
          if (
            startVideoCallResponse.status === 200 &&
            startVideoCallResponse.data && startVideoCallResponse.data !== "") {
            const doctorName = (
              this.props.store.doctor[0].name +
              "%20" +
              this.props.store.doctor[0].surname
            ).replace(" ", "%20");
            const params = new URLSearchParams();
            params.append("jitsi_meet_external_api_id", "0");
            params.append("config.toolbarButtons", '["microphone","camera"]');
            params.append("config.hideConferenceSubject", "true");
            params.append("config.hideConferenceTimer", "true");
            params.append("config.connectionIndicators", '{"disabled":true}');
            params.append(
              "interfaceConfig.VIDEO_QUALITY_LABEL_DISABLED",
              "true"
            );
            params.append("userInfo.displayName", '"doctorName"');
            params.append("appData.localStorageContent", "null");

            // console.log("state", this.state);
            // console.log("props", this.props);

            const urlParameterString = params
              .toString()
              .replace("doctorName", doctorName);
            let videoLink = startVideoCallResponse.data + urlParameterString;
            //   let videoLink = startVideoCallResponse.data + params
            this.setState({
              videoUrl: videoLink,
            });
          } else {
            let errMsg = "Görüşme başlatılırken hata oluştu";
            if (startVideoCallResponse.status === 400 && startVideoCallResponse.data === 9)
            {
                errMsg = errMsg + ": Ödeme bekleniyor";
            } else if (startVideoCallResponse.status) {
                errMsg = errMsg + ": " + startVideoCallResponse.message;
            }
            this.props.store.setMessage(
              errMsg,
              true,
              "error"
            );
          }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    onChangeDiagnosisDescription = (event) => {
        this.setState({
            diagnosis: { ...this.state.diagnosis, diagnosisDesc: event.target.value }
        })
    }

    onChangeTaniType = (event) => {
        //console.log("event.name: ", event.target.name)
        if (event.target.name === "ontani") {
            this.setState({
                diagnosis: { ...this.state.diagnosis, diagnosisType: 3 }
            })
        } else {
            this.setState({
                diagnosis: { ...this.state.diagnosis, diagnosisType: 1 }
            })
        }
    }

    onClickSaveTani = async () => {
        this.props.store.setLoadingBarStatus(false)

        //console.log("new Date(): ", new Date())

        if (this.state.patient.patientId !== 0) {
            let diagnosis = {
                appointmentId: Number(this.props.match.params.id),
                doctorId: this.props.store.doctor[0].id,
                patientId: this.state.patient.patientId,
                diagnosisDate: new Date(),
                diagnosisDesc: this.state.diagnosis.diagnosisDesc,
                diagnosisType: this.state.diagnosis.diagnosisType,
                diagnosisId: this.props.store.selectedDiagnosisId
            }

            //console.log("diagnosis: ", diagnosis)

            let diagnosisSaveResponse = await postDiagnosis(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, this.props.match.params.id,
                [diagnosis])

            if (diagnosisSaveResponse !== null && diagnosisSaveResponse !== undefined) {
                if (diagnosisSaveResponse.status === 200) {
                    this.props.store.setSelectedDiagnosisName("")
                    this.props.store.setMessage("Tanı başarıyla kaydedildi.", true, "success")
                } else {
                    this.props.store.setSelectedDiagnosisName("")
                    this.props.store.setMessage("Tanı kaydedilirken hata oluştu", true, "error")
                }
            } else {
                this.props.store.setSelectedDiagnosisName("")
                this.props.store.setMessage("Tanı kaydedilirken hata oluştu", true, "error")
            }
        } else {
            this.props.store.setMessage("Hasta bilgisi getirmede sorun yaşandığı için tanı kaydetme işlemi gerçekleştirilemedi.", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    getDiagnosisReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let diagnosisGetResponse = await getDiagnosis(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, this.props.match.params.id)
        if (diagnosisGetResponse !== null && diagnosisGetResponse !== undefined) {
            if (diagnosisGetResponse.status === 200) {
                //console.log("diagnosisGetResponse: ", diagnosisGetResponse.data)
                if (diagnosisGetResponse.data !== null) {
                    let inputDiagnosis = diagnosisGetResponse.data[0]

                    if (inputDiagnosis !== undefined) {
                        let diagnosisTemp = {
                            diagnosisId: inputDiagnosis.diagnosisId,
                            patientId: inputDiagnosis.patientId,
                            doctorId: inputDiagnosis.doctorId,
                            appointmentId: inputDiagnosis.appointmentId,
                            diagnosisType: inputDiagnosis.diagnosisType,
                            diagnosisCode: inputDiagnosis.diagnosisCode,
                            diagnosisName: inputDiagnosis.diagnosisName,
                            diagnosisDesc: inputDiagnosis.diagnosisDesc,
                        }

                        this.setState({
                            diagnosis: diagnosisTemp
                        })
                        this.props.store.setSelectedDiagnosisName(inputDiagnosis.diagnosisCode + " - " + inputDiagnosis.diagnosisName)
                        this.props.store.setSelectedDiagnosisId(inputDiagnosis.diagnosisId)
                    }
                } else {
                    this.setState({
                        diagnosis: Diagnosis.create({})
                    })
                    this.props.store.setSelectedDiagnosisName("")
                    this.props.store.setSelectedDiagnosisId(0)
                }
            }
        }
        this.props.store.setLoadingBarStatus(true)
    }

    getAppointmentActionsReq = async () => {
        this.props.store.setLoadingBarStatus(false)

        let getAppointmentActionsResponse = await getAppointmentActions(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, this.props.match.params.id)

        if (getAppointmentActionsResponse !== null && getAppointmentActionsResponse !== undefined) {
            if (getAppointmentActionsResponse.status === 200) {
                //console.log("getAppointmentActionsResponse: ", getAppointmentActionsResponse)

                if (getAppointmentActionsResponse.data !== null) {
                    let actionList = getAppointmentActionsResponse.data.actions
                    let actionDescription = getAppointmentActionsResponse.data.treatment

                    this.setState({
                        tedaviIslemAciklama: actionDescription
                    })

                    if (actionList !== null) {
                        this.setState({
                            tedaviIslem: getAppointmentActionsResponse.data.actions
                        })

                        for (let index = 0; index < actionList.length; index++) {
                            const actionTemp = actionList[index];
                            if (actionTemp.actionTypeId === 1) {
                                this.setState({
                                    receteDurum: true
                                })
                            } else if (actionTemp.actionTypeId === 2) {
                                this.setState({
                                    raporDurum: true
                                })
                            } else if (actionTemp.actionTypeId === 3) {
                                this.setState({
                                    tetkikDurum: true
                                })
                            } else if (actionTemp.actionTypeId === 4) {
                                this.setState({
                                    konsultasyonDurum: true
                                })
                            } else if (actionTemp.actionTypeId === 5) {
                                this.setState({
                                    randevuDurum: true
                                })
                            }

                        }
                    }
                }
            }
        }
        this.props.store.setLoadingBarStatus(true)
    }

    onChangeTedaviIslem = (event) => {
        //console.log("event.target.checked: ", event.target.checked)
        //console.log("event.target.name: ", event.target.name)
        if (event.target.name === "receteDurum") {
            this.setState({
                receteDurum: event.target.checked
            })
        } else if (event.target.name === "raporDurum") {
            this.setState({
                raporDurum: event.target.checked
            })
        } else if (event.target.name === "tetkikDurum") {
            this.setState({
                tetkikDurum: event.target.checked
            })
        } else if (event.target.name === "konsultasyonDurum") {
            this.setState({
                konsultasyonDurum: event.target.checked
            })
        } else if (event.target.name === "randevuDurum") {
            this.setState({
                randevuDurum: event.target.checked
            })
        }

    }

    onChangeTedaviIslemAciklama = (event) => {
        this.setState({
            tedaviIslemAciklama: event.target.value
        })
    }

    onClickSaveTedaviIslem = async () => {
        this.props.store.setLoadingBarStatus(false)

        let actionArray = []

        if (this.state.receteDurum) {
            actionArray.push({
                actionTypeId: 1
            })
        }
        if (this.state.raporDurum) {
            actionArray.push({
                actionTypeId: 2
            })
        }
        if (this.state.tetkikDurum) {
            actionArray.push({
                actionTypeId: 3
            })
        }
        if (this.state.konsultasyonDurum) {
            actionArray.push({
                actionTypeId: 4
            })
        }
        if (this.state.randevuDurum) {
            actionArray.push({
                actionTypeId: 5
            })
        }

        let actionsBodyObject = {
            treatment: this.state.tedaviIslemAciklama,
            actions: actionArray
        }

        let appointmentActionsResponse = await postAppointmentActions(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id, actionsBodyObject)

        if (appointmentActionsResponse !== null && appointmentActionsResponse !== undefined) {
            if (appointmentActionsResponse.status === 200) {
                this.props.store.setMessage("Tedavi işlem bilgisi başarıyla kaydedildi.", true, "success")
            } else {
                this.props.store.setMessage("Tedavi işlem bilgisi kaydedilirken hata oluştu.", true, "error")
            }
        } else {
            this.props.store.setMessage("Tedavi işlem bilgisi kaydedilirken hata oluştu.", true, "error")
        }
        this.props.store.setLoadingBarStatus(true)

    }

    onClickOpenDavetEtDialogmenu = () => {
        this.setState({
            gsmDavetEtDialogMenuDisplay: true
        })
    }

    onClickCloseDavetEtDialogmenu = () => {
        this.setState({
            gsmDavetEtDialogMenuDisplay: false
        })
    }

    onClickDavetEt = async () => {
        this.props.store.setLoadingBarStatus(false)

        let gsmNo = this.state.davetGsmNo

        var find = '-';
        var re = new RegExp(find, 'g');
        gsmNo = gsmNo.replace(re, '');

        gsmNo = gsmNo.replace(/\(/g, '');
        gsmNo = gsmNo.replace(/\)/g, '');

        let davetEtResponse = await inviteVideoCallWithGsmNo(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, this.props.match.params.id,
            gsmNo)
        //console.log("davetEtResponse: ", davetEtResponse)
        if (davetEtResponse !== null && davetEtResponse !== undefined) {
            if (davetEtResponse.status === 200) {
                this.props.store.setMessage("Davet başarıyla gönderildi", true, "success")
                this.setState({
                    gsmDavetEtDialogMenuDisplay: false
                })
            } else {
                this.props.store.setMessage(davetEtResponse.data.message, true, "error")
            }
        } else {
            this.props.store.setMessage("Davet gönderimi yapılırken hata oluştu.", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    onChangeDavetEtGsmNo = (event) => {
        this.setState({
            davetGsmNo: event.target.value
        })
    }

    onClickNabizIntegration = async () => {
        this.props.store.setLoadingBarStatus(false)

        let eNabizResponse = await enabizRequest(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id,
            this.props.match.params.id)
        //console.log("eNabizResponse: ", eNabizResponse)

        if (eNabizResponse !== null && eNabizResponse !== undefined) {
            if (eNabizResponse.status === 200) {

            } else {
                this.props.store.setMessage("Nabız sayfasına yönlendirilirken hata oluştu.", true, "error")
            }
        } else {
            this.props.store.setMessage("Nabız sayfasına yönlendirilirken hata oluştu.", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    onClickTetkikButton = async () => {
        //await this.getDiagnosisReq()
        await this.getExaminationFirmListReq()
        this.props.store.setTetkikDialogMenuDisplay(true)
    }

    onClickGoruntulemeButton = async () => {
        //await this.getDiagnosisReq()
        await this.getAppointmentGoruntulemeReq()
        this.props.store.setGoruntulemeDialogMenuDisplay(true)
    }

    encryptWithAES = (text) => {
        const passphrase = '123';
        return CryptoJS.AES.encrypt(text, passphrase).toString();
    };

    decryptWithAES = (ciphertext) => {
        const passphrase = '123';
        const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText;
    };

    onClickCloseVideoCallEnd = (e) => {
        this.setState({
            pageCloseWarningDisplay: true
        })
    }

    onClickCloseWarningDisplayMenu = async () => {

        this.props.store.setLoadingBarStatus(false)

        let updateAppointment = toJS(this.state.appointment)
        updateAppointment.status = 3

        let updateAppointmentResponse = await putAppointment(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, this.props.match.params.id, updateAppointment)
        if (updateAppointmentResponse !== null && updateAppointmentResponse !== undefined) {
            if (updateAppointmentResponse.status === 200) {


            }
        }

        let putAppointmentCompleteResponse = await putAppointmentComplete(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, this.props.match.params.id)
        if (putAppointmentCompleteResponse !== null && putAppointmentCompleteResponse !== undefined) {
            if (putAppointmentCompleteResponse.status === 200) {

            }
        }

        this.setState({
            pageCloseWarningDisplay: false
        })

        this.props.store.setLoadingBarStatus(true)

        this.props.history.push("/panel/ajanda")
    }

    render() {

        return (
            <div className="container mps-container">
                <TopBarMenu />
                <ClosePageComponent />

                <Container maxWidth={false} style={{ paddingTop: "80px" }}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                            <Grid container >
                                <Grid item xs={8}>
                                    <Card style={{ height: 'calc(100vh - 290px)', borderRadius: "10px" }}>
                                        <CardContent style={{ padding: "0", height: "100%" }}>
                                            {(this.state.videoUrl !== "") ?
                                                <iframe allow="camera; microphone; display-capture; autoplay; clipboard-write; hid" id="meeting-iframe" src={this.state.videoUrl} title="Görüşme Ekranı"
                                                    style={{ height: '100%', width: '100%', border: "0" }}
                                                    onLoad={this.onLoadIframe} /> :
                                                <div className="session-start-screen">
                                                    <div className="doctor-image">
                                                        <FaUserMd style={{
                                                            fontSize: "200px", color: "#2196f3",
                                                            borderRadius: "150px", border: "7px solid #2196f3", marginBottom: "15px"
                                                        }} />
                                                    </div>

                                                    <Button variant='contained' color="primary" onClick={this.startVideoProcess} disabled={(this.state.appointment?.appointmentType === 2) ? true : false}>
                                                        <FaVideo style={{ fontSize: "24px", marginRight: "15px" }} /> Görüşmeyi Başlat
                                                    </Button>
                                                </div>

                                            }
                                        </CardContent>
                                    </Card>
                                    <Card style={{ height: '140px', borderRadius: "10px", marginTop: "25px" }}>
                                        <CardContent style={{ padding: "0px", height: "100px" }}>
                                            <Grid container spacing={3}>
                                                {this.canPrescription && <Grid item xs={2} style={{ padding: "50px", textAlign: "center", paddingTop: "55px" }}>
                                                    <Tooltip title="Hasta için reçete oluştur">
                                                        <IconButton aria-label="upload picture" component="label" className='video-call-buttons'
                                                            onClick={this.onClickReceteButton}>
                                                            <GiMedicines />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <p style={{ fontSize: "12px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>Reçete</p>
                                                </Grid>}
                                                {this.canExamination && <Grid item xs={2} style={{ padding: "50px", textAlign: "center", paddingTop: "55px" }}>
                                                    <Tooltip title="Hasta için tetkik talebi oluştur">
                                                        <IconButton aria-label="upload picture" component="label" className='video-call-buttons'
                                                            onClick={this.onClickTetkikButton}>
                                                            <ImLab />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <p style={{ fontSize: "12px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>Tetkik</p>
                                                </Grid>}

                                                {this.canExamination && <Grid item xs={2} style={{ padding: "50px", textAlign: "center", paddingTop: "55px" }}>
                                                    <Tooltip title="Hasta için görüntüleme talebi oluştur">
                                                        <IconButton aria-label="upload picture" component="label" className='video-call-buttons'
                                                            onClick={this.onClickGoruntulemeButton}>
                                                            <BurstModeIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <p style={{ fontSize: "12px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>Görüntüleme</p>
                                                </Grid>}
                                                {this.canReport && <Grid item xs={2} style={{ padding: "50px", textAlign: "center", paddingTop: "55px" }}>
                                                    <Tooltip title="Hasta için rapor oluştur">
                                                        <IconButton aria-label="upload picture" component="label" className='video-call-buttons'
                                                            onClick={this.openRaporDialogMenu}>
                                                            <HiDocumentText />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <p style={{ fontSize: "12px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>Rapor</p>
                                                </Grid>}
                                                <Grid item xs={2} style={{ padding: "50px", textAlign: "center", paddingTop: "55px" }}>
                                                    <Tooltip title="Sigorta Bilgi Formu Oluştur">
                                                        <IconButton aria-label="upload picture" component="label" className='video-call-buttons'
                                                            onClick={this.openSigortaBilgiFormuDialogMenu}>
                                                            <HiDocumentText />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <p style={{ fontSize: "12px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>Sigorta B. Formu</p>
                                                </Grid>
                                                <Grid item xs={2} style={{ padding: "50px", textAlign: "right", paddingTop: "55px" }}>

                                                    <IconButton aria-label="upload picture" color="error" className='video-call-button-endcall'
                                                        onClick={this.onClickCloseVideoCallEnd}>
                                                        <CallEndIcon style={{ color: "#fff" }} />
                                                    </IconButton>
                                                    <p style={{ fontSize: "12px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>Görüşmeyi <br /> Sonlandır</p>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card style={{ height: '100%', borderRadius: "10px", marginTop: "0px", marginLeft: "25px" }}>
                                        <CardContent style={{ height: "auto", padding: "10px" }}>
                                            <Grid container spacing={3} style={{ paddingRight: "35px" }}>
                                                <Grid item xs={6}>
                                                    <Typography variant='body1' style={{ margin: "10px", marginTop: "15px", marginLeft: "5px" }}><b>GÖRÜŞME BİLGİLERİ</b></Typography>
                                                    <Typography variant='body2' style={{ margin: "5px" }}><b>Adı Soyadı: </b>
                                                        {this.state.patient.name} {this.state.patient.surname}
                                                    </Typography>
                                                    <Typography variant='body2' style={{ margin: "5px" }}><b>Cinsiyet / Yaş: </b> {(this.state.patient.gender === 1) ? "Erkek" : (this.state.patient.gender === 2) ? "Kadın" : "Belirsiz"} / {dayjs(this.state.patient.birthDate).year() > 1900 ? dayjs(new Date()).diff(this.state.patient.birthDate, 'year') : "-"}</Typography>
                                                    <Typography variant='body2' style={{ margin: "5px" }}><b>Randevu Tarih/Saat: </b> {dayjs(this.state.appointment.appointmentDate).format("DD.MM.YYYY HH:mm")}</Typography>
                                                </Grid>
                                                {/* <Grid item xs={2} style={{ textAlign: "center" }}>
                                                    <Button variant='contained' color="primary" className='video-call-action-buttons'
                                                        onClick={this.onClickNabizIntegration} disabled={(this.props.store.doctorType !== 1) ? true : false}>
                                                        <AddLinkIcon style={{ color: "#fff" }} />
                                                    </Button>
                                                    <p style={{ textAlign: "left", fontSize: "10px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>E-NABIZ</p>

                                                </Grid> */}
                                                <Grid item xs={2} style={{ textAlign: "center" }}>
                                                    <Button variant='contained' color="primary" className='video-call-action-buttons'
                                                        onClick={this.onClickOpenDavetEtDialogmenu}>
                                                        <PersonAddIcon style={{ color: "#fff" }} />
                                                    </Button>
                                                    <p style={{ textAlign: "left", fontSize: "10px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>DAVET ET</p>
                                                </Grid>
                                                <Grid item xs={2} style={{ textAlign: "center" }}>
                                                    <Button variant='contained' color="primary" className='video-call-action-buttons'
                                                        onClick={this.openConsultDoctorDialogMenu} disabled={(this.props.store.doctorType !== 1) ? true : false}>
                                                        <FaHospitalUser style={{ color: "#fff", fontSize: "24px" }} />
                                                    </Button>
                                                    <p style={{ textAlign: "left", fontSize: "10px", marginTop: "5px", color: "gray", fontWeight: "bold" }}>KONSÜLTAN EKLE</p>
                                                </Grid>
                                            </Grid>

                                            <Tabs value={this.state.activeTab} onChange={this.handleTabChange} aria-label="basic tabs example"
                                                /*variant="scrollable" scrollButtons="auto"*/>
                                                <Tab label="TANI İŞLEMLERİ" style={this.canDiagnose ? null : {display:'none'}}  />
                                                <Tab label="ÖZ/SOY GEÇMİŞ" style={this.canHistory ? null : {display:'none'}}  />
                                                <Tab label="MESAJLAŞMA" />
                                            </Tabs>

                                            {this.canDiagnose && <Card hidden={(this.state.activeTab !== 0) ? true : false} style={{ boxShadow: "none", padding: "0" }}>
                                                <Accordion expanded={this.state.expanded === 'panel6'} onChange={this.handleAccordionChange('panel6')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header">
                                                        <Typography variant="body2" style={{ fontWeight: "bold" }}>Hasta Şikayeti ve Öyküsü</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <TextField
                                                            id="standard-multiline-flexible"
                                                            label="Hasta Şikayetleri"
                                                            multiline minRows={3} maxRows={3}
                                                            value={(this.state.note !== null) ? this.state.note.complaint : ""}
                                                            onChange={this.onChangeComplaintTextChange}
                                                            variant="outlined" style={{ width: "100%" }}
                                                        />
                                                        <Button variant='contained' color="primary" onClick={this.saveComplaints}
                                                            style={{ float: "right", marginTop: "5px", marginBottom: "5px" }}>
                                                            Kaydet
                                                        </Button>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion expanded={this.state.expanded === 'panel7'} onChange={this.handleAccordionChange('panel7')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel2a-content"
                                                        id="panel2a-header">
                                                        <Typography variant="body2" style={{ fontWeight: "bold" }}>Semptom ve Bulgular</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <TextField
                                                            id="standard-multiline-flexible"
                                                            label="Semptomlar"
                                                            multiline minRows={2} maxRows={2}
                                                            value={(this.state.note !== null) ? this.state.note.symptom : ""}
                                                            onChange={this.onChangeSymptomTextChange}
                                                            variant="outlined" style={{ width: "100%" }}
                                                        />
                                                        <Button variant='contained' color="primary" onClick={this.saveSymptom}
                                                            style={{ float: "right", marginTop: "5px", marginBottom: "5px" }}>
                                                            Kaydet
                                                        </Button>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion expanded={this.state.expanded === 'panel9'} onChange={this.handleAccordionChange('panel9')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header">
                                                        <Typography variant="body2" style={{ fontWeight: "bold" }}>Doktor Özel Not Alanı</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography variant='body2'>Bu alana girdiğiniz bilgiler hasta tarafından görüntülenemez.</Typography>
                                                        <TextEditor/>
                                                        {/*
                                                       <TextField
                                                            id="standard-multiline-flexible"
                                                            label="Notlarım"
                                                            multiline minRows={3} maxRows={3}
                                                            value={this.state.doctorSpecialNote}
                                                            onChange={this.onChangeDoktorOzelAlanTextChange}
                                                            variant="outlined" style={{ width: "100%", marginTop: "10px" }}
                                                        />
                                                       */}
                                                        <Button variant='contained' color="primary" onClick={this.saveDoctorSpecialNote}
                                                            style={{ float: "right", marginTop: "5px", marginBottom: "5px" }}>
                                                            Kaydet
                                                        </Button>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion expanded={this.state.expanded === 'panel8'} onChange={this.handleAccordionChange('panel8')}
                                                    disabled={(this.props.store.doctorType !== 1) ? true : false}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel2a-content"
                                                        id="panel2a-header">
                                                        <Typography variant="body2" style={{ fontWeight: "bold" }}>Tanı Bilgileri</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ minHeight: "270px" }}>
                                                        <FormGroup row >
                                                            <FormControlLabel control={<Checkbox name="ontani" checked={(this.state.diagnosis.diagnosisType === 3) ? true : false} onChange={this.onChangeTaniType} />} label="Ön Tanı" />
                                                            <FormControlLabel control={<Checkbox name="kesintani" checked={(this.state.diagnosis.diagnosisType === 1) ? true : false} onChange={this.onChangeTaniType} />} label="Kesin Tanı" />
                                                        </FormGroup>
                                                        <AutocompleteTani appointmentId={this.props.match.params.id} diagnosisId={this.props.store.selectedDiagnosisId} />
                                                        <TextField label="Tanı Açıklama" multiline rows={3} style={{ marginTop: "10px", width: "100%" }}
                                                            value={this.state.diagnosis.diagnosisDesc} onChange={this.onChangeDiagnosisDescription}></TextField>
                                                        <Button color='primary' variant='contained' style={{ float: "right", marginTop: "10px", marginBottom: "10px" }}
                                                            onClick={this.onClickSaveTani}>
                                                            Tanı Kaydet
                                                        </Button>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion expanded={this.state.expanded === 'panel10'} onChange={this.handleAccordionChange('panel10')}
                                                    disabled={(this.props.store.doctorType !== 1) ? true : false}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel2a-content"
                                                        id="panel2a-header">
                                                        <Typography variant="body2" style={{ fontWeight: "bold" }}>Tedavi - İşlem Bilgisi</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ minHeight: "250px" }}>

                                                        <FormGroup row>
                                                            {this.canPrescription && <FormControlLabel control={<Checkbox name="receteDurum" checked={this.state.receteDurum} onClick={this.onChangeTedaviIslem} />} label="Reçete verildi" />}
                                                            {this.canReport && <FormControlLabel control={<Checkbox name="raporDurum" checked={this.state.raporDurum} onClick={this.onChangeTedaviIslem} />} label="Rapor verildi" />}
                                                            {this.canExamination && <FormControlLabel control={<Checkbox name="tetkikDurum" checked={this.state.tetkikDurum} onClick={this.onChangeTedaviIslem} />} label="Tetkik istendi" />}
                                                            <FormControlLabel control={<Checkbox name="konsultasyonDurum" checked={this.state.konsultasyonDurum} onClick={this.onChangeTedaviIslem} />} label="Konsültasyon istendi" />
                                                            <FormControlLabel control={<Checkbox name="randevuDurum" checked={this.state.randevuDurum} onClick={this.onChangeTedaviIslem} />} label="Randevu oluşturuldu" />
                                                        </FormGroup>
                                                        <TextField label="Tedavi Açıklama" multiline rows={3} value={this.state.tedaviIslemAciklama}
                                                            style={{ width: "100%" }} onChange={this.onChangeTedaviIslemAciklama}></TextField>

                                                        <Button color="primary" variant='contained' style={{ float: "right", marginTop: "10px", marginBottom: "10px" }}
                                                            onClick={this.onClickSaveTedaviIslem}>
                                                            Tedavi - İşlem Bilgisi Kaydet
                                                        </Button>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Card>}

                                            <Card hidden={(this.state.activeTab !== 1) ? true : false} style={{ boxShadow: "none" }}>
                                                <CardContent style={{ padding: "0" }}>
                                                    <Accordion expanded={this.state.expanded === 'panel1'} onChange={this.handleAccordionChange('panel1')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel2a-content"
                                                            id="panel2a-header">
                                                            <Typography variant="body2" style={{ fontWeight: "bold" }}>Hasta Geçmiş Bilgileri</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container>
                                                                <Grid item xs={9}>
                                                                    <TextField
                                                                        id="standard-multiline-flexible"
                                                                        label="Önceki hastalıklar, ameliyatlar, alerji, kullanılan ilaçlar, sigara ve alkol"
                                                                        multiline minRows={2} maxRows={2}
                                                                        value={(this.state.note !== null) ? this.state.note.history : ""}
                                                                        onChange={this.onChangeHistoryText}
                                                                        variant="outlined" style={{ width: "100%" }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Button variant='contained' color="primary" onClick={this.saveHistory}
                                                                        style={{ float: "right", marginTop: "5px", marginBottom: "5px" }}>
                                                                        Kaydet
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container style={{marginTop: "10px"}}>
                                                                {(this.state.kronikHastalikListesi !== null) ? this.state.kronikHastalikListesi.map((kronikHastalik, idx) => (
                                                                    <Grid key={idx} color="primary" style={{ color: "#fff" }} >
                                                                        <Chip key={idx} label={kronikHastalik.description} color="primary" 
                                                                            style={{ color: "#fff", margin: "10px" }} />
                                                                    </Grid>
                                                                )) : null}
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    {/*
                                                        <Accordion expanded={this.state.expanded === 'panel2'} onChange={this.handleAccordionChange('panel2')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel2a-content"
                                                            id="panel2a-header">
                                                            <Typography variant="body2" style={{ fontWeight: "bold" }}>Alerji Bilgileri</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={8}>
                                                                    <TextField
                                                                        id="standard-multiline-flexible"
                                                                        label="Hasta Alerji Bilgileri"
                                                                        value={this.state.allergyName}
                                                                        onChange={this.onChangeAllergyTextInput}
                                                                        variant="outlined" style={{ width: "100%" }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Button variant='contained' color="primary" onClick={this.saveAllergy}
                                                                        style={{ float: "right", marginTop: "5px", marginBottom: "5px" }}>
                                                                        Kaydet
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                            <Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
                                                                {(this.state.allergyList !== null) ? this.state.allergyList.map((allergy, idx) => (
                                                                    <Chip key={idx} label={allergy.allergyName}
                                                                        style={{ fontWeight: "bold", width: "auto" }} />
                                                                )) : null}
                                                            </Stack>
                                                            <Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
                                                                {(this.state.alerjiListesi !== null) ? this.state.alerjiListesi.map((allergy, idx) => (
                                                                    <Chip key={idx} label={allergy.description} color="primary"
                                                                        style={{ fontWeight: "bold", width: "auto" }} />
                                                                )) : null}
                                                            </Stack>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                
                                                                */}

                                                    <Accordion expanded={this.state.expanded === 'panel3'} onChange={this.handleAccordionChange('panel3')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel2a-content"
                                                            id="panel2a-header">
                                                            <Typography variant="body2" style={{ fontWeight: "bold" }}>Hasta Yakınlarının Geçmiş Bilgileri</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <TextField
                                                                id="standard-multiline-flexible"
                                                                label="Anne, Baba, Kardeş, Çocuklar, Ailedeki Hastalıklar"
                                                                multiline minRows={2} maxRows={2}
                                                                value={(this.state.note !== null) ? this.state.note.familyIllness : ""}
                                                                onChange={this.onChangeFamilyHistoryText}
                                                                variant="outlined" style={{ width: "100%" }}
                                                            />
                                                            <Button variant='contained' color="primary" onClick={this.saveFamilyHistory}
                                                                style={{ float: "right", marginTop: "5px", marginBottom: "5px" }}>
                                                                Kaydet
                                                            </Button>
                                                            <Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
                                                                {(this.state.yakinKronikHastaListesi !== null) ? this.state.yakinKronikHastaListesi.map((yakin, idx) => (
                                                                    <Chip key={idx} label={yakin.description} color="primary"
                                                                        style={{ fontWeight: "bold", width: "auto" }} />
                                                                )) : null}
                                                            </Stack>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    { /*
                                                        <Accordion expanded={this.state.expanded === 'panel4'} onChange={this.handleAccordionChange('panel4')}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel2a-content"
                                                                id="panel2a-header">
                                                                <Typography variant="body2" style={{ fontWeight: "bold" }}>Sigara ve Alkol Kullanım Bilgileri</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Switch checked={(this.state.note !== null) ? (this.state.note.alcohol === 0) ? false : true : false}
                                                                        onChange={this.onChangeAlkolSwitchChange} />} label="Alkol" />
                                                                    <FormControlLabel control={<Switch checked={(this.state.note !== null) ? (this.state.note.smooking === 0) ? false : true : false}
                                                                        onChange={this.onChangeSigaraSwitchChange} />} label="Sigara" />
                                                                </FormGroup>
                                                                <Button variant='contained' color="primary" onClick={this.saveSmookingAlcohol}
                                                                    style={{ float: "right", marginTop: "5px", marginBottom: "5px" }}>
                                                                    Kaydet
                                                                </Button>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                       
                                                        <Accordion expanded={this.state.expanded === 'panel5'} onChange={this.handleAccordionChange('panel5')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel2a-content"
                                                            id="panel2a-header">
                                                            <Typography variant="body2" style={{ fontWeight: "bold" }}>Kullanılan İlaç Bilgileri</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={6}>
                                                                    <TextField variant='outlined' name="medicineName" label="İlaç İsmi" style={{ width: "100%" }}
                                                                        value={this.state.medicineName} onChange={this.onChangeMedicineTextInput}></TextField>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField variant='outlined' name="medicinePeriod" label="İlaç Periyot" style={{ width: "100%" }}
                                                                        value={this.state.medicinePeriod} onChange={this.onChangeMedicineTextInput}></TextField>
                                                                </Grid>

                                                                <Grid item xs={8}>
                                                                    <TextField variant='outlined' name="medicineDoz" label="Doz" style={{ width: "100%" }}
                                                                        value={this.state.medicineDoz} onChange={this.onChangeMedicineTextInput}></TextField>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Button variant='contained' style={{ height: "50px", marginTop: "3px", float: "right" }}
                                                                        onClick={this.saveMedicine}>Ekle</Button>
                                                                </Grid>
                                                            </Grid>
                                                            <Stack direction="column" spacing={1} style={{ marginTop: "15px" }}>
                                                                {(this.state.medicineList !== null) ? this.state.medicineList.map((medicine, idx) => (
                                                                    <Chip key={idx} label={medicine.medicineName + " Periyot: " + medicine.medicinePeriod + " Doz: " + medicine.medicineDose}
                                                                        style={{ fontWeight: "bold", width: "auto" }} />
                                                                )) : null}
                                                            </Stack>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                        */
                                                    }

                                                </CardContent>
                                            </Card>

                                            <Card hidden={(this.state.activeTab !== 2) ? true : false} style={{ boxShadow: "none" }}>
                                                <Chat appointmentId={this.props.match.params.id} patientId={this.props.match.params.patientId} />
                                            </Card>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Dialog fullScreen open={this.state.consultDoctorDialogDisplay} /*onClose={this.closeConsultDoctorDialogMenu}*/>
                    <AppBar sx={{ position: 'relative', background: "#2196f3" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.closeConsultDoctorDialogMenu}
                                aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Konsültasyon İşlemleri
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>KONSÜLTAN DOKTOR BİLGİSİ</TableCell>
                                        <TableCell align="right">UZMANLIK ALANI</TableCell>
                                        <TableCell align="right">DURUM</TableCell>
                                        <TableCell align="right">İŞLEMLER</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(this.state.consultDoctorList !== null) ? this.state.consultDoctorList.map((doctor, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell component="th" scope="row">
                                                <Avatar alt={doctor.avatar} src={"/assets/" + doctor.avatar} />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {doctor.consultName + " " + doctor.consultSurname}
                                            </TableCell>
                                            <TableCell align="right">
                                                {doctor.consultDepartment}
                                            </TableCell>
                                            <TableCell align="right">
                                                {(this.state.appointmentConsult !== null) ?
                                                    <Tooltip title="Konsültasyon talebi gönderildi">
                                                        <IconButton color="success">
                                                            <CheckIcon />
                                                        </IconButton>

                                                    </Tooltip> :
                                                    <Tooltip title="Henüz konsültasyon talebi gönderilmedi">
                                                        <IconButton color="warning">
                                                            <HourglassTopIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                                {(this.state.appointmentConsult !== null) ?
                                                    <Button variant='contained' color="error" onClick={() => this.cancelKonsultasyonTalep(doctor.consultDoctorId)}>
                                                        <SendTimeExtensionIcon style={{ marginRight: "10px" }} />İptal Et
                                                    </Button>
                                                    :
                                                    <Button variant='contained' onClick={() => this.addKonsultasyonTalep(doctor.consultDoctorId)}>
                                                        <SendTimeExtensionIcon style={{ marginRight: "10px" }} />Talep Oluştur
                                                    </Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Dialog>

                {this.canPrescription && <ReceteMenu patient={this.state.patient} appointmentId={this.props.match.params.id}
                    diagnosis={this.state.diagnosis} prescription={this.state.prescription} />}

                {this.canReport && <RaporMenu patient={this.state.patient} appointmentId={this.props.match.params.id} rapor={this.state.report}
                    diagnosis={this.state.diagnosis} raporIsExist={false} tedaviAciklama={this.state.tedaviIslemAciklama}
                    complaint={this.state.note.complaint} symptom={this.state.note.symptom} tedavi={this.state.tedaviIslem} />}

                <SigortaBilgiFormuMenu patient={this.state.patient} appointmentId={this.props.match.params.id} appointmentInsuranceForm={this.state.appointmentInsuranceForm}
                    diagnosis={this.state.diagnosis} complaint={this.state.note.complaint} note={this.state.note} patientInsurances={this.state.patientInsuranceList}
                    bilgiFormuExist={false} symptom={this.state.note.symptom} tedavi={this.state.tedaviIslem} tedaviAciklama={this.state.tedaviIslemAciklama} />

                {this.canExamination &&
                    <>
                        <TetkikMenu patient={this.state.patient} appointmentId={this.props.match.params.id} examinationListFirm={this.state.examinationListFirm}
                        examinations={this.state.examinations} />
                        <GoruntulemeMenu patient={this.state.patient} appointmentId={this.props.match.params.id} examinationListFirm={this.state.examinationListFirm}
                        images={this.state.goruntulemeTalep} />
                    </>}
                <Dialog open={this.state.gorusmeOncesiBilgilendirmeDisplay} >
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="body1" style={{ fontWeight: "bold" }}>Bilgilendirme</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Değerli kullanıcımız, uzaktan sağlık hizmetinin yerine getirilebilmesi
                            için <b>kamera ve mikrofon erişim izni </b>gerekmektedir. Hastanız ile yapacağınız görüşmeyi karşı tarafa
                            bildirerek istediğiniz anda sonlandırabilirsiniz.
                        </Typography>
                        <Typography>
                            Hastanız ile yapacağınız görüşmede <b>hiçbir şekilde ses ve görüntü kaydı yapılmamaktadır.</b>
                        </Typography>

                    </DialogContent>
                    <DialogActions>
                        <Button /*component={Link} to=""*/ variant='contained' onClick={this.onAcceptedGorusmeOncesiBilgilendirme}>
                            Devam Et
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.gsmDavetEtDialogMenuDisplay} onClose={this.onClickCloseDavetEtDialogmenu}>
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="body1" style={{ fontWeight: "bold" }}>Davet Et</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Buradan görüşmeye eklenecek kişinin gsm numarasını girerek davet edebilirsiniz.
                        </Typography>
                        <FormControl fullWidth style={{ marginTop: "10px" }}>
                            <TextField id="outlined-basic" variant="outlined" name="mobilePhone"
                                value={this.state.davetGsmNo}
                                onChange={this.onChangeDavetEtGsmNo}
                                type='text'
                                InputProps={{
                                    inputComponent: TextMaskCustom,
                                }}
                                label="Cep Telefon Numarası" />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button /*component={Link} to=""*/ variant='contained' onClick={this.onClickDavetEt}>
                            Davet Et
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog fullWidth maxWidth="xs" open={this.state.pageCloseWarningDisplay} onClose={this.onClickCloseWarningDisplayMenu}>
                    <DialogTitle id="alert-dialog-title">

                    </DialogTitle>
                    <DialogContent style={{ paddingTop: "0" }}>
                        <Grid container>
                            <Grid item xs={3}>
                                <ErrorIcon color="error" style={{ fontSize: "80px" }} />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>Görüşmeden çıkmak istediğinize emin misiniz ?</Typography>
                                <Typography variant="body2">Görüşmeyi sonlandırdığınız anda sigorta bilgi
                                    formu oluşturulmamış ise hastanızın Özel Sağlık Sigortası tarafından bu randevu ücretini karşılamasında sorun yaşanabilir.</Typography>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={this.onClickCloseWarningDisplayMenu}>
                            GERİ DÖN
                        </Button>
                        <Button color='error' variant='contained' onClick={this.onClickCloseWarningDisplayMenu}>
                            GÖRÜŞMEDEN AYRIL
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default inject("store")(observer(VideoCallPage));
